import { render, staticRenderFns } from "./ProductoPedir.vue?vue&type=template&id=0b4ebd04&scoped=true"
import script from "./ProductoPedir.vue?vue&type=script&lang=js"
export * from "./ProductoPedir.vue?vue&type=script&lang=js"
import style0 from "./ProductoPedir.vue?vue&type=style&index=0&id=0b4ebd04&prod&lang=scss&scoped=true"
import style1 from "./ProductoPedir.vue?vue&type=style&index=1&id=0b4ebd04&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b4ebd04",
  null
  
)

export default component.exports