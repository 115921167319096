export default () => {
  return {
    cashguardDialog: false,
    cashguardBottom: false, //  PON ESTE A TRUE
    ficheroCashguard: "",
    ficheroCashguardResultado: "", //OK, KO
    accionSigue: "",
    ficheroCashguardContenidoFichero: "",
    inventario: [],
    ultimaComprobacion: null,
  };
};
