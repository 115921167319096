import moment from 'moment-timezone';
import { mostrarMoneda, redondeo } from '@/utils/helper.utils.js';
import { readFileAsync } from 'fs';
const { loadImage } = require('canvas');
const QRCode = require('qrcode');
var EscPosEncoder = require('esc-pos-encoder');

const ESC_INIT = [0x1b, 0x40];
const CORTE = [0x1d, 0x56, 49];
const LINEA = [0x0a];
const FUENTE_MEDIANA = [0x1d, 0x21, 0x10];
const FUENTE_GRANDE = [0x1d, 0x21, 0x11];
const FUENTE_NORMAL = [0x1d, 0x21, 0x00];
const BOLD = [0x1b, 0x21, 0x08];
const BOLD_NORMAL = [0x1b, 0x21, 0x00];
const FUENTE_MAS_GRANDE = [0x1d, 0x21, 34];
const ALIGN_CENTER = [0x1b, 0x61, 0x01];
const ALIGN_LEFT = [0x1b, 0x61, 0x00];
const ALIGN_RIGHT = [0x1b, 0x61, 0x02];

const ABRIR_CAJON = [0x1b, 0x70, 0x0, 0x19, 0xfa];

const IMAGEN = [0x1b, 0xfa, 0x01, 0x00, 0x0a, 0x00, 0xc8];

// prueba rene

const RENE = [0x1b, 0x57];
const INVERTIR = [0x1d, 0x42, 1];
const STOP_INVERTIR = [0x1d, 0x42, 0];
const FONT_HACHATADA = [0x1b, 0x21, 34];
const ESPACIO_ENTRE_CARACTER = [0x1b, 0x20, 2];
const LINEA_HORIZONTAL = [
  0x1b,
  0x61,
  0x01,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  ,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  ,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x2d,
  0x0a,
];

function lineaEsc(text) {
  return quitarAcentos(text)
    .split('')
    .map((char) => char.charCodeAt(0))
    .concat(LINEA);
}

function quitarAcentos(str) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

function idToTexto(s) {
  return s && s.length > 0
    ? s.replace(/^./, s[0].toUpperCase()).replace(/_/g, ' ').replace(/-/g, ' ')
    : '';
}

function _base64ToArrayBuffer(base64) {
  var binary_string = window.atob(base64);
  var len = binary_string.length;
  var bytes = new Uint8Array(len);
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i);
  }
  return bytes.buffer;
}

function getBase64FromImageUrl(url) {
  return new Promise((r, rej) => {
    var img = new Image();

    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = function () {
      var canvas = document.createElement('canvas');
      canvas.width = this.width;
      canvas.height = this.height;

      var ctx = canvas.getContext('2d');
      ctx.drawImage(this, 0, 0);

      var dataURL = canvas.toDataURL('image/png');

      let datos = dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
      return r(_base64ToArrayBuffer(datos));
    };

    img.src = url;
  });
}

export const imprimirTicketTipo1 = async function (data, logo) {
  var res = [];
  return new Promise((resolve) => {
    if (!logo) resolve([]);

    var img = new Image();

    //listener has to be added before setting the src attribute in case the image is cached
    img.addEventListener('load', (r) => {
      let encoder = new EscPosEncoder();
      let result = encoder.image(img, 480, 240, 'atkinson').encode();
      resolve(result);
    });
    img.src = 'data:image/png;base64,' + logo;
  }).then(async (logo) => {
    res = res
      .concat(ESC_INIT)
      .concat(ALIGN_CENTER)
      .concat([...logo]);

    const negocio = data.copyNegocio;
    const restaurante = data.copyRest;
    const timezone = restaurante.confGeneral.zonaHoraria
      ? restaurante.confGeneral.zonaHoraria
      : 'Europe/Madrid';
    var fechaMoment = data.fechaServidor.seconds
      ? moment.unix(data.fechaServidor.seconds).tz(timezone)
      : moment.unix(data.fechaServidorFinalEjecucion.seconds).tz(timezone);
    const formaPago =
      data.forma_pago == 'tarjeta_online'
        ? 'PAGADO ' + idToTexto(data.forma_pago).toUpperCase()
        : idToTexto(data.forma_pago).toUpperCase();
    res = res
      .concat(ALIGN_CENTER)
      .concat(lineaEsc(quitarAcentos(data.copyNegocio.nombre)))
      .concat(lineaEsc(quitarAcentos(data.copyRest.direccion)))
      .concat(lineaEsc(data.copyRest.cif + ' | ' + data.copyRest.telefono))
      .concat(BOLD)
      .concat(
        lineaEsc(
          'TICKET: 00' +
            data.numPedidoRest +
            ' - ' +
            fechaMoment.format('DD/MM/YYYY') +
            ' - ' +
            fechaMoment.format('HH:mm')
        )
      )
      .concat(BOLD);
    /*
            .concat(FUENTE_MAS_GRANDE)
            .concat(LINEA)
            .concat(INVERTIR)
        if (data.multiple && data.multipleHijos && data.multipleHijos.length) {//hijo
            cabecera 
                .line('PEDIDO MULTIPLE')
                .raw([0x1D, 0x21, 0x00])
            data.multipleHijos.forEach(h => {
                cabecera
                    .line(h)
            })
            cabecera.newline()
        } else if (data.multiplePadre) { //padre
            cabecera
                .line('MULTI-00' + data.numPedidoRestDiaDirectorio + ' - ' + data.modo.toUpperCase())
        } else  {
            // let f = this.$store.state.datosTpv.modos.find((m) => m.id == this.modoid);
            //   return f ? f.nombre : "";
            let modoMostrar = !!data.modoTexto ? data.modoTexto.toUpperCase() : data.modo.toUpperCase()
            res = res.concat(lineaEsc('00' + data.numPedidoRestDia + ' - ' + modoMostrar))
        } */
    res = res
      .concat(FUENTE_NORMAL) //miriad undereline
      .concat(STOP_INVERTIR)
      .concat(LINEA)
      .concat(ALIGN_CENTER)
      .concat(BOLD)
      .concat(lineaEsc('DIRECCION DE ENTREGA'))
      .concat(
        lineaEsc(
          data.modo == 'domicilio'
            ? quitarAcentos(
                data.direccion.calle +
                  ' ' +
                  data.direccion.numero +
                  ' ' +
                  data.direccion.adicional +
                  ', ' +
                  data.direccion.municipio +
                  ' ' +
                  data.direccion.cp +
                  ' ' +
                  data.direccion.provincia
              )
            : 'RECOGIDA'
        )
      )
      .concat(LINEA)
      .concat(ALIGN_LEFT)
      .concat(BOLD);

    if (
      (data.modo == 'domicilio' || data.modo == 'recogida') &&
      !!data.copyUser &&
      !!data.copyUser.phoneNumber
    )
      res = res.concat(
        lineaEsc('TELEFONO: ' + data.copyUser.phoneNumber.padStart(5))
      );

    if (data.modo == 'domicilio' || data.modo == 'recoger')
      res = res.concat(
        lineaEsc(
          data.modo == 'domicilio'
            ? 'TIEMPO ESTIMADO: ' + data.copyRest.tiempoDomicilio + ' min.'
            : 'TIEMPO ESTIMADO: ' + data.copyRest.tiempoRecogida + ' min.'
        )
      );

    // .bold()
    // .underline()
    // .size()
    // .align("center")
    // .raw([0x1D, 0x21, 0x11]) //tamaño grande
    // .newline()
    // .line('PEDIDO ONLINE')
    // .raw([0x1D, 0x21, 0x00])
    // .underline("false")
    // .align("left")
    // .bold("false")
    // .newline()
    res = res.concat(lineaEsc('FORMA PAGO: ' + formaPago));
    if (data.notaRestaurante)
      res = res.concat(
        lineaEsc(
          data.notaRestaurante
            ? 'COMENTARIO CLIENTE: ' + data.notaRestaurante
            : ''
        )
      );

    res = res
      .concat(ALIGN_LEFT)
      .concat(BOLD)
      .concat(LINEA_HORIZONTAL)
      .concat(ALIGN_LEFT);

    var impuestos = 0;
    var productos = data.productos.filter((p) => p.cantidad > 0);

    productos.forEach((prd) => {
      let s = '';
      if (prd.porPeso) {
        s = s + (prd.cantidad + 'kg ').padEnd(5) + quitarAcentos(prd.nombre);
      } else {
        s = s + (prd.cantidad + 'x ').padEnd(5) + quitarAcentos(prd.nombre);
      }

      s = s.padEnd(34);
      let precioUnitario = !!prd.precioProductoTotalUnitario
        ? prd.precioProductoTotalUnitario
        : prd.precio;
      precioUnitario = mostrarMoneda(precioUnitario, true).padStart(7);
      //if (prd.porPeso || prd.cantidad > 1)
      //    s = s + precioUnitario

      let precioTotal = !!prd.precioProductoTotal
        ? prd.precioProductoTotal
        : !!prd.precioProductoTotalUnitario
        ? prd.precioProductoTotalUnitario * prd.cantidad
        : precio * prd.cantidad;
      impuestos = impuestos + redondeo(prd.impuestoCantidad * prd.cantidad);
      let precioTotalString = mostrarMoneda(precioTotal, true).padStart(7);
      s = s + precioUnitario + precioTotalString;
      res = res.concat(lineaEsc(s));
      if (prd.opciones) {
        let opcionesImpresas = [];
        prd.opciones.forEach((e, i) => {
          if (!opcionesImpresas.includes(e)) {
            opcionesImpresas.push(e);
            var ocurrencias = prd.opciones.reduce(
              (a, v) => (v === e ? a + 1 : a),
              0
            );
            let s = '';
            s =
              ocurrencias > 1
                ? '   ' +
                  ocurrencias +
                  '- ' +
                  quitarAcentos(prd.opcionesName[i])
                : '   - ' + quitarAcentos(prd.opcionesName[i]);
            s = s.padEnd(43);
            let precio =
              '' + mostrarMoneda(prd.opcionesPrecio[i] * prd.cantidad);
            precio = precio.padStart(5);
            s = s + precio;
            // carritoString = carritoString + encoder.line(s)
            res = res.concat(lineaEsc(s));
          }
        });
      }
    });
    if (data.recargo && data.recargo > 0) {
      let s = '';
      // carritoString = carritoString + encoder.line(s)
      // res = res.concat(lineaEsc(s))
      s = '';
      s = s + '   Recargo domicilio ';
      s = s.padEnd(36);
      let precio = mostrarMoneda(data.recargo).padStart(5);
      s = s + precio;
      // carritoString = carritoString + encoder.line(s)
      res = res.concat(lineaEsc(s));
    }
    const impuestosImprimir = data.impuestosTotal
      ? data.impuestosTotal
      : impuestos;
    let precioTotal = data.precioTotal;
    // var carritoProductos = new Uint8Array(carritoString);
    var precioFinal = 'TOTAL: ' + mostrarMoneda(precioTotal);
    precioFinal = precioFinal;

    res = res
      .concat(ALIGN_LEFT)
      .concat(LINEA_HORIZONTAL)
      .concat(ALIGN_RIGHT)
      .concat(FUENTE_GRANDE)
      .concat(FONT_HACHATADA);
    if (
      data.forma_pago == 'efectivo' &&
      Array.isArray(data.cobradoDesglose) &&
      data.cobradoDesglose.length == 1
    ) {
      //sólo un pago
      let cambioFound = data.cobradoDesglose.find(
        (f) => f.formaPago == 'efectivo'
      );
      if (!!cambioFound) {
        res = res
          .concat(
            lineaEsc('ENTREGADO: ' + mostrarMoneda(cambioFound.entregado, true))
          )
          .concat(
            lineaEsc(' CAMBIO: ' + mostrarMoneda(cambioFound.cambio, true))
          );
      }
    }
    res = res
      .concat(BOLD_NORMAL)
      .concat(
        lineaEsc(
          'BASE IMPONIBLE:   ' +
            mostrarMoneda(parseFloat(precioTotal) - impuestosImprimir)
        )
      )
      .concat(lineaEsc('IMPUESTOS:   ' + mostrarMoneda(impuestosImprimir)))
      .concat(FUENTE_GRANDE)
      .concat(lineaEsc(precioFinal))
      .concat(LINEA)
      .concat(FUENTE_NORMAL)

      .concat(LINEA)
      .concat(ALIGN_CENTER);

    if (data.facturaCliente) {
      // create QRCode Image
      async function getQRCode(content) {
        return new Promise((resolve, reject) => {
          QRCode.toDataURL(content, (err, url) => {
            if (err) return reject(err);

            loadImage(url).then(resolve, reject);
          });
        });
      }
      const qrcode = await getQRCode(data.facturaCliente);
      let encoder = new EscPosEncoder();
      const result = encoder
        .initialize()
        .align('center')
        .image(qrcode, 320, 320)
        .encode();

      res = res
        .concat(ALIGN_CENTER)
        .concat(lineaEsc('DESCARGUE SU FACTURA'))
        .concat([...result])
        .concat(ALIGN_CENTER)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA);
    }

    res = res.concat(lineaEsc('Gracias por su pedido'));
    // .concat(lineaEsc('By FunEats'));

    res = res
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA);

    res = res.concat(CORTE);
    return new Uint8Array(res);
  });
};

export const imprimirTicketTipo2 = async function (data, logo) {
  var res = [];

  var logoEncode = [];
  return new Promise((resolve) => {
    if (!logo) resolve([]);

    var img = new Image();
    img.crossOrigin = 'Anonymous';
    //listener has to be added before setting the src attribute in case the image is cached
    img.addEventListener('load', (r) => {
      let encoder = new EscPosEncoder();
      let result = encoder.image(img, 480, 240, 'threshold').encode();
      logoEncode = result;
      resolve(result);
    });
    img.src =
      'https://firebasestorage.googleapis.com/v0/b/fun-eat-produccion.appspot.com/o/negocios%2Flos-bandios%2Ftickets%2FZFkQ1B22LbzBsDhjLcZp.jpg?alt=media&token=70269548-dc59-49a1-935b-0b99eb21f03e';
  }).then(async (logoE) => {
    res = res.concat(ESC_INIT).concat(ALIGN_CENTER);

    const negocio = data.copyNegocio;
    const restaurante = data.copyRest;
    const timezone =
      restaurante &&
      restaurante.confGeneral &&
      restaurante.confGeneral.zonaHoraria
        ? restaurante.confGeneral.zonaHoraria
        : 'Europe/Madrid';
    var fechaMoment = data.fechaServidor.seconds
      ? moment.unix(data.fechaServidor.seconds).tz(timezone)
      : moment.unix(data.fechaServidorFinalEjecucion.seconds).tz(timezone);
    var formaPago =
      data.forma_pago == 'tarjeta_online'
        ? 'PAGADO ' + idToTexto(data.forma_pago).toUpperCase()
        : idToTexto(data.forma_pago).toUpperCase();
    res = res
      .concat(ALIGN_CENTER)
      .concat(lineaEsc(quitarAcentos(data.copyNegocio.nombre)))
      .concat(lineaEsc(quitarAcentos(data.copyRest.direccion)))
      .concat(lineaEsc(data.copyRest.cif + ' | ' + data.copyRest.telefono))
      .concat(BOLD)
      .concat(
        lineaEsc(
          'TICKET: 00' +
            data.numPedidoRest +
            ' - ' +
            fechaMoment.format('DD/MM/YYYY') +
            ' - ' +
            fechaMoment.format('HH:mm')
        )
      )
      .concat(BOLD)
      .concat(FUENTE_MAS_GRANDE)
      .concat(LINEA)
      .concat(INVERTIR);
    if (data.multiple && data.multipleHijos && data.multipleHijos.length) {
      //hijo
      cabecera.line('PEDIDO MULTIPLE').raw([0x1d, 0x21, 0x00]);
      data.multipleHijos.forEach((h) => {
        cabecera.line(h);
      });
      cabecera.newline();
    } else if (data.multiplePadre) {
      //padre
      cabecera.line(
        'MULTI-00' +
          data.numPedidoRestDiaDirectorio +
          ' - ' +
          data.modo.toUpperCase()
      );
    } else {
      let cabeceraMostrar;
      if (data.copyMesa && data.modoCopy) {
        cabeceraMostrar =
          'MESA ' + data.copyMesa.nombre + ' ' + data.modoCopy.nombre;
      } else if (data.modoCopy) {
        let num = data.numPedidoRestDiaModoMostrar
          ? ' ' + data.numPedidoRestDiaModoMostrar
          : data.numPedidoRestDia
          ? ' ' + data.numPedidoRestDia
          : '';
        cabeceraMostrar = data.modoCopy.nombre + num;
      }
      if (!cabeceraMostrar) {
        switch (data.modo) {
          case 'domicilio':
            res = '';
            break;
          case 'recoger':
            res = 'RECOGER';
            break;
          case 'mesa':
            res = 'MESA ' + data.copyMesa.nombre;
            break;
          case 'mostrador':
            res = 'MOSTRADOR ';
            break;
        }
        cabeceraMostrar = res;
      }
      // let cabeceraMostrar = (data.modo == 'mesa')
      //     ? data.copyMesa.nombre
      //     : (data.modoCopy ? data.modoCopy.nombre : data.modo).toUpperCase()
      res = res.concat(lineaEsc(cabeceraMostrar));
    }
    res = res
      .concat(FUENTE_NORMAL) //miriad undereline
      .concat(STOP_INVERTIR)
      .concat(LINEA)
      .concat(ALIGN_CENTER)
      .concat(BOLD)
      .concat(lineaEsc('DIRECCION DE ENTREGA'))
      .concat(
        lineaEsc(
          data.modo == 'domicilio'
            ? quitarAcentos(
                data.direccion.calle +
                  ' ' +
                  data.direccion.numero +
                  ' ' +
                  data.direccion.adicional +
                  ', ' +
                  data.direccion.municipio +
                  ' ' +
                  data.direccion.cp +
                  ' ' +
                  data.direccion.provincia
              )
            : 'RECOGIDA'
        )
      )
      .concat(LINEA)
      .concat(ALIGN_LEFT)
      .concat(BOLD);

    if (
      (data.modo == 'domicilio' || data.modo == 'recogida') &&
      !!data.copyUser &&
      !!data.copyUser.phoneNumber
    )
      res = res.concat(
        lineaEsc('TELEFONO: ' + data.copyUser.phoneNumber.padStart(5))
      );

    if (data.modo == 'domicilio' || data.modo == 'recoger')
      res = res.concat(
        lineaEsc(
          data.modo == 'domicilio'
            ? 'TIEMPO ESTIMADO: ' + data.copyRest.tiempoDomicilio + ' min.'
            : 'TIEMPO ESTIMADO: ' + data.copyRest.tiempoRecogida + ' min.'
        )
      );

    // .bold()
    // .underline()
    // .size()
    // .align("center")
    // .raw([0x1D, 0x21, 0x11]) //tamaño grande
    // .newline()
    // .line('PEDIDO ONLINE')
    // .raw([0x1D, 0x21, 0x00])
    // .underline("false")
    // .align("left")
    // .bold("false")
    // .newline()
    if (data.restante) {
      res = res.concat(lineaEsc('RESTANTE POR PAGAR'));
    } else {
      res = res.concat(lineaEsc('FORMA PAGO: ' + formaPago));
    }
    if (data.notaRestaurante)
      res = res.concat(
        lineaEsc(
          data.notaRestaurante
            ? 'COMENTARIO CLIENTE: ' + data.notaRestaurante
            : ''
        )
      );

    res = res
      .concat(ALIGN_LEFT)
      .concat(BOLD)
      .concat(LINEA_HORIZONTAL)
      .concat(ALIGN_LEFT);
    var impuestos = 0;
    var productos = data.productos.filter((p) => p.cantidad > 0);
    productos.forEach((prd) => {
      let s = '';
      if (prd.porPeso) {
        s =
          s +
          (prd.cantidad + 'kg ').padEnd(5) +
          quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre).substring(
            0,
            28
          );
      } else {
        s =
          s +
          (prd.cantidad + 'x ').padEnd(5) +
          quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre).substring(
            0,
            28
          );
      }

      s = s.padEnd(34);
      let precioUnitario = !!prd.precioProductoTotalUnitario
        ? prd.precioProductoTotalUnitario
        : prd.precio;
      precioUnitario = mostrarMoneda(precioUnitario, true).padStart(7);
      //if (prd.porPeso || prd.cantidad > 1)
      //    s = s + precioUnitario

      let precioTotal = !!prd.precioProductoTotal
        ? prd.precioProductoTotal
        : prd.precio * prd.cantidad;
      impuestos = impuestos + redondeo(prd.impuestoCantidad * prd.cantidad);
      let precioTotalString = mostrarMoneda(precioTotal, true).padStart(7);
      s = s + precioUnitario + precioTotalString;
      res = res.concat(lineaEsc(s));
      if (prd.opciones) {
        let opcionesImpresas = [];
        prd.opciones.forEach((e, i) => {
          if (!opcionesImpresas.includes(e)) {
            let arrayUsar = Array.isArray(prd.opcionesNombreEs)
              ? prd.opcionesNombreEs
              : prd.opcionesName;
            opcionesImpresas.push(e);
            var ocurrencias = prd.opciones.reduce(
              (a, v) => (v === e ? a + 1 : a),
              0
            );
            let s = '';
            s =
              ocurrencias > 1
                ? '   ' + ocurrencias + '- ' + quitarAcentos(arrayUsar[i])
                : '   - ' + quitarAcentos(arrayUsar[i]);
            s = s.padEnd(43);
            /*let precio = "" + mostrarMoneda(prd.opcionesPrecio[i] * prd.cantidad);
                            precio = precio.padStart(5);
                            s = s + precio*/
            // carritoString = carritoString + encoder.line(s)
            res = res.concat(lineaEsc(s));
          }
        });
      }
    });
    if (data.recargo && data.recargo > 0) {
      let s = '';
      // carritoString = carritoString + encoder.line(s)
      // res = res.concat(lineaEsc(s))
      s = '';
      s = s + '   Recargo domicilio ';
      s = s.padEnd(36);
      let precio = mostrarMoneda(data.recargo).padStart(5);
      s = s + precio;
      // carritoString = carritoString + encoder.line(s)
      res = res.concat(lineaEsc(s));
    }

    const impuestosImprimir = data.impuestosTotal
      ? data.impuestosTotal
      : impuestos;
    let precioTotal = data.precioTotal;
    // var carritoProductos = new Uint8Array(carritoString);
    var precioFinal = 'TOTAL: ' + mostrarMoneda(precioTotal);
    precioFinal = precioFinal;

    res = res
      .concat(ALIGN_LEFT)
      .concat(LINEA_HORIZONTAL)
      .concat(ALIGN_RIGHT)
      .concat(FUENTE_GRANDE)
      .concat(FONT_HACHATADA);
    if (
      data.forma_pago == 'efectivo' &&
      Array.isArray(data.cobradoDesglose) &&
      data.cobradoDesglose.length == 1
    ) {
      //sólo un pago
      let cambioFound = data.cobradoDesglose.find(
        (f) => f.formaPago == 'efectivo'
      );
      if (!!cambioFound) {
        res = res
          .concat(
            lineaEsc('ENTREGADO: ' + mostrarMoneda(cambioFound.entregado, true))
          )
          .concat(
            lineaEsc(' CAMBIO: ' + mostrarMoneda(cambioFound.cambio, true))
          );
      }
    }

    res = res
      .concat(BOLD_NORMAL)
      .concat(
        lineaEsc(
          'BASE IMPONIBLE:   ' +
            mostrarMoneda(parseFloat(precioTotal) - impuestosImprimir)
        )
      )
      .concat(lineaEsc('IMPUESTOS:   ' + mostrarMoneda(impuestosImprimir)))
      .concat(FUENTE_GRANDE)
      .concat(lineaEsc(precioFinal))
      .concat(LINEA)
      .concat(FUENTE_NORMAL)

      .concat(LINEA)
      .concat(ALIGN_CENTER);

    if (data.facturaCliente) {
      // create QRCode Image
      async function getQRCode(content) {
        return new Promise((resolve, reject) => {
          QRCode.toDataURL(content, (err, url) => {
            if (err) return reject(err);

            loadImage(url).then(resolve, reject);
          });
        });
      }
      const qrcode = await getQRCode(data.facturaCliente);
      let encoder = new EscPosEncoder();
      const result = encoder
        .initialize()
        .align('center')
        .image(qrcode, 320, 320)
        .encode();

      res = res
        .concat(ALIGN_CENTER)
        .concat(lineaEsc('DESCARGUE SU FACTURA'))
        .concat([...result])
        .concat(ALIGN_CENTER)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA);
    }

    res = res.concat(lineaEsc('Gracias por su pedido'));
    // .concat(lineaEsc('By FunEats'));

    res = res
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA);

    res = res.concat(CORTE);
    // var mergedArray = new Uint8Array(logoEncode.length + res.length);

    return new Uint8Array(res);
  });

  //let img = await getBase64FromImageUrl("https://upload.wikimedia.org/wikipedia/commons/thumb/9/9a/PNG_transparency_demonstration_2.png/600px-PNG_transparency_demonstration_2.png")
};

export const impresionFacturaSimplificada = async function (
  data,
  factSimplificada,
  logo
) {
  var res = [];
  return new Promise((resolve) => {
    if (!logo) resolve([]);

    var img = new Image();

    //listener has to be added before setting the src attribute in case the image is cached
    img.addEventListener('load', (r) => {
      let encoder = new EscPosEncoder();
      let result = encoder.image(img, 480, 240, 'atkinson').encode();
      resolve(result);
    });
    img.src = 'data:image/png;base64,' + logo;
  }).then(async (logo) => {
    res = res.concat(ESC_INIT).concat(ALIGN_CENTER);
    // .concat([...logo]);

    const negocio = data.copyNegocio;
    const restaurante = data.copyRest;
    const timezone =
      restaurante &&
      restaurante.confGeneral &&
      restaurante.confGeneral.zonaHoraria
        ? restaurante.confGeneral.zonaHoraria
        : 'Europe/Madrid';
    var fechaMoment = data.fechaServidor.seconds
      ? moment.unix(data.fechaServidor.seconds).tz(timezone)
      : moment.unix(data.fechaServidorFinalEjecucion.seconds).tz(timezone);

    const formaPago = idToTexto(
      Array.isArray(factSimplificada.formaPago)
        ? factSimplificada.formaPago.join(', ')
        : factSimplificada.formaPago
    ).toUpperCase();
    res = res
      .concat(ALIGN_CENTER)
      .concat(lineaEsc(quitarAcentos(data.copyNegocio.nombre)))
      .concat(lineaEsc(quitarAcentos(data.copyRest.direccion)))
      .concat(lineaEsc(data.copyRest.cif + ' | ' + data.copyRest.telefono))
      .concat(BOLD);

    res = res
      .concat(FUENTE_NORMAL) //miriad undereline
      .concat(STOP_INVERTIR)
      .concat(LINEA)
      .concat(ALIGN_CENTER)
      .concat(BOLD)
      .concat(lineaEsc('FACTURA SIMPLIFICADA'))
      .concat(lineaEsc('NUMERO DE FACTURA: ' + factSimplificada.codigoFactura))
      .concat(LINEA)
      .concat(ALIGN_LEFT)
      .concat(BOLD)
      .concat(
        lineaEsc(
          'FECHA PEDIDO: ' +
            fechaMoment.format('DD/MM/YYYY') +
            ' - ' +
            fechaMoment.format('HH:mm')
        )
      )
      .concat(
        lineaEsc(
          'FECHA EMISION FACTURA: ' +
            moment.unix(factSimplificada.moment).format('DD/MM/YYYY') +
            ' - ' +
            moment.unix(factSimplificada.moment).format('HH:mm')
        )
      );

    res = res.concat(lineaEsc('FORMA PAGO: ' + formaPago));

    res = res
      .concat(ALIGN_LEFT)
      .concat(BOLD)
      .concat(LINEA_HORIZONTAL)
      .concat(ALIGN_LEFT);

    var impuestos = 0;

    var productos = factSimplificada.productos.filter((p) => p.cantidad > 0);

    productos
      .sort((a, b) => {
        return a.recargo
          ? -1
          : b.recargo
          ? 1
          : a.ordenCat < b.ordenCat
          ? 1
          : b.ordenCat < a.ordenCat
          ? -1
          : a.orden - b.orden;
      })
      .forEach((prd) => {
        let s = '';
        if (prd.porPeso) {
          s =
            s +
            (prd.cantidad + 'kg ').padEnd(5) +
            quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre).substring(
              0,
              28
            );
        } else {
          s =
            s +
            (prd.cantidad + 'x ').padEnd(5) +
            quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre).substring(
              0,
              28
            );
        }

        s = s.padEnd(34);
        let precioUnitario = !!prd.precioProductoTotalUnitario
          ? prd.precioProductoTotalUnitario
          : prd.precio;
        precioUnitario = mostrarMoneda(precioUnitario, true).padStart(7);
        //if (prd.porPeso || prd.cantidad > 1)
        //    s = s + precioUnitario

        let precioTotal = !!prd.precioProductoTotal
          ? prd.precioProductoTotal
          : prd.precioProductoTotalUnitario * prd.cantidad;
        impuestos = impuestos + redondeo(prd.impuestoCantidad * prd.cantidad);
        let precioTotalString = mostrarMoneda(precioTotal, true).padStart(7);
        s = s + precioUnitario + precioTotalString;
        res = res.concat(lineaEsc(s));
        if (prd.opciones) {
          let opcionesImpresas = [];
          prd.opciones.forEach((e, i) => {
            if (!opcionesImpresas.includes(e)) {
              opcionesImpresas.push(e);
              var ocurrencias = prd.opciones.reduce(
                (a, v) => (v === e ? a + 1 : a),
                0
              );
              let s = '';
              s =
                ocurrencias > 1
                  ? '   ' +
                    ocurrencias +
                    '- ' +
                    quitarAcentos(prd.opcionesName[i])
                  : '   - ' + quitarAcentos(prd.opcionesName[i]);
              s = s.padEnd(43);
              /*let precio = "" + mostrarMoneda(prd.opcionesPrecio[i] * prd.cantidad);
                            precio = precio.padStart(5);
                            s = s + precio*/
              // carritoString = carritoString + encoder.line(s)
              res = res.concat(lineaEsc(s));
            }
          });
        }
      });
    if (data.recargo && data.recargo > 0) {
      let s = '';
      // carritoString = carritoString + encoder.line(s)
      // res = res.concat(lineaEsc(s))
      s = '';
      s = s + '   Recargo domicilio ';
      s = s.padEnd(36);
      let precio = mostrarMoneda(data.recargo).padStart(5);
      s = s + precio;
      // carritoString = carritoString + encoder.line(s)
      res = res.concat(lineaEsc(s));
    }

    const impuestosImprimir = factSimplificada.impuestosTotalesPago
      ? factSimplificada.impuestosTotalesPago
      : impuestos;
    let precioTotal = factSimplificada.pagado;
    // var carritoProductos = new Uint8Array(carritoString);
    var precioFinal = 'TOTAL: ' + mostrarMoneda(precioTotal);
    precioFinal = precioFinal;

    res = res
      .concat(ALIGN_LEFT)
      .concat(LINEA_HORIZONTAL)
      .concat(ALIGN_RIGHT)
      .concat(FUENTE_GRANDE)
      .concat(FONT_HACHATADA);
    if (
      factSimplificada.formaPago == 'efectivo' &&
      factSimplificada.entregado !== 'undefined'
    ) {
      //sólo un pago
      res = res
        .concat(
          lineaEsc(
            'ENTREGADO: ' + mostrarMoneda(factSimplificada.entregado, true)
          )
        )
        .concat(
          lineaEsc(' CAMBIO: ' + mostrarMoneda(factSimplificada.cambio, true))
        );
    }
    let baseImponibleImprimir = factSimplificada.baseImponiblePago
      ? factSimplificada.baseImponiblePago
      : parseFloat(precioTotal) - impuestosImprimir;
    res = res
      .concat(BOLD_NORMAL)
      .concat(
        lineaEsc('BASE IMPONIBLE:   ' + mostrarMoneda(baseImponibleImprimir))
      )
      .concat(lineaEsc('IMPUESTOS:   ' + mostrarMoneda(impuestosImprimir)))
      .concat(FUENTE_GRANDE)
      .concat(lineaEsc(precioFinal))
      .concat(LINEA)
      .concat(FUENTE_NORMAL)

      .concat(LINEA)
      .concat(ALIGN_CENTER);

    if (factSimplificada.ficheroFactura) {
      // create QRCode Image
      async function getQRCode(content) {
        return new Promise((resolve, reject) => {
          QRCode.toDataURL(content, (err, url) => {
            if (err) return reject(err);

            loadImage(url).then(resolve, reject);
          });
        });
      }
      const qrcode = await getQRCode(factSimplificada.ficheroFactura);
      let encoder = new EscPosEncoder();
      const result = encoder
        // .initialize()
        // .align("center")
        // .image(qrcode, 320, 320)
        .encode();

      res = res
        .concat(ALIGN_CENTER)
        .concat(lineaEsc('DESCARGUE SU FACTURA'))
        .concat(ESC_INIT)
        .concat([...result])
        .concat(ALIGN_CENTER)
        .concat(LINEA)
        .concat(LINEA)
        .concat(LINEA);
    }

    res = res.concat(lineaEsc('Gracias por su pedido'));
    // .concat(lineaEsc('By FunEats'));

    res = res
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA);

    res = res.concat(CORTE);
    return new Uint8Array(res);
  });
};

export const imprimirTicketCocina = function (
  data,
  impObj,
  filtrarImpresos = false
) {
  //filtrar impresos true devuelve imprime solo los productos no impresos. Devuelve {datos, productos}
  const categoriasExcluir = impObj.categoriasSelects
    ? impObj.categoriasSelects
    : [];
  const productosSi = impObj.productosSelectsSi
    ? impObj.productosSelectsSi
    : [];
  const productosNo = impObj.productosSelectsNo
    ? impObj.productosSelectsNo
    : [];
  const reglasEscritura = impObj.reglasEscritura;
  const sizeCocina = impObj.sizeCocina ? impObj.sizeCocina : 'big';
  var res = []; //datos;
  var productosImprime = [];
  const negocio = data.copyNegocio;
  const restaurante = data.copyRest;
  const timezone = restaurante.confGeneral.zonaHoraria
    ? restaurante.confGeneral.zonaHoraria
    : 'Europe/Madrid';
  var fechaMoment = data.fechaServidor.toDate()
    ? moment(data.fechaServidor.toDate()).tz(timezone)
    : moment(data.fechaServidorFinalEjecucion.toDate()).tz(timezone);
  res = res
    .concat(ESC_INIT)
    .concat(ALIGN_CENTER)
    .concat(
      lineaEsc(
        fechaMoment.format('DD/MM/YYYY') +
          ' ' +
          fechaMoment.format('HH:mm') +
          ' | ' +
          (data.modoCopy ? data.modoCopy.nombre : data.modo).toUpperCase() +
          ' | COCINA'
      )
    )
    .concat(FUENTE_NORMAL)
    //underline
    .concat(ALIGN_CENTER)
    // .concat(lineaEsc('TICKET DIA: 00' + data.numPedidoRestDia))
    .concat(
      lineaEsc(
        'TICKET: 00' +
          data.numPedidoRest +
          '  ' +
          'DIA: 00' +
          data.numPedidoRestDia
      )
    )
    // .concat(lineaEsc('TICKET REST DIA: 00' + data.numPedidoRestDia))
    .concat(BOLD)
    .concat(FUENTE_MAS_GRANDE)
    .concat(LINEA)
    .concat(INVERTIR);

  if (data.multiple && data.multipleHijos && data.multipleHijos.length) {
    cabecera = cabecera.line('PEDIDO MULTIPLE').raw([0x1d, 0x21, 0x00]);
    data.multipleHijos.forEach((h) => {
      cabecera = cabecera.line(h);
    });
    cabecera = cabecera.newline();
  } else if (data.multiplePadre) {
    cabecera = cabecera.line(
      'MULTI-00' +
        data.numPedidoRestDiaDirectorio +
        ' - ' +
        data.modo.toUpperCase()
    );
  } else {
    let cabeceraMostrar = '';
    if (data.copyMesa && data.modoCopy) {
      cabeceraMostrar =
        'MESA ' + data.copyMesa.nombre + ' ' + data.modoCopy.nombre;
    } else if (data.modoCopy) {
      let num = data.numPedidoRestDiaModoMostrar
        ? ' ' + data.numPedidoRestDiaModoMostrar
        : data.numPedidoRestDia
        ? ' ' + data.numPedidoRestDia
        : '';
      cabeceraMostrar = data.modoCopy.nombre + num;
    }
    if (!cabeceraMostrar) {
      switch (data.modo) {
        case 'domicilio':
          res = 'DOMICILIO';
          break;
        case 'recoger':
          res = 'RECOGER';
          break;
        case 'mesa':
          res = 'MESA ' + data.copyMesa.nombre;
          break;
        case 'mostrador':
          res = 'MOSTRADOR ';
          break;
      }
      cabeceraMostrar = res;
    }

    // let cabeceraMostrar = (data.modo == 'mesa')
    //     ? data.copyMesa.nombre
    //     : (data.modoCopy ? data.modoCopy.nombre : data.modo).toUpperCase()
    res = res.concat(lineaEsc(cabeceraMostrar));
  }

  res = res.concat(LINEA).concat(STOP_INVERTIR);

  if (!!data.comentarioPedidoTpv && !!data.comentarioPedidoTpv.length > 0) {
    res = res
      .concat(FUENTE_NORMAL)
      .concat(LINEA_HORIZONTAL)
      .concat(ALIGN_LEFT)
      .concat(FONT_HACHATADA)
      .concat(lineaEsc(data.comentarioPedidoTpv))
      .concat(FUENTE_NORMAL)
      .concat(LINEA_HORIZONTAL);
  }
  if (!!data.notaRestaurante) {
    res = res
      .concat(ALIGN_LEFT)
      .concat(FUENTE_NORMAL)
      .concat(LINEA_HORIZONTAL)
      .concat(FONT_HACHATADA)
      .concat(lineaEsc('COMENTARIO CLIENTE'))
      .concat(lineaEsc(data.notaRestaurante))
      .concat(FUENTE_NORMAL)
      .concat(LINEA_HORIZONTAL);
  }
  res = res
    .concat(BOLD)
    .concat(ALIGN_LEFT)
    .concat(LINEA)
    .concat(sizeCocina === 'big' ? FUENTE_GRANDE : FUENTE_MEDIANA);

  var impuestos = 0;
  var productos = data.productos;
  var imprime = false;

  productos.forEach((prd, linea) => {
    var cantidadImprimir = prd.cantidad;
    var cantidadCarrito = !!data.eliminado ? 0 : prd.cantidad;
    let impresionCocina = !!data.impresionCocina ? data.impresionCocina : [];

    if (
      (!categoriasExcluir.includes(prd.categoria) &&
        !productosNo.includes(prd.id)) ||
      productosSi.includes(prd.id)
    ) {
      let imprimeConcreto = false; //va a imprimir este producto
      let idAux = [
        prd.id,
        ...(!!Array.isArray(prd.opciones) ? prd.opciones : []),
      ].join('_');

      let foundImpresion = impresionCocina.find((imP) => imP.id == idAux); //se ha imprimido algún ticket de cocina
      let anular = false;
      if (filtrarImpresos) {
        if (!!foundImpresion) {
          if (foundImpresion.cantidad < prd.cantidad && prd.cantidad != 0) {
            imprime = true;
            imprimeConcreto = true;
            cantidadImprimir = cantidadCarrito - foundImpresion.cantidad;
          } else if (foundImpresion.cantidad > cantidadCarrito) {
            imprime = true;
            imprimeConcreto = true;
            cantidadImprimir = foundImpresion.cantidad - cantidadCarrito;
            anular = true;
          }
        } else if (cantidadCarrito != 0) {
          imprime = true;
          imprimeConcreto = true;
        }
        if (imprimeConcreto) {
          productosImprime.push({
            id: idAux,
            cantidad: anular ? -cantidadImprimir : cantidadImprimir,
          });
        }
      } else if (cantidadCarrito != 0) {
        productosImprime.push({
          id: idAux,
          cantidad: prd.cantidad,
        });
        imprime = true;
        imprimeConcreto = true;
      }
      if (imprimeConcreto) {
        let nombreImprimir = prd.nombre;

        if (reglasEscritura && Array.isArray(reglasEscritura)) {
          var found = reglasEscritura.find((r) => r.antes == nombreImprimir);
          if (found) {
            nombreImprimir = found.despues;
          }
        }
        nombreImprimir = prd.nombreTpv ? prd.nombreTpv : nombreImprimir;
        if (anular) {
          nombreImprimir = '[ANUL] ' + nombreImprimir;
        }
        nombreImprimir = nombreImprimir.substring(0, 21);
        let s = '';
        if (prd.porPeso) {
          s = s + prd.cantidad + 'kg ' + quitarAcentos(nombreImprimir);
        } else {
          s = s + cantidadImprimir + 'x ' + quitarAcentos(nombreImprimir);
        }

        res = res.concat(lineaEsc(s));
        if (prd.opciones) {
          prd.opciones.forEach((e, i) => {
            var nombreOp = prd.opcionesName[i];
            if (reglasEscritura && Array.isArray(reglasEscritura)) {
              var foundOp = reglasEscritura.find((r) => r.antes == nombreOp);
              if (foundOp) {
                nombreOp = foundOp.despues;
              }
            }
            nombreOp =
              prd.opcionesTpv && prd.opcionesTpv[i]
                ? prd.opcionesTpv[i]
                : nombreOp;
            nombreOp = nombreOp.substring(0, 20);
            if (nombreOp && nombreOp.length > 0) {
              let s = '';
              s = '   -' + quitarAcentos(nombreOp);

              res = res.concat(lineaEsc(s));
            }
          });
        }
        if (prd.comentarioCocina && prd.comentarioCocina.length > 0) {
          s = 'COMENTARIO: ' + quitarAcentos(prd.comentarioCocina);
          res = res.concat(lineaEsc(s));
        }
      }
    }
  });
  if (!imprime) return false;
  res = res
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA);

  res = res.concat(CORTE);
  return { productos: productosImprime, datos: new Uint8Array(res) };
};

export const zcierre = async function (
  pedidos,
  resumenes,
  restaurante,
  fecha = null
) {
  var res = [];
  return new Promise((resolve) => {
    if (!restaurante.logo) resolve([]);

    var img = new Image();

    //listener has to be added before setting the src attribute in case the image is cached
    img.addEventListener('load', (r) => {
      let encoder = new EscPosEncoder();
      let result = encoder.image(img, 480, 240, 'atkinson').encode();
      resolve(result);
    });
    img.src = 'data:image/png;base64,' + restaurante.logo;
  }).then(async (logo) => {
    // res = res
    //   .concat(ESC_INIT)
    //   .concat(ALIGN_CENTER)
    //   .concat([...logo]);

    const timezone = restaurante.timezone
      ? restaurante.timezone
      : 'Europe/Madrid';
    let fechaDefinitiva = !fecha ? moment() : fecha;
    let fechaFormateada = !fecha
      ? 'Fecha: ' + fechaDefinitiva.tz(timezone).format('DD/MM/YYYY HH:mm')
      : 'Cierre del dia ' + fechaDefinitiva.tz(timezone).format('DD/MM/YYYY');
    res = res
      .concat(ESC_INIT)
      .concat(ALIGN_CENTER)
      .concat(
        lineaEsc(
          quitarAcentos(restaurante.nombreNegocio + ' - ' + restaurante.nombre)
        )
      )
      .concat(lineaEsc(quitarAcentos(restaurante.direccion)))
      .concat(lineaEsc(restaurante.cif + ' | ' + restaurante.telefono))
      .concat(BOLD)
      .concat(BOLD)
      .concat(FUENTE_MAS_GRANDE)
      .concat(LINEA)
      .concat(INVERTIR)
      .concat(ALIGN_CENTER)
      .concat(lineaEsc('Z CIERRE'));
    res = res
      .concat(ESC_INIT)
      .concat(FUENTE_NORMAL)
      //   .concat(lineaEsc(fechaFormateada))
      .concat(LINEA)
      .concat(LINEA)
      .concat(LINEA)
      .concat(BOLD)
      .concat(ALIGN_LEFT)
      .concat(
        lineaEsc('Unidad de venta: '.padEnd(22) + restaurante.cuenta.nombre)
      )
      .concat(lineaEsc('Empleados: '.padEnd(22) + restaurante.cuenta.nombre))
      .concat(lineaEsc('Cuentas: '.padEnd(22) + restaurante.cuenta.nombre))
      .concat(lineaEsc('Fecha: '.padEnd(22) + restaurante.cuenta.nombre))
      .concat(lineaEsc('Impresion: '.padEnd(22) + restaurante.cuenta.nombre))

      .concat(ALIGN_LEFT)
      .concat(BOLD)
      .concat(LINEA_HORIZONTAL)
      .concat(ALIGN_CENTER)
      .concat(lineaEsc('TOTALES'))
      .concat(lineaEsc('Concepto'.padEnd(34) + 'Total'.padStart(7)))
      .concat(LINEA_HORIZONTAL)
      .concat(lineaEsc('Numero tickets'.padEnd(34) + '12'.padStart(7)))
      .concat(lineaEsc('Serie FT'.padEnd(34) + '34'.padStart(7)))
      .concat(lineaEsc('Serie FN'.padEnd(34) + '34'.padStart(7)))
      .concat(lineaEsc('Serie FA (Abonos)'.padEnd(34) + '34'.padStart(7)))
      .concat(lineaEsc('Importe facturado'.padEnd(34) + '23'.padStart(7)))
      .concat(lineaEsc('Importe total'.padEnd(34) + '23'.padStart(7)))
      .concat(lineaEsc('Ticket medio'.padEnd(34) + '23'.padStart(7)));

    //   .concat(lineaEsc("Empleados: " + restaurante.cuenta.nombre))
    //   .concat(lineaEsc(("Cuentas: " + restaurante.cuenta.nombre).padEnd(28)))
    //   .concat(lineaEsc(("Fecha: " + restaurante.cuenta.nombre).padEnd(28)))
    //   .concat(lineaEsc(("Impresion: " + restaurante.cuenta.nombre).padEnd(28)));

    res = res
      .concat(
        lineaEsc(
          'TOTAL: '.padEnd(35) +
            mostrarMoneda(resumenes.cobrados.totales.total.toString()).padStart(
              5
            )
        )
      )
      .concat(
        lineaEsc(
          'TOTAL PEDIDOS: '.padEnd(35) +
            mostrarMoneda(
              resumenes.cobrados.totales.numPedidos.toString()
            ).padStart(5)
        )
      )
      .concat(
        lineaEsc(
          'TOTAL EFECTIVO: '.padEnd(35) +
            mostrarMoneda(
              resumenes.cobrados.totales.efectivo.toString()
            ).padStart(5)
        )
      )
      .concat(
        lineaEsc(
          'TOTAL TARJETA DOMICILIO: '.padEnd(35) +
            mostrarMoneda(
              resumenes.cobrados.totales.tarjeta_domicilio.toString()
            ).padStart(5)
        )
      )
      .concat(
        lineaEsc(
          'TOTAL TARJETA ONLINE: '.padEnd(35) +
            mostrarMoneda(
              resumenes.cobrados.totales.tarjeta_online.toString()
            ).padStart(5)
        )
      )
      .concat(
        lineaEsc(
          'TOTAL TARJETA LOCAL: '.padEnd(35) +
            mostrarMoneda(
              resumenes.cobrados.totales.tarjeta_local.toString()
            ).padStart(5)
        )
      )
      .concat(
        lineaEsc(
          'TOTAL OTRO: '.padEnd(35) +
            mostrarMoneda(resumenes.cobrados.totales.otro.toString()).padStart(
              5
            )
        )
      )
      .concat(LINEA);

    res = res.concat(LINEA);
    res = res.concat(LINEA).concat(LINEA).concat(LINEA).concat(LINEA);

    res = res.concat(CORTE);
    return new Uint8Array(res);
  });
};

export const imprimirPararCocina = function (data) {
  var res = [];
  const negocio = data.copyNegocio;
  const restaurante = data.copyRest;
  const timezone = restaurante.confGeneral.zonaHoraria
    ? restaurante.confGeneral.zonaHoraria
    : 'Europe/Madrid';
  var fechaMoment = moment().tz(timezone);

  res = res
    .concat(ESC_INIT)
    .concat(LINEA)
    .concat(ALIGN_CENTER)
    .concat(FUENTE_NORMAL)
    .concat(
      lineaEsc(
        'FECHA DEL MENSAJE: ' +
          fechaMoment.format('DD/MM/YYYY') +
          ' Hora: ' +
          fechaMoment.format('HH:mm')
      )
    )
    .concat(FUENTE_GRANDE)
    .concat(LINEA)
    .concat(lineaEsc('PARAR COCINA'))
    .concat(lineaEsc('TICKET ' + data.numPedidoRest))
    .concat(lineaEsc('TICKET DIA ' + data.numPedidoRestDia))
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(CORTE);

  return new Uint8Array(res);
};

export const imprimirReanudarCocina = function (data) {
  var res = [];
  const negocio = data.copyNegocio;
  const restaurante = data.copyRest;
  const timezone = restaurante.confGeneral.zonaHoraria
    ? restaurante.confGeneral.zonaHoraria
    : 'Europe/Madrid';
  var fechaMoment = moment().tz(timezone);

  res = res
    .concat(ESC_INIT)
    .concat(LINEA)
    .concat(ALIGN_CENTER)
    .concat(FUENTE_NORMAL)
    .concat(
      lineaEsc(
        'FECHA DEL MENSAJE: ' +
          fechaMoment.format('DD/MM/YYYY') +
          ' Hora: ' +
          fechaMoment.format('HH:mm')
      )
    )
    .concat(FUENTE_GRANDE)
    .concat(LINEA)
    .concat(lineaEsc('REANUDAR COCINA'))
    .concat(lineaEsc('TICKET ' + data.numPedidoRest))
    .concat(lineaEsc('TICKET DIA ' + data.numPedidoRestDia))
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(CORTE);

  return new Uint8Array(res);
};

export const abrirCaja = function () {
  var res = [];
  res = res.concat(ESC_INIT).concat(ABRIR_CAJON);
  return new Uint8Array(res);
};

//QZ
function lineaQz(s) {
  return quitarAcentos(s) + '\x0A';
}
// [0x1d, 0x56, 49];
const QZ_INIT = '\x1B' + '\x40';
const QZ_CORTE = '\x1d' + '\x56' + '\x31';
const QZ_ABRIR_CAJON = '\x1b' + '\x70' + '\0x00' + '\x19' + '\xfa';

const QZ_ALIGN_CENTER = '\x1B' + '\x61' + '\x31'; // center align
const QZ_BOLD = '\x1B' + '\x45' + '\x0D';
const QZ_LINEA = '\x0a';
const QZ_FUENTE_MEDIANA = '\x1d' + '\x21' + '\x10';
const QZ_FUENTE_GRANDE = '\x1d' + '\x21' + '\x11';
const QZ_FUENTE_NORMAL = '\x1d' + '\x21' + '\x00';
const QZ_BOLD_NORMAL = '\x1b' + '\x21' + '\x00';
const QZ_FUENTE_MAS_GRANDE = '\x1d' + '\x21' + '\x22';
// [0x1d, 0x21, 34];
const QZ_ALIGN_LEFT = '\x1b' + '\x61' + '\x00';
const QZ_ALIGN_RIGHT = '\x1b' + '\x61' + '\x02';
const QZ_INVERTIR = '\x1d' + '\x42' + '\x01';
const QZ_STOP_INVERTIR = '\x1d' + '\x42' + '\x00';
const QZ_FONT_HACHATADA = '\x1b' + '\x21' + '\x34';
const QZ_ESPACIO_ENTRE_CARACTER = '\x1b' + '\x20' + '\x02';

const anchoPapel = 80; // ancho del papel en mm
const caracteresPorMilimetro = 2; // esto puede variar según la configuración de tu impresora

const cantidadCaracteres = anchoPapel * caracteresPorMilimetro;
const QZ_LINEA_HORIZONTAL = '-'.padStart(cantidadCaracteres, '-') + '\x0A';

export const imprimirTicketTipo2Qz = async function (
  data,
  logo,
  productosFinales
) {
  const negocio = data.copyNegocio;
  const restaurante = data.copyRest;
  const timezone =
    restaurante &&
    restaurante.confGeneral &&
    restaurante.confGeneral.zonaHoraria
      ? restaurante.confGeneral.zonaHoraria
      : 'Europe/Madrid';
  var fechaMoment = data.fechaServidor.seconds
    ? moment.unix(data.fechaServidor.seconds).tz(timezone)
    : moment.unix(data.fechaServidorFinalEjecucion.seconds).tz(timezone);
  var formaPago =
    data.forma_pago == 'tarjeta_online'
      ? 'PAGADO ' + idToTexto(data.forma_pago).toUpperCase()
      : Array.isArray(data.forma_pago)
      ? idToTexto(data.forma_pago.join(', ')).toUpperCase()
      : idToTexto(data.forma_pago).toUpperCase();
  var cabeceraMostrar = '';
  if (data.copyMesa && data.modoCopy) {
    cabeceraMostrar =
      'MESA ' + data.copyMesa.nombre + ' ' + data.modoCopy.nombre;
  } else if (data.modoCopy) {
    let num = data.numPedidoGofo
      ? ' G' + data.numPedidoGofo
      : data.numPedidoRestDiaModoMostrar
      ? ' ' + data.numPedidoRestDiaModoMostrar
      : data.numPedidoRestDia
      ? ' ' + data.numPedidoRestDia
      : '';
    cabeceraMostrar = data.modoCopy.nombre + num;
  }
  if (!cabeceraMostrar) {
    switch (data.modo) {
      case 'domicilio':
        res = '';
        break;
      case 'recoger':
        res = 'RECOGER';
        break;
      case 'mesa':
        res = 'MESA ' + data.copyMesa.nombre;
        break;
      case 'mostrador':
        res = 'MOSTRADOR ';
        break;
    }
    cabeceraMostrar = res;
  }

  let direccion =
    data.modo == 'domicilio'
      ? quitarAcentos(
          data.direccion.calle +
            ' ' +
            data.direccion.numero +
            ' ' +
            data.direccion.adicional +
            ', ' +
            data.direccion.municipio +
            ' ' +
            data.direccion.cp +
            ' ' +
            data.direccion.provincia
        )
      : data.modo == 'recogida'
      ? 'RECOGIDA'
      : '';

  let devolverQz = [];

  devolverQz.push(...[QZ_INIT]);
  if (logo) {
    devolverQz.push({
      type: 'raw',
      format: 'image',
      data: 'data:image/png;base64,' + logo,
      options: { language: 'ESCPOS', dotDensity: 'double' },
    });
  }
  devolverQz.push(
    ...[
      QZ_INIT, // init
      QZ_ALIGN_CENTER,
      lineaQz(data.copyNegocio.nombre),
      lineaQz(data.copyRest.direccion),
      lineaQz(data.copyRest.cif + ' | ' + data.copyRest.telefono),
      QZ_ALIGN_CENTER,
      QZ_BOLD,
      lineaQz(
        'TICKET: 00' +
          data.numPedidoRest +
          ' - ' +
          fechaMoment.format('DD/MM/YYYY') +
          ' - ' +
          fechaMoment.format('HH:mm')
      ),
      // QZ_BOLD,
      QZ_FUENTE_GRANDE,
      QZ_LINEA,
      QZ_INVERTIR,
      lineaQz(' ' + cabeceraMostrar + ' '),
      QZ_FUENTE_NORMAL,
      QZ_STOP_INVERTIR,
      QZ_LINEA,
      QZ_ALIGN_CENTER,
      QZ_BOLD,
      lineaQz('DIRECCION DE ENTREGA'),
      lineaQz(direccion),
      QZ_ALIGN_LEFT,
    ]
  );

  if (
    (data.modo == 'domicilio' || data.modo == 'recogida') &&
    !!data.copyUser &&
    !!data.copyUser.phoneNumber
  )
    devolverQz.push(
      lineaQz('TELEFONO: ' + data.copyUser.phoneNumber.padStart(5))
    );

  if (data.modo == 'domicilio' || data.modo == 'recoger')
    devolverQz.push(
      lineaQz(
        data.modo == 'domicilio'
          ? 'TIEMPO ESTIMADO: ' + data.copyRest.tiempoDomicilio + ' min.'
          : 'TIEMPO ESTIMADO: ' + data.copyRest.tiempoRecogida + ' min.'
      )
    );

  if (data.restante) {
    devolverQz.push(lineaQz('RESTANTE POR PAGAR'));
  } else if (!!data.cobrado) {
    devolverQz.push(lineaQz('FORMA PAGO: ' + formaPago));
  }

  if (data.notaRestaurante)
    devolverQz.push(
      lineaQz(
        data.notaRestaurante
          ? 'COMENTARIO CLIENTE: ' + data.notaRestaurante
          : ''
      )
    );
  devolverQz.push(QZ_ALIGN_LEFT);
  devolverQz.push(QZ_BOLD);
  devolverQz.push(QZ_LINEA_HORIZONTAL);
  devolverQz.push(QZ_ALIGN_LEFT);
  var impuestos = 0;
  var productos = data.productos.filter((p) => p.cantidad > 0);
  var productosRecorrerArray = productosRecorrer(data);
  productosFinales
    .sort((a, b) =>
      a.ordenCat > b.ordenCat
        ? 1
        : b.ordenCat > a.ordenCat
        ? -1
        : a.orden > b.orden
        ? 1
        : -1
    )
    .forEach((prd) => {
      let s = '';
      if (prd.porPeso) {
        s =
          s +
          (prd.cantidad + 'kg ').padEnd(5) +
          quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre).substring(
            0,
            23
          );
      } else {
        s =
          s +
          (prd.cantidad + 'x ').padEnd(5) +
          quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre).substring(
            0,
            23
          );
      }

      s = s.padEnd(28);
      let precioUnitario = !!prd.precioProductoTotalUnitario
        ? prd.precioProductoTotalUnitario
        : prd.precio;
      precioUnitario = mostrarMoneda(precioUnitario, true).padStart(7);
      //if (prd.porPeso || prd.cantidad > 1)
      //    s = s + precioUnitario

      let precioTotal = !!prd.precioProductoTotal
        ? prd.precioProductoTotal
        : prd.precio * prd.cantidad;
      impuestos = impuestos + redondeo(prd.impuestoCantidad * prd.cantidad);
      let precioTotalString = mostrarMoneda(precioTotal, true).padStart(7);
      s = s + precioUnitario + precioTotalString;
      devolverQz.push(lineaQz(s));
      if (prd.opciones) {
        let opcionesImpresas = [];
        prd.opciones.forEach((e, i) => {
          if (!opcionesImpresas.includes(e)) {
            let arrayUsar = Array.isArray(prd.opcionesNombreEs)
              ? prd.opcionesNombreEs
              : prd.opcionesName;
            opcionesImpresas.push(e);
            var ocurrencias = prd.opciones.reduce(
              (a, v) => (v === e ? a + 1 : a),
              0
            );
            let s = '';
            s =
              ocurrencias > 1
                ? '   ' + ocurrencias + '- ' + quitarAcentos(arrayUsar[i])
                : '   - ' + quitarAcentos(arrayUsar[i]);
            s = s.padEnd(38);
            /*let precio = "" + mostrarMoneda(prd.opcionesPrecio[i] * prd.cantidad);
                              precio = precio.padStart(5);
                              s = s + precio*/
            // carritoString = carritoString + encoder.line(s)

            devolverQz.push(lineaQz(s));
          }
        });
      }
    });
  if (data.recargo && data.recargo > 0) {
    let s = '';
    // carritoString = carritoString + encoder.line(s)
    // res = res.concat(lineaEsc(s))
    s = '';
    s = s + '   Recargo domicilio ';
    s = s.padEnd(36);
    let precio = mostrarMoneda(data.recargo).padStart(5);
    s = s + precio;
    // carritoString = carritoString + encoder.line(s)

    devolverQz.push(lineaQz(s));
  }
  const impuestosImprimir = data.impuestosTotal
    ? data.impuestosTotal
    : impuestos;

  let precioTotal = data.precioTotal;
  // var carritoProductos = new Uint8Array(carritoString);
  var precioFinal = 'TOTAL: ' + mostrarMoneda(precioTotal);
  precioFinal = precioFinal;

  devolverQz.push(QZ_ALIGN_LEFT);
  devolverQz.push(QZ_LINEA_HORIZONTAL);
  devolverQz.push(QZ_ALIGN_RIGHT);
  devolverQz.push(QZ_FUENTE_GRANDE);
  devolverQz.push(QZ_FONT_HACHATADA);

  if (!!data.cobrado) {
    if (
      data.forma_pago == 'efectivo' &&
      Array.isArray(data.cobradoDesglose) &&
      data.cobradoDesglose.length == 1
    ) {
      //sólo un pago
      let cambioFound = data.cobradoDesglose.find(
        (f) => f.formaPago == 'efectivo'
      );
      if (!!cambioFound) {
        devolverQz.push(
          lineaQz('ENTREGADO: ' + mostrarMoneda(cambioFound.entregado, true))
        );
        devolverQz.push(
          lineaQz(' CAMBIO: ' + mostrarMoneda(cambioFound.cambio, true))
        );
      }
    }
  }

  devolverQz.push(QZ_BOLD_NORMAL);
  devolverQz.push(
    lineaQz(
      'BASE IMPONIBLE:   ' +
        mostrarMoneda(parseFloat(precioTotal) - impuestosImprimir)
    )
  );
  devolverQz.push(lineaQz('IMPUESTOS:   ' + mostrarMoneda(impuestosImprimir)));
  devolverQz.push(QZ_FUENTE_GRANDE);
  devolverQz.push(lineaQz(precioFinal));
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_FUENTE_NORMAL);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_ALIGN_CENTER);

  if (data.facturaCliente) {
    // create QRCode Image
    async function getQRCode(content) {
      return new Promise((resolve, reject) => {
        QRCode.toDataURL(content, (err, url) => {
          if (err) return reject(err);

          loadImage(url).then(resolve, reject);
        });
      });
    }
    const qrcode = await getQRCode(data.facturaCliente);

    devolverQz.push(QZ_ALIGN_CENTER);
    devolverQz.push(lineaQz('DESCARGUE SU FACTURA'));
    devolverQz.push({
      type: 'raw',
      format: 'image',
      data: 'data:image/png;base64,' + qrcode,
      // "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAJFBMVEX" +
      // "///8kIiQAAADMzMyZmZlmZmb8/vxcXlz//8z//5lmzAAzZgBFSkm0AAAAVklEQVQYlYWPUQ6AMAhD" +
      // "ATvZ5v3va6duYybG90P60gQQ+cBuZs47KVG4exA/BVXLbRZT1ZZle1YYQMM8UVBEgKWBRdR6UKRXg" +
      // "5kCAx7QrkidK48/+7MnGPwCSdhQpuEAAAAASUVORK5CYII=",
      options: { language: 'ESCPOS', dotDensity: 'double' },
    });
  }
  devolverQz.push(QZ_ALIGN_CENTER);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(lineaQz('Gracias por su pedido'));
  // devolverQz.push(lineaQz('By FunEats'));
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);

  devolverQz.push(QZ_CORTE);
  devolverQz.push(QZ_INIT);

  return devolverQz;
};

export const impresionFacturaSimplificadaQz = async function (
  data,
  factSimplificada,
  logo,
  productosFinales
) {
  let devolverQz = [];
  if (logo) {
    devolverQz.push(QZ_INIT, QZ_ALIGN_CENTER, {
      type: 'raw',
      format: 'image',
      data: 'data:image/png;base64,' + logo,
      options: { language: 'ESCPOS', dotDensity: 'double' },
    });
  }

  devolverQz.push(QZ_INIT);
  devolverQz.push(QZ_ALIGN_CENTER);

  const negocio = data.copyNegocio;
  const restaurante = data.copyRest;
  const timezone =
    restaurante &&
    restaurante.confGeneral &&
    restaurante.confGeneral.zonaHoraria
      ? restaurante.confGeneral.zonaHoraria
      : 'Europe/Madrid';

  var fechaMoment = data.fechaServidor.seconds
    ? moment.unix(data.fechaServidor.seconds).tz(timezone)
    : moment.unix(data.fechaServidorFinalEjecucion.seconds).tz(timezone);

  const formaPago = idToTexto(
    Array.isArray(factSimplificada.formaPago)
      ? factSimplificada.formaPago.join(', ')
      : factSimplificada.formaPago
  ).toUpperCase();

  devolverQz.push(QZ_ALIGN_CENTER);
  devolverQz.push(lineaQz(data.copyNegocio.nombre));
  devolverQz.push(lineaQz(data.copyRest.direccion));
  devolverQz.push(lineaQz(data.copyRest.cif + ' | ' + data.copyRest.telefono));
  devolverQz.push(QZ_BOLD);

  devolverQz.push(QZ_FUENTE_NORMAL); //miriad undereline
  devolverQz.push(QZ_STOP_INVERTIR);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_ALIGN_CENTER);
  devolverQz.push(QZ_BOLD);
  devolverQz.push(lineaQz('FACTURA SIMPLIFICADA'));
  devolverQz.push(
    lineaQz('NUMERO DE FACTURA: ' + factSimplificada.codigoFactura)
  );
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_ALIGN_LEFT);
  devolverQz.push(QZ_BOLD);
  devolverQz.push(
    lineaQz(
      'FECHA PEDIDO: ' +
        fechaMoment.format('DD/MM/YYYY') +
        ' - ' +
        fechaMoment.format('HH:mm')
    )
  );
  devolverQz.push(
    lineaQz(
      'FECHA EMISION FACTURA: ' +
        moment.unix(factSimplificada.moment).format('DD/MM/YYYY') +
        ' - ' +
        moment.unix(factSimplificada.moment).format('HH:mm')
    )
  );
  devolverQz.push(lineaQz('FORMA PAGO: ' + formaPago));

  // if (data.notaRestaurante)
  //     res = res.concat(lineaEsc(((data.notaRestaurante) ? "COMENTARIO CLIENTE: " + data.notaRestaurante : '')))

  devolverQz.push(QZ_ALIGN_LEFT);
  devolverQz.push(QZ_BOLD);
  devolverQz.push(QZ_LINEA_HORIZONTAL);
  devolverQz.push(QZ_ALIGN_LEFT);

  var impuestos = 0;

  // var productos = factSimplificada.productos.filter((p) => p.cantidad > 0);
  var productos = productosFinales.filter((p) => p.cantidad > 0);

  productos
    .sort((a, b) =>
      a.ordenCat > b.ordenCat
        ? 1
        : b.ordenCat > a.ordenCat
        ? -1
        : a.orden > b.orden
        ? 1
        : -1
    )
    .forEach((prd) => {
      let s = '';
      if (prd.porPeso) {
        s =
          s +
          (prd.cantidad + 'kg ').padEnd(5) +
          quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre).substring(
            0,
            23
          );
      } else {
        s =
          s +
          (prd.cantidad + 'x ').padEnd(5) +
          quitarAcentos(!!prd.nombreEs ? prd.nombreEs : prd.nombre).substring(
            0,
            23
          );
      }

      s = s.padEnd(28);
      let precioUnitario = !!prd.precioProductoTotalUnitario
        ? prd.precioProductoTotalUnitario
        : prd.precio;
      precioUnitario = mostrarMoneda(precioUnitario, true).padStart(7);
      //if (prd.porPeso || prd.cantidad > 1)
      //    s = s + precioUnitario

      let precioTotal = !!prd.precioProductoTotal
        ? prd.precioProductoTotal
        : prd.precioProductoTotalUnitario * prd.cantidad;
      impuestos = impuestos + redondeo(prd.impuestoCantidad * prd.cantidad);
      let precioTotalString = mostrarMoneda(precioTotal, true).padStart(7);
      s = s + precioUnitario + precioTotalString;
      devolverQz.push(lineaQz(s));

      if (prd.opciones) {
        let opcionesImpresas = [];
        prd.opciones.forEach((e, i) => {
          if (!opcionesImpresas.includes(e)) {
            opcionesImpresas.push(e);
            var ocurrencias = prd.opciones.reduce(
              (a, v) => (v === e ? a + 1 : a),
              0
            );
            let s = '';
            s =
              ocurrencias > 1
                ? '   ' +
                  ocurrencias +
                  '- ' +
                  quitarAcentos(prd.opcionesName[i])
                : '   - ' + quitarAcentos(prd.opcionesName[i]);
            s = s.padEnd(38);
            /*let precio = "" + mostrarMoneda(prd.opcionesPrecio[i] * prd.cantidad);
                            precio = precio.padStart(5);
                            s = s + precio*/
            // carritoString = carritoString + encoder.line(s)

            devolverQz.push(lineaQz(s));
          }
        });
      }
    });
  if (data.recargo && data.recargo > 0) {
    let s = '';
    // carritoString = carritoString + encoder.line(s)
    // res = res.concat(lineaEsc(s))
    s = '';
    s = s + '   Recargo domicilio ';
    s = s.padEnd(36);
    let precio = mostrarMoneda(data.recargo).padStart(5);
    s = s + precio;
    devolverQz.push(lineaQz(s));
  }

  const impuestosImprimir = factSimplificada.impuestosTotalesPago
    ? factSimplificada.impuestosTotalesPago
    : impuestos;
  let precioTotal = factSimplificada.pagado;
  // var carritoProductos = new Uint8Array(carritoString);
  var precioFinal = 'TOTAL: ' + mostrarMoneda(precioTotal);
  precioFinal = precioFinal;

  devolverQz.push(QZ_ALIGN_LEFT);
  devolverQz.push(QZ_LINEA_HORIZONTAL);
  devolverQz.push(QZ_ALIGN_RIGHT);
  devolverQz.push(QZ_FUENTE_GRANDE);
  devolverQz.push(QZ_FONT_HACHATADA);
  if (
    factSimplificada.formaPago == 'efectivo' &&
    factSimplificada.entregado !== 'undefined'
  ) {
    //sólo un pago
    devolverQz.push(
      lineaQz('ENTREGADO: ' + mostrarMoneda(factSimplificada.entregado, true))
    );
    devolverQz.push(
      lineaQz(' CAMBIO: ' + mostrarMoneda(factSimplificada.cambio, true))
    );
  }
  let baseImponibleImprimir = factSimplificada.baseImponiblePago
    ? factSimplificada.baseImponiblePago
    : parseFloat(precioTotal) - impuestosImprimir;
  devolverQz.push(QZ_BOLD_NORMAL);
  devolverQz.push(
    lineaQz('BASE IMPONIBLE:   ' + mostrarMoneda(baseImponibleImprimir))
  );
  devolverQz.push(lineaQz('IMPUESTOS:   ' + mostrarMoneda(impuestosImprimir)));
  devolverQz.push(QZ_FUENTE_GRANDE);
  devolverQz.push(lineaQz(precioFinal));
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_FUENTE_NORMAL);

  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_ALIGN_CENTER);

  if (factSimplificada.ficheroFactura) {
    // create QRCode Image
    async function getQRCode(content) {
      return new Promise((resolve, reject) => {
        QRCode.toDataURL(content, (err, url) => {
          if (err) return reject(err);

          loadImage(url).then(resolve, reject);
        });
      });
    }
    const qrcode = await getQRCode(factSimplificada.ficheroFactura);

    devolverQz.push(QZ_ALIGN_CENTER);
    devolverQz.push(lineaQz('DESCARGUE SU FACTURA'));
    devolverQz.push(QZ_INIT);
    devolverQz.push(QZ_INIT, QZ_ALIGN_CENTER, {
      type: 'raw',
      format: 'image',
      data: qrcode.src,
      options: { language: 'ESCPOS', dotDensity: 'double' },
    });
    devolverQz.push(QZ_ALIGN_CENTER);
    devolverQz.push(QZ_LINEA);
    devolverQz.push(QZ_LINEA);
    devolverQz.push(QZ_LINEA);
  }

  devolverQz.push(lineaQz('Gracias por su pedido'));
  // devolverQz.push(lineaQz('By FunEats'));

  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);

  devolverQz.push(QZ_CORTE);
  devolverQz.push(QZ_INIT);

  return devolverQz;
};

export const zcierreQz = async function (
  pedidos,
  resumenes,
  restaurante,
  fecha = null,
  logo
) {
  var logoImprimir = logo
    ? {
        type: 'raw',
        format: 'image',
        data: 'data:image/png;base64,' + logo,
        options: { language: 'ESCPOS', dotDensity: 'double' },
      }
    : false;
  var devolverQz = [QZ_INIT, QZ_ALIGN_CENTER];
  if (logoImprimir) devolverQz.push(logoImprimir);

  const timezone = restaurante.timezone
    ? restaurante.timezone
    : 'Europe/Madrid';
  let fechaDefinitiva = !fecha ? moment() : fecha;
  let fechaFormateada = !fecha
    ? 'Fecha: ' + fechaDefinitiva.tz(timezone).format('DD/MM/YYYY HH:mm')
    : 'Cierre del dia ' + fechaDefinitiva.tz(timezone).format('DD/MM/YYYY');
  devolverQz.push(QZ_INIT);
  devolverQz.push(QZ_ALIGN_CENTER);
  devolverQz.push(
    lineaQz(
      restaurante.nombreNegocio == restaurante.nombre
        ? restaurante.nombre
        : restaurante.nombreNegocio + ' - ' + restaurante.nombre
    )
  );
  devolverQz.push(lineaQz(restaurante.direccion));
  devolverQz.push(lineaQz(restaurante.cif + ' | ' + restaurante.telefono));
  devolverQz.push(QZ_BOLD);
  devolverQz.push(QZ_BOLD);
  devolverQz.push(QZ_FUENTE_GRANDE);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_INVERTIR);
  devolverQz.push(QZ_ALIGN_CENTER);
  devolverQz.push(lineaQz('Z CIERRE'));
  devolverQz.push(QZ_INIT);
  devolverQz.push(QZ_FUENTE_NORMAL);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_BOLD);
  devolverQz.push(QZ_ALIGN_LEFT);
  devolverQz.push(
    lineaQz('Unidad de venta: '.padEnd(22) + restaurante.cuenta.nombre)
  );
  devolverQz.push(
    lineaQz('Empleados: '.padEnd(22) + resumenes.empleados.join(', '))
  );
  devolverQz.push(
    lineaQz('Cuentas: '.padEnd(22) + resumenes.cuentas.join(', '))
  );
  devolverQz.push(
    lineaQz(
      'Fecha: '.padEnd(22) +
        (resumenes.moment
          ? moment.unix(resumenes.moment).format('DD-MM-YYYY')
          : '-')
    )
  );
  devolverQz.push(
    lineaQz('Impresion: '.padEnd(22) + moment().format('HH:mm DD-MM-YYYY'))
  );

  devolverQz.push(QZ_ALIGN_LEFT);
  devolverQz.push(QZ_BOLD);
  devolverQz.push(QZ_LINEA_HORIZONTAL);
  devolverQz.push(QZ_ALIGN_CENTER);
  devolverQz.push(lineaQz('TOTALES'));
  devolverQz.push(lineaQz('Concepto'.padEnd(34) + 'Total'.padStart(7)));
  devolverQz.push(QZ_LINEA_HORIZONTAL);
  devolverQz.push(
    lineaQz(
      'Numero tickets'.padEnd(34) +
        resumenes.cobrados.totales.numPedidos.toString().padStart(7)
    )
  );
  if (resumenes.serieFacturacion[0])
    devolverQz.push(
      lineaQz(
        'Serie FT (Cobro TPV) Inicio:'.padEnd(34) +
          parseInt(resumenes.serieFacturacion[0].split('-')[1])
            .toString()
            .padStart(7)
      )
    );
  if (resumenes.serieFacturacion[1])
    devolverQz.push(
      lineaQz(
        'Serie FT (Cobro TPV) Fin:'.padEnd(34) +
          parseInt(resumenes.serieFacturacion[1].split('-')[1])
            .toString()
            .padStart(7)
      )
    );
  if (resumenes.serieFacturacionOnline[0])
    devolverQz.push(
      lineaQz(
        'Serie FN (Pago online) Inicio:'.padEnd(34) +
          parseInt(resumenes.serieFacturacionOnline[0].split('-')[1])
            .toString()
            .padStart(7)
      )
    );
  if (resumenes.serieFacturacionOnline[1])
    devolverQz.push(
      lineaQz(
        'Serie FN (Pago online) Fin:'.padEnd(34) +
          parseInt(resumenes.serieFacturacionOnline[1].split('-')[1])
            .toString()
            .padStart(7)
      )
    );
  if (resumenes.serieFacturacionAbono[0])
    devolverQz.push(
      lineaQz(
        'Serie FA (Abonos) Inicio:'.padEnd(34) +
          parseInt(resumenes.serieFacturacionAbono[0].split('-')[1])
            .toString()
            .padStart(7)
      )
    );
  if (resumenes.serieFacturacionAbono[1])
    devolverQz.push(
      lineaQz(
        'Serie FA (Abonos) Fin:'.padEnd(34) +
          parseInt(resumenes.serieFacturacionAbono[1].split('-')[1])
            .toString()
            .padStart(7)
      )
    );

  devolverQz.push(
    lineaQz(
      'Importe facturado'.padEnd(34) +
        resumenes.cobrados.totales.total.toString().padStart(7)
    )
  );
  devolverQz.push(
    lineaQz(
      'Importe total'.padEnd(34) +
        resumenes.cobrados.totales.total.toString().padStart(7)
    )
  );
  devolverQz.push(
    lineaQz(
      'Ticket medio'.padEnd(34) +
        (
          parseFloat(resumenes.cobrados.totales.total) /
          parseFloat(resumenes.cobrados.totales.numPedidos)
        )
          .toFixed(2)
          .padStart(7)
    )
  );

  devolverQz.push(
    lineaQz(
      'TOTAL EFECTIVO: '.padEnd(34) +
        mostrarMoneda(resumenes.cobrados.totales.efectivo.toString()).padStart(
          7
        )
    )
  );
  devolverQz.push(
    lineaQz(
      'TOTAL TARJETA DOMICILIO: '.padEnd(34) +
        mostrarMoneda(
          resumenes.cobrados.totales.tarjeta_domicilio.toString()
        ).padStart(7)
    )
  );
  devolverQz.push(
    lineaQz(
      'TOTAL TARJETA ONLINE: '.padEnd(34) +
        mostrarMoneda(
          resumenes.cobrados.totales.tarjeta_online.toString()
        ).padStart(7)
    )
  );
  devolverQz.push(
    lineaQz(
      'TOTAL TARJETA LOCAL: '.padEnd(34) +
        mostrarMoneda(
          resumenes.cobrados.totales.tarjeta_local.toString()
        ).padStart(7)
    )
  );
  devolverQz.push(
    lineaQz(
      'TOTAL OTRO: '.padEnd(34) +
        mostrarMoneda(resumenes.cobrados.totales.otro.toString()).padStart(7)
    )
  );
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);

  devolverQz.push(
    lineaQz(
      'NUMERO'.padEnd(12) +
        'MODO'.padEnd(12) +
        'PAGO'.padEnd(12) +
        'CANTIDAD'.padEnd(12)
    )
  );
  devolverQz.push(QZ_BOLD_NORMAL);
  devolverQz.push(QZ_LINEA_HORIZONTAL);

  devolverQz.push(QZ_LINEA);

  pedidos.forEach((ped) => {
    var numeroCalc =
      ped.copyMesa && ped.modoCopy && ped.modo === 'mesa'
        ? ped.numPedidoRestDiaModoMostrar
        : ped.modoCopy
        ? ped.numPedidoGofo
          ? 'G' + ped.numPedidoGofo
          : ped.numPedidoRestDiaModoMostrar
          ? ped.numPedidoRestDiaModoMostrar
          : ped.numPedidoRestDia
          ? ped.numPedidoRestDia
          : ''
        : ped.modoCopy.nombre + s;
    var numero = numeroCalc.toString().padEnd(10);

    if (Array.isArray(ped.cobradoDesglose)) {
      var multiplePago = ped.cobradoDesglose.length > 1;
      ped.cobradoDesglose.forEach((p) => {
        let modoString = Array.isArray(ped.modo)
          ? ped.modo.join(', ')
          : ped.modo;
        let envio = ped.modoCopy.nombre.padEnd(12);
        if (Array.isArray(p.variasFormasPago)) {
          //MISMA FACTURA SIMPLIFICADA CON VARIOS PAGOS
          p.variasFormasPago.forEach((forma) => {
            let formaPagoString = forma.formaPago
              .replace('_', '')
              .toUpperCase();
            let formaPago = (
              (multiplePago ? '* +' : '  + ') + idToTexto(formaPagoString)
            ).padEnd(18);
            let cantidad = mostrarMoneda(forma.pagar).padStart(7);
            let s = numero + envio + formaPago + cantidad;
            devolverQz.push(lineaQz(s));
          });
        } else {
          let formaPagoString = p.formaPago.replace('_', '').toUpperCase();
          let formaPago = (
            (multiplePago ? '* ' : '') + idToTexto(formaPagoString)
          ).padEnd(18);
          let cantidad = mostrarMoneda(p.pagado).padStart(7);
          let s = numero + envio + formaPago + cantidad;
          devolverQz.push(lineaQz(s));
        }
      });
    } else {
      // let numero = ped.numPedidoRestDia.toString().padEnd(10);
      let envio = idToTexto(ped.modo).padEnd(12);
      let formaPagoString = Array.isArray(ped.formaPago)
        ? ped.formaPago.join(', ')
        : ped.formaPago;
      let formaPago = (
        (multiplePago ? '* ' : '') + idToTexto(formaPagoString)
      ).padEnd(18);

      let cantidad = mostrarMoneda(ped.precioTotal).padStart(7);
      let s = numero + envio + formaPago + cantidad;

      // res = res.concat(lineaEsc(s));
      devolverQz.push(lineaQz(s));
    }
  });
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_LINEA);
  devolverQz.push(QZ_CORTE);
  return devolverQz;
};

export const imprimirTicketCocinaQz = function (
  data,
  impObj,
  filtrarImpresos = false
) {
  //filtrar impresos true devuelve imprime solo los productos no impresos. Devuelve {datos, productos}
  const categoriasExcluir = impObj.categoriasSelects
    ? impObj.categoriasSelects
    : [];
  const productosSi = impObj.productosSelectsSi
    ? impObj.productosSelectsSi
    : [];
  const productosNo = impObj.productosSelectsNo
    ? impObj.productosSelectsNo
    : [];
  const reglasEscritura = impObj.reglasEscritura;
  const sizeCocina = impObj.sizeCocina ? impObj.sizeCocina : 'big';
  var res = []; //datos;
  var productosImprime = [];
  const negocio = data.copyNegocio;
  const restaurante = data.copyRest;
  const timezone = restaurante.confGeneral.zonaHoraria
    ? restaurante.confGeneral.zonaHoraria
    : 'Europe/Madrid';
  var fechaMoment = data.fechaServidor.toDate()
    ? moment(data.fechaServidor.toDate()).tz(timezone)
    : moment(data.fechaServidorFinalEjecucion.toDate()).tz(timezone);
  res = res
    .concat(ESC_INIT)
    .concat(ALIGN_CENTER)
    .concat(
      lineaEsc(
        fechaMoment.format('DD/MM/YYYY') +
          ' ' +
          fechaMoment.format('HH:mm') +
          ' | ' +
          (data.modoCopy ? data.modoCopy.nombre : data.modo).toUpperCase() +
          ' | COCINA'
      )
    )
    .concat(FUENTE_NORMAL)
    //underline
    .concat(ALIGN_CENTER)
    // .concat(lineaEsc('TICKET DIA: 00' + data.numPedidoRestDia))
    .concat(
      lineaEsc(
        'TICKET: 00' +
          data.numPedidoRest +
          '  ' +
          'DIA: 00' +
          data.numPedidoRestDia
      )
    )
    // .concat(lineaEsc('TICKET REST DIA: 00' + data.numPedidoRestDia))
    .concat(BOLD)
    .concat(FUENTE_GRANDE)
    .concat(LINEA)
    .concat(INVERTIR);

  if (data.multiple && data.multipleHijos && data.multipleHijos.length) {
    cabecera = cabecera.line('PEDIDO MULTIPLE').raw([0x1d, 0x21, 0x00]);
    data.multipleHijos.forEach((h) => {
      cabecera = cabecera.line(h);
    });
    cabecera = cabecera.newline();
  } else if (data.multiplePadre) {
    cabecera = cabecera.line(
      'MULTI-00' +
        data.numPedidoRestDiaDirectorio +
        ' - ' +
        data.modo.toUpperCase()
    );
  } else {
    let cabeceraMostrar = '';
    if (data.copyMesa && data.modoCopy) {
      cabeceraMostrar =
        'MESA ' + data.copyMesa.nombre + ' ' + data.modoCopy.nombre;
    } else if (data.modoCopy) {
      let num = data.numPedidoRestDiaModoMostrar
        ? ' ' + data.numPedidoRestDiaModoMostrar
        : data.numPedidoRestDia
        ? ' ' + data.numPedidoRestDia
        : '';
      cabeceraMostrar = data.modoCopy.nombre + num;
    }
    if (!cabeceraMostrar) {
      switch (data.modo) {
        case 'domicilio':
          res = 'DOMICILIO';
          break;
        case 'recoger':
          res = 'RECOGER';
          break;
        case 'mesa':
          res = 'MESA ' + data.copyMesa.nombre;
          break;
        case 'mostrador':
          res = 'MOSTRADOR ';
          break;
      }
      cabeceraMostrar = res;
    }

    // let cabeceraMostrar = (data.modo == 'mesa')
    //     ? data.copyMesa.nombre
    //     : (data.modoCopy ? data.modoCopy.nombre : data.modo).toUpperCase()
    res = res.concat(lineaEsc(' ' + cabeceraMostrar + ' '));
  }

  res = res.concat(LINEA).concat(STOP_INVERTIR);

  if (!!data.comentarioPedidoTpv && !!data.comentarioPedidoTpv.length > 0) {
    res = res
      .concat(FUENTE_NORMAL)
      .concat(LINEA_HORIZONTAL)
      .concat(ALIGN_LEFT)
      .concat(FONT_HACHATADA)
      .concat(lineaEsc(data.comentarioPedidoTpv))
      .concat(FUENTE_NORMAL)
      .concat(LINEA_HORIZONTAL);
  }
  if (!!data.notaRestaurante) {
    res = res
      .concat(ALIGN_LEFT)
      .concat(FUENTE_NORMAL)
      .concat(LINEA_HORIZONTAL)
      .concat(FONT_HACHATADA)
      .concat(lineaEsc('COMENTARIO CLIENTE'))
      .concat(lineaEsc(data.notaRestaurante))
      .concat(FUENTE_NORMAL)
      .concat(LINEA_HORIZONTAL);
  }
  res = res
    .concat(BOLD)
    .concat(ALIGN_LEFT)
    .concat(LINEA)
    .concat(sizeCocina === 'big' ? FUENTE_GRANDE : FUENTE_MEDIANA);

  var impuestos = 0;
  var productos = data.productos;
  var imprime = false;

  productos
    .sort((a, b) =>
      a.ordenCat > b.ordenCat
        ? 1
        : b.ordenCat > a.ordenCat
        ? -1
        : a.orden > b.orden
        ? 1
        : -1
    )
    .forEach((prd, linea) => {
      var cantidadImprimir = prd.cantidad;
      var cantidadCarrito = !!data.eliminado ? 0 : prd.cantidad;
      let impresionCocina = !!data.impresionCocina ? data.impresionCocina : [];

      if (
        (!categoriasExcluir.includes(prd.categoria) &&
          !productosNo.includes(prd.id)) ||
        productosSi.includes(prd.id)
      ) {
        let imprimeConcreto = false; //va a imprimir este producto
        let idAux = [
          prd.id,
          ...(!!Array.isArray(prd.opciones) ? prd.opciones : []),
        ].join('_');

        let foundImpresion = impresionCocina.find((imP) => imP.id == idAux); //se ha imprimido algún ticket de cocina
        let anular = false;
        if (filtrarImpresos) {
          if (!!foundImpresion) {
            if (foundImpresion.cantidad < prd.cantidad && prd.cantidad != 0) {
              imprime = true;
              imprimeConcreto = true;
              cantidadImprimir = cantidadCarrito - foundImpresion.cantidad;
            } else if (foundImpresion.cantidad > cantidadCarrito) {
              imprime = true;
              imprimeConcreto = true;
              cantidadImprimir = foundImpresion.cantidad - cantidadCarrito;
              anular = true;
            }
          } else if (cantidadCarrito != 0) {
            imprime = true;
            imprimeConcreto = true;
          }
          if (imprimeConcreto) {
            productosImprime.push({
              id: idAux,
              cantidad: anular ? -cantidadImprimir : cantidadImprimir,
            });
          }
        } else if (cantidadCarrito != 0) {
          productosImprime.push({
            id: idAux,
            cantidad: prd.cantidad,
          });
          imprime = true;
          imprimeConcreto = true;
        }
        if (imprimeConcreto) {
          let nombreImprimir = prd.nombre;

          if (reglasEscritura && Array.isArray(reglasEscritura)) {
            var found = reglasEscritura.find((r) => r.antes == nombreImprimir);
            if (found) {
              nombreImprimir = found.despues;
            }
          }
          nombreImprimir = prd.nombreTpv ? prd.nombreTpv : nombreImprimir;
          if (anular) {
            nombreImprimir = '[ANUL] ' + nombreImprimir;
          }
          nombreImprimir = nombreImprimir.substring(0, 18);
          let s = '';
          if (prd.porPeso) {
            s = s + prd.cantidad + 'kg ' + quitarAcentos(nombreImprimir);
          } else {
            s = s + cantidadImprimir + 'x ' + quitarAcentos(nombreImprimir);
          }

          res = res.concat(lineaEsc(s));
          if (prd.opciones) {
            prd.opciones.forEach((e, i) => {
              var nombreOp = prd.opcionesName[i];
              if (reglasEscritura && Array.isArray(reglasEscritura)) {
                var foundOp = reglasEscritura.find((r) => r.antes == nombreOp);
                if (foundOp) {
                  nombreOp = foundOp.despues;
                }
              }
              nombreOp =
                prd.opcionesTpv && prd.opcionesTpv[i]
                  ? prd.opcionesTpv[i]
                  : nombreOp;
              nombreOp = nombreOp.substring(0, 20);
              if (nombreOp && nombreOp.length > 0) {
                let s = '';
                s = '   -' + quitarAcentos(nombreOp);

                res = res.concat(lineaEsc(s));
              }
            });
          }
          if (prd.comentarioCocina && prd.comentarioCocina.length > 0) {
            s = 'COMENTARIO: ' + quitarAcentos(prd.comentarioCocina);
            res = res.concat(lineaEsc(s));
          }
        }
      }
    });
  if (!imprime) return false;
  res = res
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA)
    .concat(LINEA);

  res = res.concat(CORTE);
  let datos = new TextDecoder()
    .decode(new Uint8Array(res))
    .split('\n')
    .map((r) => {
      return r + '\n';
    });

  return { productos: productosImprime, datos: datos };
};

export const abrirCajaQz = function () {
  return [QZ_INIT, QZ_ABRIR_CAJON];
};

function productosRecorrer(pedidoRecorrer) {}
