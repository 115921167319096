// https://vuex.vuejs.org/en/getters.html
import moment from 'moment-timezone';
import { redondeo, mostrarMoneda } from '@/utils/helper.utils.js';

export default {
  isLoaded(state, getters, rootState) {
    return (
      (state.loaded && !rootState.auth.isLogged) ||
      (state.loaded &&
        state.restauranteObj !== null &&
        state.negocioObj !== null)
    );
  },
  esDesarrollo(state) {
    return state.modoDesarrollo == 'desarrollo';
  },
  mesas_OLD(state) {
    return state.datosTpv.planos &&
      state.datosTpv.planos.plano1 &&
      Array.isArray(state.datosTpv.planos.plano1)
      ? state.datosTpv.planos.plano1
          .filter((m) => m.propiedades.pedir && m.propiedades.activo)
          .map((m) => {
            return {
              plano: 'plano1',
              id: m.id,
              comensales: m.propiedades.comensales
                ? m.propiedades.comensales
                : 0,
              nombre: m.propiedades.nombre,
              pedido: m.pedido ? m.pedido : false,
              catalogo: m.propiedades.catalogo ? m.propiedades.catalogo : 0,
            };
          })
      : [];
  },
  mesasFiltradas(state, getters, rootState) {
    //muestra las mesas que tienen peedidos vigentes
    return state.datosTpv.mesas.map((m) => {
      var f = rootState.pedidos.pedidosRealizandoTpv.find(
        (p) => p.id == m.pedido
      );
      return {
        ...m,
        pedido: !!f ? m.pedido : false,
      };
    });
  },
  hora(state) {
    let tz =
      state.restauranteObj &&
      state.restauranteObj.confGeneral &&
      state.restauranteObj.confGeneral.zonaHoraria
        ? state.restauranteObj.confGeneral.zonaHoraria
        : 'Europe/Madrid';
    return moment(state.horaActual).tz(tz).format('HH:mm');
  },
  impresoras(state, getters, rootState) {
    return state.datosTpv && state.datosTpv.dispositivosTpv
      ? state.datosTpv.dispositivosTpv.filter(
          (d) =>
            (d.tipo == 'impresora' && d.cuenta == rootState.auth.user.email) ||
            d.tipo == 'impresora-otro-rest' ||
            d.tipo == 'impresora-printnode' ||
            d.tipo == 'impresora-qz'
        )
      : [];
  },
  balanzas(state, getters, rootState) {
    return state.datosTpv && state.datosTpv.dispositivosTpv
      ? state.datosTpv.dispositivosTpv.filter(
          (d) => d.tipo == 'balanza' && d.cuenta == rootState.auth.user.email
        )
      : [];
  },
  lectores(state, getters, rootState) {
    return state.datosTpv && state.datosTpv.dispositivosTpv
      ? state.datosTpv.dispositivosTpv.filter(
          (d) =>
            d.tipo == 'lector-codigo' && d.cuenta == rootState.auth.user.email
        )
      : [];
  },
  tickets(state, getters) {
    return [
      ...new Set(
        getters.impresoras
          .map((i) => {
            return Array.isArray(i.impresiones)
              ? i.impresiones.map((imp) => {
                  return {
                    ...i,
                    ...imp,
                  };
                })
              : [];
          })
          .flat()
      ),
    ];
  },
  ticketsCaja(state) {
    return state.datosTpv.tickets.filter((t) => t.tipo == 'caja');
  },
  ticketsFactsimplificada(state) {
    return state.datosTpv.tickets.filter((t) => t.tipo == 'factsimplificada');
  },
  ticketsCocina(state) {
    return state.datosTpv.tickets.filter((t) => t.tipo == 'cocina');
  },
  ordenadores(state, getters, rootState) {
    return state.datosTpv && state.datosTpv.dispositivosTpv
      ? state.datosTpv.dispositivosTpv.filter(
          (d) => d.tipo == 'ordenador' && d.cuenta == rootState.auth.user.email
        )
      : [];
  },
  cuenta(state, getters, rootState) {
    let f =
      state.datosTpv && state.datosTpv.cuentas
        ? state.datosTpv.cuentas.find((c) => c.id == rootState.auth.user.uid)
        : false;
    return !!f ? { email: rootState.auth.user.email, ...f } : false;
  },

  impresionLocal(state, getters) {
    return getters['cuenta'] ? getters['cuenta'].impresionLocal : false;
  },
  impuestosSelect(state) {
    return state.impuestos.map((i) => {
      return {
        text:
          i.nombre +
          ' ' +
          redondeo(parseFloat(i.impuesto * 100)).toString() +
          '%',
        value: i.id,
        cantidadImpuesto: i.impuesto,
      };
    });
  },
  paginaActualPaginacion(state, getters) {
    let res = {
      actual: 0,
      maximo: 0,
      mostrando: 0,
    };
    switch (state.paginacionManejando) {
      case 'pedidos':
        res.actual = state.paginaPaginacionPedidos;
        res.mostrando = state.mostrandoPaginacionPedidos;
        res.maximo = state.maximoPaginacionPedidos;
        break;
      case 'categorias':
        res.actual = state.paginaPaginacionCategorias;
        res.mostrando = state.mostrandoPaginacionCategorias;
        res.maximo = state.maximoPaginacionCategorias;
        break;
      case 'productos':
        res.actual = state.paginaPaginacionProductos;
        res.mostrando = state.mostrandoPaginacionProductos;
        res.maximo = state.maximoPaginacionProductos;
        break;
      case 'productosListado':
        res.actual = state.paginaPaginacionProductosListado;
        res.mostrando = state.mostrandoPaginacionProductosListado;
        res.maximo = state.maximoPaginacionProductosListado;
        break;
    }

    return res;
  },
  usarCashGuard(state) {
    return state.configuracionTpv.cashguard;
    // return true;
    return (
      state.configuracionTpv.cashguard &&
      window.navigator &&
      window.navigator.platform == 'Win32'
    );
  },
  /*zCierreResumenMostrarMoneda(state){
    return {...state.zcierreResumen,
    totales: state.zcierreResumen.totales}
  }*/
  restauranteSinNegocio(state) {
    return state.restauranteObj.id.replace(state.negocioObj.id + '-', '');
  },
  zCierreDialogGetters(state) {
    return (
      state.zcierreDialog ||
      (state.configuracionTpv &&
        state.configuracionTpv.zcierreObligatorio &&
        !state.zcierre)
    );
  },
  botonGrande(state) {
    return state.sizeBoton == 'grande';
  },
  cocinaControl(state) {
    let r = state.datosTpv.cuentas
      .filter(
        (c) =>
          c.role == 'tpv-cocina' && Array.isArray(c.cocinaControlCategorias)
      )
      .map((c) => {
        return c.cocinaControlCategorias;
      })
      .flat();
    return [...new Set(r)];
  },
  ALMACENES(state, getters, rootState, rootGetters) {
    return [
      ...rootState.restaurantes.restaurantesNegocio
        .filter((r) => r.almacen)
        .map((r) => ({ ...r, esRestaurante: true })),
      ...state.almacenes.map((r) => {
        return {
          ...r,
        };
      }),
    ];
  },
};
