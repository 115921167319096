import firebase from 'firebase';
import { db, storage } from '@/firebase';
export default {
  getRestaurantes({ state, rootState, commit }) {
    return db
      .collection('restaurantes')
      .where('negocio', '==', rootState.auth.user.negocioTpv)
      .get()
      .then((r) => {
        commit(
          'SET_RESTAURANTES_NEGOCIO',
          r.docs.map((rest) => ({
            id: rest.id,
            ...rest.data(),
          }))
        );
      });
  },
  guardarUsuario({ rootState }, cuenta) {
    const id = cuenta.id;

    return db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('tpv')
      .doc('secreto')
      .collection('usuarios')
      .doc(id)
      .set(cuenta, { merge: true });
  },
};
