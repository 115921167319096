import moment from 'moment-timezone';
import { mostrarMoneda, redondeo } from '@/utils/helper.utils.js';
import { quitarAcentos, idToTexto, lineaQz } from './utils';
import { comandosF, valoresTicketDinamico } from './comandos';
export default function (obj) {
  const { pedidos, resumen, restaurante, fecha = null, logo, ticket } = obj;

  const ticketDinamico = valoresTicketDinamico(ticket);
  const comandos = comandosF(ticket);

  var logoImprimir = logo
    ? {
        type: 'raw',
        format: 'image',
        data: 'data:image/png;base64,' + logo,
        options: { language: 'ESCPOS', dotDensity: 'double' },
      }
    : false;
  var devolverQz = [comandos.QZ_INIT, comandos.QZ_ALIGN_CENTER];
  if (logoImprimir) devolverQz.push(logoImprimir);

  const timezone = restaurante.timezone
    ? restaurante.timezone
    : 'Europe/Madrid';
  let fechaDefinitiva = !fecha ? moment() : fecha;
  let fechaFormateada = !fecha
    ? 'Fecha: ' + fechaDefinitiva.tz(timezone).format('DD/MM/YYYY HH:mm')
    : 'Cierre del dia ' + fechaDefinitiva.tz(timezone).format('DD/MM/YYYY');
  devolverQz.push(comandos.QZ_INIT);
  devolverQz.push(comandos.QZ_ALIGN_CENTER);
  devolverQz.push(
    lineaQz(
      restaurante.nombreNegocio == restaurante.nombre
        ? restaurante.nombre
        : restaurante.nombreNegocio + ' - ' + restaurante.nombre
    )
  );
  devolverQz.push(lineaQz(restaurante.direccion));
  devolverQz.push(lineaQz(restaurante.cif + ' | ' + restaurante.telefono));
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(comandos.QZ_FUENTE_GRANDE);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_INVERTIR);
  devolverQz.push(comandos.QZ_ALIGN_CENTER);
  devolverQz.push(
    lineaQz('Z CIERRE ' + (resumen.numeroCierre ? resumen.numeroCierre : ''))
  );
  devolverQz.push(comandos.QZ_INIT);
  devolverQz.push(comandos.QZ_FUENTE_NORMAL);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(comandos.QZ_ALIGN_LEFT);
  devolverQz.push(lineaQz('Unidad de venta: ' + restaurante.cuenta.nombre));
  devolverQz.push(lineaQz('Empleados: ' + resumen.empleados.join(', ')));
  devolverQz.push(lineaQz('Cuentas: ' + resumen.cuentas.join(', ')));
  devolverQz.push(
    lineaQz(
      'Fecha: ' +
        (resumen.moment
          ? moment.unix(resumen.moment).format('DD-MM-YYYY')
          : '-')
    )
  );
  devolverQz.push(
    lineaQz('Impresion: ' + moment().format('HH:mm - DD-MM-YYYY'))
  );

  devolverQz.push(comandos.QZ_ALIGN_LEFT);
  devolverQz.push(comandos.QZ_BOLD);
  devolverQz.push(comandos.QZ_LINEA_HORIZONTAL);
  devolverQz.push(comandos.QZ_ALIGN_CENTER);
  devolverQz.push(comandos.QZ_FUENTE_MEDIANA);
  devolverQz.push(lineaQz('TOTALES'));
  devolverQz.push(comandos.QZ_FUENTE_NORMAL);
  devolverQz.push(comandos.QZ_ALIGN_LEFT);
  devolverQz.push(
    lineaQz('Concepto'.padEnd(ticketDinamico.zCierreConcepto) + 'Total')
  ); // Para 80mm - 43  y para 58mm - 27
  devolverQz.push(comandos.QZ_LINEA_HORIZONTAL);
  devolverQz.push(
    lineaQz(
      'Numero tickets:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
        resumen.cobrados.totales.numPedidos.toString().padStart(7)
    )
  );
  if (resumen.serieFacturacion[0])
    devolverQz.push(
      lineaQz(
        'FT (Cobro TPV) Inicio:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
          parseInt(resumen.serieFacturacion[0].split('-')[1])
            .toString()
            .padStart(7)
      )
    );
  if (resumen.serieFacturacion[1])
    devolverQz.push(
      lineaQz(
        'FT (Cobro TPV) Fin:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
          parseInt(resumen.serieFacturacion[1].split('-')[1])
            .toString()
            .padStart(7)
      )
    );
  if (resumen.serieFacturacionOnline[0])
    devolverQz.push(
      lineaQz(
        'FN (Pago online) Inicio:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
          parseInt(resumen.serieFacturacionOnline[0].split('-')[1])
            .toString()
            .padStart(7)
      )
    );
  if (resumen.serieFacturacionOnline[1])
    devolverQz.push(
      lineaQz(
        'FN (Pago online) Fin:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
          parseInt(resumen.serieFacturacionOnline[1].split('-')[1])
            .toString()
            .padStart(7)
      )
    );
  if (resumen.serieFacturacionAbono[0])
    devolverQz.push(
      lineaQz(
        'FA (Abonos) Inicio:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
          parseInt(resumen.serieFacturacionAbono[0].split('-')[1])
            .toString()
            .padStart(7)
      )
    );
  if (resumen.serieFacturacionAbono[1])
    devolverQz.push(
      lineaQz(
        'FA (Abonos) Fin:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
          parseInt(resumen.serieFacturacionAbono[1].split('-')[1])
            .toString()
            .padStart(7)
      )
    );

  devolverQz.push(
    lineaQz(
      'Importe facturado:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
        mostrarMoneda(resumen.cobrados.totales.total.toString()).padStart(7)
    )
  );
  const imprimirResumenDatos = (resumen) => {
    devolverQz.push(
      lineaQz(
        'Importe total:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
          mostrarMoneda(resumen.cobrados.totales.total.toString()).padStart(7)
      )
    );
    devolverQz.push(
      lineaQz(
        'Ticket medio:'.padEnd(ticketDinamico.zCierreConcepto - 2) +
          // Para 80mm - 41  y para 58mm - 25
          (resumen.cobrados.totales.numPedidos > 0
            ? parseFloat(resumen.cobrados.totales.total) /
              parseFloat(resumen.cobrados.totales.numPedidos)
            : 0
          )
            .toFixed(2)
            .padStart(7)
      )
    );

    devolverQz.push(
      lineaQz(
        'Total efectivo: '.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
          mostrarMoneda(resumen.cobrados.totales.efectivo.toString()).padStart(
            7
          )
      )
    );
    devolverQz.push(
      lineaQz(
        'Total tarjeta domicilio: '.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
          mostrarMoneda(
            resumen.cobrados.totales.tarjeta_domicilio.toString()
          ).padStart(7)
      )
    );
    devolverQz.push(
      lineaQz(
        'Total tarjeta On Line: '.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
          mostrarMoneda(
            resumen.cobrados.totales.tarjeta_online.toString()
          ).padStart(7)
      )
    );
    devolverQz.push(
      lineaQz(
        'Total tarjeta local: '.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
          mostrarMoneda(
            resumen.cobrados.totales.tarjeta_local.toString()
          ).padStart(7)
      )
    );
    devolverQz.push(
      lineaQz(
        'Total otro:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
          mostrarMoneda(resumen.cobrados.totales.otro.toString()).padStart(7)
      )
    );
  };

  imprimirResumenDatos(resumen);

  if (Array.isArray(resumen.cierresX) && resumen.cierresX.length > 1) {
    resumen.cierresX.forEach((cierre, i) => {
      devolverQz.push(comandos.QZ_LINEA_HORIZONTAL);
      devolverQz.push(comandos.QZ_ALIGN_CENTER);
      devolverQz.push(comandos.QZ_FUENTE_MEDIANA);
      devolverQz.push(lineaQz('TURNO ' + (i + 1)));
      devolverQz.push(comandos.QZ_FUENTE_NORMAL);
      devolverQz.push(comandos.QZ_ALIGN_LEFT);
      devolverQz.push(
        lineaQz(
          'Inicio:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
            moment.unix(cierre.inicio).format('HH:mm').padStart(7)
        )
      );
      devolverQz.push(
        lineaQz(
          'Fin:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
            (cierre.fin
              ? moment.unix(cierre.fin).format('HH:mm').padStart(7)
              : '-')
        )
      );
      devolverQz.push(
        lineaQz(
          'Numero tickets:'.padEnd(ticketDinamico.zCierreConcepto - 2) + // Para 80mm - 41  y para 58mm - 25
            cierre.cobrados.totales.numPedidos.toString().padStart(7)
        )
      );
      imprimirResumenDatos(cierre);
    });
  }

  if (ticket.cierreMostrarPedidos) {
    devolverQz.push(comandos.QZ_LINEA);

    devolverQz.push(
      lineaQz(
        '#'.padEnd(ticketDinamico.zCierreListadoTicket5) + // Para 80mm - 10  y para 58mm - 5
          'MODO'.padEnd(ticketDinamico.zCierreListadoTicket4) + // Para 80mm - 12  y para 58mm - 8
          'PAGO'.padEnd(ticketDinamico.zCierreListadoTicket7) + // Para 80mm - 18  y para 58mm - 11
          'CANTIDAD'
      )
    );
    devolverQz.push(comandos.QZ_BOLD_NORMAL);
    devolverQz.push(comandos.QZ_LINEA_HORIZONTAL);

    pedidos.forEach((ped) => {
      var numeroCalc =
        ped.copyMesa && ped.modoCopy && ped.modo === 'mesa'
          ? ped.numPedidoRestDiaModoMostrar
          : ped.modoCopy
          ? ped.numPedidoGofo
            ? 'G' + ped.numPedidoGofo
            : ped.numPedidoRestDiaModoMostrar
            ? ped.numPedidoRestDiaModoMostrar
            : ped.numPedidoRestDia
            ? ped.numPedidoRestDia
            : ''
          : ped.modoCopy.nombre + s;
      var numero = numeroCalc
        .toString()
        .padEnd(ticketDinamico.zCierreListadoTicket5); // Para 80mm - 10  y para 58mm - 5

      if (Array.isArray(ped.cobradoDesglose)) {
        var multiplePago = ped.cobradoDesglose.length > 1;
        ped.cobradoDesglose.forEach((p) => {
          let modoString = Array.isArray(ped.modo)
            ? ped.modo.join(', ')
            : ped.modo;
          let envio = ped.modoCopy.nombre
            .substring(0, ticketDinamico.zCierreListadoTicket4 - 1)
            .padEnd(ticketDinamico.zCierreListadoTicket4); // Para 80mm - 12  y para 58mm - 8
          if (Array.isArray(p.variasFormasPago)) {
            //MISMA FACTURA SIMPLIFICADA CON VARIOS PAGOS
            p.variasFormasPago.forEach((forma) => {
              let formaPagoString = forma.formaPago
                .replace('_', '')
                .toUpperCase();
              let formaPago = (
                (multiplePago ? '+ ' : '+') + idToTexto(formaPagoString)
              )
                .substring(0, ticketDinamico.zCierreListadoTicket4 + 4)
                .padEnd(ticketDinamico.zCierreMultiPagoTarjetaLocal); // Para 80mm - 18  y para 58mm - 11
              let cantidad = mostrarMoneda(forma.pagar).padStart(7);
              let s = numero + envio + formaPago + cantidad;
              devolverQz.push(lineaQz(s));
            });
          } else {
            let formaPagoString = p.formaPago.replace('_', '').toUpperCase();
            let formaPago = (
              (multiplePago ? '* ' : '') + idToTexto(formaPagoString)
            )
              .substring(0, ticketDinamico.zCierreListadoTicket4 + 4)
              .padEnd(ticketDinamico.zCierreMultiPagoTarjetaLocal); // Para 80mm - 19  y para 58mm - 12
            let cantidad = mostrarMoneda(p.pagado).padStart(7);
            let s = numero + envio + formaPago + cantidad;
            devolverQz.push(lineaQz(s));
          }
        });
      } else {
        // let numero = ped.numPedidoRestDia.toString().padEnd(10);
        let envio = idToTexto(ped.modo);
        let formaPagoString = Array.isArray(ped.formaPago)
          ? ped.formaPago.join(', ')
          : ped.formaPago;
        let formaPago = (multiplePago ? '* ' : '') + idToTexto(formaPagoString);

        let cantidad = mostrarMoneda(ped.precioTotal).padStart(7);
        let s = numero + envio + formaPago + cantidad;

        // res = res.concat(lineaEsc(s));
        devolverQz.push(lineaQz(s));
      }
    });
  }
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_LINEA);
  devolverQz.push(comandos.QZ_CORTE);

  return devolverQz;
}
