export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const chunkArray = (myArray, chunk_size) => {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];
  var myChunk;
  for (index = 0; index < arrayLength; index += chunk_size) {
    myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
};

export const mostrarIdioma = (obj, key = 'name') => {
  let campo = obj[key];

  if (campo && campo.es && campo.es.length > 0) {
    return campo.es;
  } else {
    if (key == 'name') campo = obj.nombre;
    return mostrarIdiomaOld(campo);
  }
};
export const mostrarIdiomaOld = (s) => {
  //hay que gestionar idiomas
  let lang = 'es';

  if (Array.isArray(s)) {
    //v2
    let mostrar = s.find((e) => e.lang == lang);
    return mostrar.text;
  } else if (s && s.es) {
    return s.es;
  } else {
    return '';
  }
};

export const mostrarMoneda = function (cantidad, zero = false) {
  return cantidad
    ? redondeo(cantidad).toFixed(2)
    : zero
    ? Number.parseFloat(cantidad).toFixed(2)
    : '';
};

export const isFloat = function (n) {
  return Number(n) === n && n % 1 !== 0;
};

export const redondeo = (num, decimales = 2) => {
  let v = Math.pow(10, decimales);
  return Math.round((parseFloat(num) + Number.EPSILON) * v) / v;
};

export const parsePrecios = (data) => {
  // let prod =  state.activeProduct;
  let prod = data;
  var descuento = 1;
  if (prod.id != undefined) {
    if (
      prod.precio == undefined ||
      (prod.precioCobrar == 0 && prod.opciones && prod.opciones.length > 0)
    ) {
      for (let i = 0; i < prod.opciones.length; i++) {
        let precioOpciones = prod.opciones;
        if (precioOpciones[i].opciones != undefined) {
          let precioBaseArray = precioOpciones[i].opciones.map((e) => {
            return e && e.precioCobrar != undefined && e.precioCobrar > 0
              ? e.precioCobrar
              : 0;
          });

          let sinCeros = precioBaseArray.filter((e) => {
            return e > 0;
          });
          if (sinCeros.length > 0) {
            prod.precioCorregido = Math.min.apply(Math, sinCeros);
            precioOpciones[i].opciones.forEach((e) => {
              if (e && e.precio)
                e.precioCorregido = e.precioCobrar - prod.precioCorregido;
            });

            i = prod.opciones.length;
          }
        } else {
          prod.precioCorregido = parseFloat(precioOpciones[i].precio);
        }

        // Carga el descuento si esta definido y si el precio no es cero
        if (prod.descuentoPorcentaje)
          descuento = descuento - parseFloat(prod.descuentoPorcentaje);
        //if (prod.precioCorregido > 0) prod.precioCorregido = prod.precioCorregido * descuento;
      }
    }
  }
  return prod;
};

export const generateToken = (length) => {
  //edit the token allowed characters
  var a =
    'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split('');
  var b = [];
  for (var i = 0; i < length; i++) {
    var j = (Math.random() * (a.length - 1)).toFixed(0);
    b[i] = a[j];
  }
  return b.join('');
};
