export default {
  getUsuariosListado(state, getters, rootState, rootGetters) {
    let aux = rootGetters['auth/isTpv']
      ? [{ nombre: 'Sin User', id: 'sin-user' }]
      : [];
    return [
      ...aux,
      ...state.usuarios.filter((u) =>
        u.roles.includes(rootState.modoFuncionamiento)
      ),
    ];
  },
  accionesNecesarioAdmin(state, getters, rootState) {
    if (
      Array.isArray(rootState.configuracionTpv.accionesTpvAdminRestriccion) &&
      !state.usuarioActualObj.tpvAdmin
    ) {
      return rootState.configuracionTpv.accionesTpvAdminRestriccion;
    } else {
      return [];
    }
  },
  puedeEliminarPedido(state, getters) {
    return !getters.accionesNecesarioAdmin.includes('eliminar-pedido');
  },
  puedeEliminarProducto(state, getters) {
    return !getters.accionesNecesarioAdmin.includes('eliminar-producto');
  },
  puedeAbonarPedido(state, getters) {
    return !getters.accionesNecesarioAdmin.includes('abono-producto'); //cambiar a abono-pedidp
  },
};
