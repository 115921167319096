import { db, storage, functions } from '@/firebase';
import { redondeo } from '@/utils/helper.utils.js';
import { key } from 'firebase-key';
import { arrayMoveImmutable } from 'array-move';
import { firestore } from 'firebase';
import moment from 'moment';
export default {
  getProductos_DEPRECATED({ commit, rootState }) {
    //
    let negocio = rootState.auth.user.negocioTpv;
    return db
      .collection('negocios')
      .doc(negocio)
      .collection('productos')
      .onSnapshot((res) => {
        commit(
          'setProductosArray',
          res.docs.map((r) => {
            return {
              ...r.data(),
              id: r.id,
            };
          })
        );
      });
    /*.then((res) => {
      var actions = res.map(function (item) {
        if (item.imagen) {
          return item;
        } else {
          var storageRef = storage.ref('imagenes/productos/' + item.id + '.jpg');
          //var spaceRef = storageRef.child('imagenes/caracteristicas/' + element.id + '.jpg');
          return storageRef.getDownloadURL()
            .then((r) => {
              item.imagen = r;
              return item;
            })
            .catch((e) => {
              item.imagen = false;
              return item;
            })
        }
      });
      return Promise.all(actions)
        .then((r) => {
          commit('setProductosArray', r)
        })
        .catch((e) => {
          console.log(e);
        })
    })*/
  },
  getCategorias({ commit, rootState }) {
    let negocio = rootState.auth.user.negocioTpv;

    return db
      .collection('negocios')
      .doc(negocio)
      .collection('categorias')
      .get()
      .then((r) => {
        let res = [];
        let docs = r.docs;
        docs.forEach((e) => {
          let d = e.data();
          d.id = e.id;
          res.push(d);
        });
        commit('setCategorias', res);
      });
  },
  getTodosCatalogos({ state, dispatch, getters }) {
    return Promise.all(
      state.catalogos.map((c) => {
        return dispatch('getCatalogo', c.value);
      })
    )
      .then(() => {
        let tarifas = getters['TARIFAS_EFECTIVAS'];
        return Promise.all(
          tarifas.map((t) => {
            //return dispatch('getProductosTarifa', t)
          })
        );
      })
      .catch((e) => console.log(e));
  },

  getCatalogo({ commit, rootState }, catalogo) {
    let rest = rootState.auth.user.restauranteTpv;
    return new Promise((resolve, reject) => {
      var resolveOnce = (doc) => {
        resolveOnce = () => void resolve(doc);
      };
      return db
        .collection('restaurantes')
        .doc(rest)
        .collection('catalogos')
        .doc(catalogo)
        .onSnapshot((r) => {
          if (r.exists) {
            commit('setProductosCatalogo', {
              catalogo: catalogo,
              tarifa: r.data().tarifa,
              productos: r.exists ? r.data().productos : [],
            });
          }
          resolve();
        });
    });
  },
  setCatalogo({ rootState }, { catalogo, productos }) {
    let rest = rootState.auth.user.restauranteTpv;
    return db
      .collection('restaurantes')
      .doc(rest)
      .collection('catalogos')
      .doc(catalogo)
      .set(
        {
          productos: productos,
        },
        { merge: true }
      );
  },
  setTodosCatalogo({ state, dispatch }, data) {
    return Promise.all(
      data.map((c) => {
        return dispatch('setCatalogo', c);
      })
    );
  },
  //cambia la tarifa asociada al modo.
  setTarifa() {},
  saveCodigo({ rootState, dispatch }, { producto, codigo }) {
    let negocio = rootState.auth.user.negocioTpv;
    return db
      .collection('negocios')
      .doc(negocio)
      .collection('productos')
      .doc(producto.id)
      .set({ codigoLector: codigo }, { merge: true });
    //.finally(() => dispatch('iniciarCuentaGenerarCatalogo'))
  },
  iniciarCuentaGenerarCatalogo({ state, commit, dispatch }) {
    commit('setGenerarCatalogoSnackbar', true);

    if (!!state.generarCatalogoTimeout)
      clearTimeout(state.generarCatalogoTimeout);
    if (!!state.generarCatalogoTimeoutContador)
      clearTimeout(state.generarCatalogoTimeoutContador);
    dispatch('iniciarCuentaGenerarCatalogoContador');
    commit(
      'setGenerarCatalogoTimeout',
      setTimeout(() => {
        dispatch('generarCatalogo');
      }, 10000)
    );
  },
  iniciarCuentaGenerarCatalogoContador({ state, commit, dispatch }) {
    const funcContador = () => {
      commit('setGenerarCatalogoSegundos', state.generarCatalogoSegundos - 1);
      commit(
        'setGenerarCatalogoTimeoutContador',
        setTimeout(funcContador, 1000)
      );
    };
    funcContador();
  },
  generarCatalogoResetear({ state, commit }) {
    if (!!state.generarCatalogoTimeout)
      clearTimeout(state.generarCatalogoTimeout);
    if (!!state.generarCatalogoTimeoutContador)
      clearTimeout(state.generarCatalogoTimeoutContador);
    commit('setGenerarCatalogoSegundos', state.generarCatalogoSegundosInicial);
  },
  generarCatalogo({ rootState, dispatch, commit }) {
    let negocio = rootState.auth.user.negocioTpv;
    var f = functions.httpsCallable('httpNegociosGenerarCatalogos');

    return f({ negocio: negocio }).finally(() => {
      dispatch('generarCatalogoResetear');
      commit('setGenerarCatalogoSnackbar', false);
    });
  },
  //CONSTRUCCION PRODUCTOS V2.6 se usa en V3
  //V3
  generarProductosCatalogos({ rootState, dispatch, commit, state, getters }) {
    let negocio = rootState.auth.user.negocioTpv;
    var productosBruto;
    var modificadores;
    var grupoModificadores;
    var menus;
    var catalogos;
    var impuestos;
    var descuentos;
    var caracteristicas;
    var tarifas;
    var t0 = performance.now();
    return Promise.all([
      db
        .collection('caracteristicas')
        .get()
        .then((res) => {
          caracteristicas = res.docs.map((c) => {
            return {
              ...c.data(),
              id: c.id,
            };
          });
        }),
      db
        .collection('negocios')
        .doc(negocio)
        .collection('descuentos')
        .get()
        .then((res) => {
          descuentos = res.docs.map((d) => {
            return {
              ...d.data(),
              id: d.id,
            };
          });
        }),
      db
        .collection('impuestos')
        .get()
        .then((res) => {
          impuestos = res.docs.map((i) => {
            return {
              ...i.data(),
              id: i.id,
            };
          });
          commit('SET_IMPUESTOS', impuestos);
        }),

      db
        .collection('negocios')
        .doc(negocio)
        .collection('productos')
        .get()
        .then((res) => {
          productosBruto = res.docs.map((p) => {
            return {
              ...p.data(),
              id: p.id,
            };
          });
          commit(
            'SET_MODIFICADORES_OPCIONES',
            productosBruto.filter((p) => !p.individualPedible)
          );
          // let productosXabu = [];
          // let i = 0;
          // while (i < 100) {
          //   i++;
          //   productosXabu = [...productosXabu, ...productosBruto];
          // }

          commit('SET_PRODUCTOS', productosBruto);
        }),
      db
        .collection('negocios')
        .doc(negocio)
        .collection('productosStock')
        .get()
        .then((res) => {
          commit(
            'SET_PRODUCTOS_SECRETO',
            res.docs.map((p) => ({
              ...p.data(),
              id: p.id,
            }))
          );
          // dispatch('cachearProductosTarifas');
        }),
      db
        .collection('negocios')
        .doc(negocio)
        .collection('productosMenus')
        .get()
        .then((res) => {
          menus = res.docs.map((g) => {
            return {
              ...g.data(),
              id: g.id,
            };
          });
          commit('SET_MENUS', menus);
        }),
      db
        .collection('negocios')
        .doc(negocio)
        .collection('productosGruposModificadores')
        .get()
        .then((res) => {
          grupoModificadores = res.docs.map((g) => {
            return {
              ...g.data(),
              id: g.id,
            };
          });
          commit('SET_GRUPOS_MODIFICADORES', grupoModificadores);
        }),
      db
        .collection('negocios')
        .doc(negocio)
        .collection('productosModificadores')
        .get()
        .then((res) => {
          modificadores = res.docs.map((m) => {
            return {
              ...m.data(),
              id: m.id,
            };
          });
          commit('SET_MODIFICADORES', modificadores);
        }),
      db
        .collection('negocios')
        .doc(negocio)
        .collection('productosTarifas')
        .get()
        .then((res) => {
          tarifas = res.docs.map((m) => {
            return {
              ...m.data(),
              id: m.id,
            };
          });
          commit('SET_TARIFAS', tarifas);
        }),
    ])
      .then(() => {
        return dispatch('cachearProductosTarifas');
      })
      .then(() => {
        // alert('todo listo');
      });
  },
  cachearProductosTarifasProducto({ state, getters, commit }, producto) {
    //actualiza solo un producto de caché, por rendimiento.
    const auxTodoCache = JSON.parse(
      JSON.stringify(state.productosCatalogoCacheado)
    );
    state.tarifas.forEach((tar) => {
      const fTarCacheado = auxTodoCache.find((ca) => ca.tarifa == tar.id);
      const productosTarifa = fTarCacheado ? fTarCacheado.productos : [];
      const fNuevo = getters.PRODUCTOS_MONTADOS.find((p) => p.id == producto);
      const fI = productosTarifa.findIndex((p) => p.id == producto);

      if (fNuevo && fI > -1) {
        let tarifaFound = Array.isArray(fNuevo.tarifas)
          ? fNuevo.tarifas.find((t) => t.id == tar.id)
          : false;

        var impuestoAux = null;
        var impuestoCantidadAux = 0;
        if (tarifaFound) {
          let foundImp = state.impuestos.find(
            (i) => i.id == tarifaFound.impuesto
          );
          if (foundImp) {
            impuestoAux = foundImp.id;
            impuestoCantidadAux = foundImp.impuesto;
          }
        }
        let auxP = JSON.parse(JSON.stringify(fNuevo));
        delete auxP.tarifas;
        productosTarifa[fI] = {
          ...auxP,
          precio: tarifaFound ? parseFloat(tarifaFound.precio) : 0,
          impuesto: impuestoAux,
          impPorcentaje: impuestoCantidadAux,
          activo: tarifaFound ? tarifaFound.activo : false,
          opciones: auxP.opcionesMontadas
            ? auxP.opcionesMontadas.modificadores.map((m) => {
                let aux = JSON.parse(JSON.stringify(m));
                delete aux.tarifas;
                return {
                  ...aux,
                  opciones: Array.isArray(m.opciones)
                    ? m.opciones
                        .map((op) => {
                          let tarifaFoundOp = op.tarifas.find(
                            (t) => t.id == tar.id
                          );

                          return {
                            ...op,
                            precioCobrar: tarifaFoundOp
                              ? parseFloat(tarifaFoundOp.precio)
                              : 0,
                            precio: tarifaFoundOp
                              ? parseFloat(tarifaFoundOp.precio)
                              : 0,
                            impuesto: impuestoAux,
                            impPorcentaje: impuestoCantidadAux,
                            activo: tarifaFoundOp
                              ? tarifaFoundOp.activo
                              : false,
                          };
                        })
                        .filter((op) => op && op.activo && !op.ocultar)
                    : [],
                };
              })
            : false,
        };
        const fIFinal = auxTodoCache.findIndex((t) => t.tarifa == tar.id);
        if (fIFinal > -1) {
          console.log(auxTodoCache);
          auxTodoCache[fIFinal].productos = productosTarifa;
        } else {
          auxTodoCache.push({
            tarifa: tar.id,
            productos: productosTarifa,
          });
        }
        // commit('SET_PRODUCTOS_CACHEADO_TARIFA', {
        //   tarifa: tar.id,
        //   productos: productosTarifa,
        // });
      }
    });
    console.log(auxTodoCache);
    commit('SET_PRODUCTOS_CACHEADO_TARIFA_TODO', auxTodoCache);
  },
  cachearProductosTarifas({ state, getters, commit }) {
    return state.tarifas.map((tar) => {
      commit('SET_PRODUCTOS_CACHEADO_TARIFA', {
        tarifa: tar.id,
        productos: getters.PRODUCTOS_MONTADOS.map((p) => {
          let tarifaFound = Array.isArray(p.tarifas)
            ? p.tarifas.find((t) => t.id == tar.id)
            : false;

          var impuestoAux = null;
          var impuestoCantidadAux = 0;
          if (tarifaFound) {
            let foundImp = state.impuestos.find(
              (i) => i.id == tarifaFound.impuesto
            );
            if (foundImp) {
              impuestoAux = foundImp.id;
              impuestoCantidadAux = foundImp.impuesto;
            }
          }
          let auxP = JSON.parse(JSON.stringify(p));
          delete auxP.tarifas;
          return {
            ...auxP,
            precio: tarifaFound ? parseFloat(tarifaFound.precio) : 0,
            impuesto: impuestoAux,
            impPorcentaje: impuestoCantidadAux,
            activo: tarifaFound ? tarifaFound.activo : false,
            opciones: p.opcionesMontadas
              ? p.opcionesMontadas.modificadores.map((m) => {
                  let aux = JSON.parse(JSON.stringify(m));
                  delete aux.tarifas;
                  return {
                    ...aux,
                    opciones: Array.isArray(m.opciones)
                      ? m.opciones
                          .map((op) => {
                            let tarifaFoundOp = op.tarifas.find(
                              (t) => t.id == tar.id
                            );

                            return {
                              ...op,
                              precioCobrar: tarifaFoundOp
                                ? parseFloat(tarifaFoundOp.precio)
                                : 0,
                              precio: tarifaFoundOp
                                ? parseFloat(tarifaFoundOp.precio)
                                : 0,
                              impuesto: impuestoAux,
                              impPorcentaje: impuestoCantidadAux,
                              activo: tarifaFoundOp
                                ? tarifaFoundOp.activo
                                : false,
                            };
                          })
                          .filter((op) => op && op.activo && !op.ocultar)
                      : [],
                  };
                })
              : false,
          };
        }).filter((p) => p.activo),
      });
    });
  },

  guardarProducto({ rootState, state, dispatch }, prod) {
    let negocio = rootState.auth.user.negocioTpv;

    if (Array.isArray(rootState.configuracionTpv.productosGestionaTarifas)) {
      var prodInsertar = {
        id: prod.id ? prod.id : key(),
        name: { es: prod.nombre },
        categoria: prod.categoria,
        tipo: 'simple',
        individualPedible: true,
        grupoModificador: null,
        orden:
          state.productos.filter((p) => p.categoria == prod.categoria).length +
          1,
        tarifas: rootState.configuracionTpv.productosGestionaTarifas.map(
          (t) => {
            return {
              id: t,
              precio: parseFloat(prod.precio),
              impuesto: prod.impuesto,
              activo: true,
            };
          }
        ),
      };

      return db
        .collection('negocios')
        .doc(negocio)
        .collection('productos')
        .doc(prodInsertar.id)
        .set(prodInsertar, { merge: true })
        .then(() => {
          return dispatch('actualizarProductoUno', prodInsertar.id);
        });
    }
  },
  async editarProductoSistema({ rootState, dispatch }, prod) {
    let negocio = rootState.auth.user.negocioTpv;

    let prodInsertar = {
      name: { es: prod.nombre },
      id: prod.id ? prod.id : key(),
      categoria: prod.categoria,

      //hay que poner las tarifas que se mezclen con las que tiene
      tarifas: rootState.configuracionTpv.productosGestionaTarifas.map((t) => {
        return {
          id: t,
          precio: parseFloat(prod.precio),
          impuesto: prod.impuesto,
          activo: true,
        };
      }),
    };
    if (prod.codigoLector) prodInsertar.codigoLector = prod.codigoLector;
    if (prod.secreto && prod.secreto.fechaCaducidad) {
      let prodSecreto = {
        fechaCaducidad: prod.secreto.fechaCaducidad,
      };
      await db
        .collection('negocios')
        .doc(negocio)
        .collection('productosStock')
        .doc(prodInsertar.id)
        .set(prodSecreto, { merge: true });
    }

    console.log(prodInsertar);

    return db
      .collection('negocios')
      .doc(negocio)
      .collection('productos')
      .doc(prodInsertar.id)
      .set(prodInsertar, { merge: true })
      .then(() => {
        return dispatch('actualizarProductoUno', prodInsertar.id);
      });
  },
  async editarOpcionSistema({ state, rootState, dispatch }, data) {
    var negocio = rootState.auth.user.negocioTpv;

    let fMod = state.modificadores.find((m) => m.id == data.idPanel);
    let fGrupo = state.gruposModificadores.find(
      (m) => m.id == data.idGrupoModificador
    );

    var idNuevaOpcion;
    if (fMod) {
      if (!data.id) {
        //nuevo
        idNuevaOpcion = key();
        var prodInsertar = {
          name: { es: data.nombreMostrar },
          descripcionInterna: 'Opción creada en TPV ',
          description: { es: '' },
          modificador: true,
        };

        await db
          .collection('negocios')
          .doc(negocio)
          .collection('productos')
          .doc(idNuevaOpcion)
          .set(prodInsertar, { merge: true });
      } else {
        var prodInsertar = {
          name: { es: data.nombreMostrar },
        };
        await db
          .collection('negocios')
          .doc(negocio)
          .collection('productos')
          .doc(data.id)
          .set(prodInsertar, { merge: true });
      }
      var auxOpciones = JSON.parse(JSON.stringify(fMod.opciones));
      var fIOp;
      if (data.id) {
        fIOp = auxOpciones.findIndex((op) => op.id == data.id);
      } else {
        auxOpciones.push({
          id: idNuevaOpcion,
          tarifas: rootState.configuracionTpv.productosGestionaTarifas.map(
            (t) => {
              return {
                activo: true,
                id: t,
                precio: data.precio,
              };
            }
          ),
        });
        fIOp = auxOpciones.length - 1;
      }
      if (fIOp > -1) {
        auxOpciones[fIOp].tarifas = auxOpciones[fIOp].tarifas.map((tar) => {
          if (
            rootState.configuracionTpv.productosGestionaTarifas.includes(tar.id)
          ) {
            return {
              ...tar,
              activo: true,
              precio: parseFloat(data.precio),
            };
          } else {
            return tar;
          }
        });
        if (data.cambioOrden) {
          auxOpciones = arrayMoveImmutable(
            auxOpciones,
            data.ordenAntes - 1,
            data.orden - 1
          );
        }
        return db
          .collection('negocios')
          .doc(negocio)
          .collection('productosModificadores')
          .doc(data.idPanel)
          .set({ opciones: auxOpciones }, { merge: true })
          .then(() => {
            if (data.cambioOcultar) {
              let fModEnGrupoI = fGrupo.modificadores.findIndex(
                (m) => m.id == data.idPanel
              );
              let fModEnGrupo = fGrupo.modificadores[fModEnGrupoI];
              var auxOpcionesGrupo = Array.isArray(fModEnGrupo.opciones)
                ? JSON.parse(JSON.stringify(fModEnGrupo.opciones))
                : [];

              let fIOp = auxOpcionesGrupo.findIndex((op) => op.id == data.id);
              if (fIOp > -1) {
                auxOpcionesGrupo[fIOp].ocultar = data.ocultar;
              } else {
                auxOpcionesGrupo.push({
                  id: data.id,
                  ocultar: data.ocultar,
                  incompatibilidades: [],
                });
              }
              fGrupo.modificadores[fModEnGrupoI].opciones = auxOpcionesGrupo;
              return db
                .collection('negocios')
                .doc(negocio)
                .collection('productosGruposModificadores')
                .doc(data.idGrupoModificador)
                .set({ modificadores: fGrupo.modificadores }, { merge: true });
            }
          })
          .then(() => {
            return dispatch('generarProductosCatalogos');
          });
      }
    }

    return;
  },
  actualizarProductoUno({ rootState, dispatch }, producto) {
    //cambia en productos y productosStock solo el valor nuevo de bbdd
    const negocio = rootState.auth.user.negocioTpv;
    return Promise.all([
      db
        .collection('negocios')
        .doc(negocio)
        .collection('productos')
        .doc(producto)
        .get(),
      db
        .collection('negocios')
        .doc(negocio)
        .collection('productosStock')
        .doc(producto)
        .get(),
    ]).then((r) => {
      const promesas = [];

      if (r[0].exists) {
        const prod = {
          ...r[0].data(),
          id: r[0].id,
        };
        console.log(prod);
        promesas.push(dispatch('actualizarProductoUnoNormal', prod));
      }
      if (r[1].exists) {
        const prodSecreto = {
          ...r[1].data(),
          id: r[1].id,
        };
        promesas.push(dispatch('actualizarProductoUnoSecreto', prodSecreto));
      }
      return Promise.all(promesas).then(() => {
        dispatch('cachearProductosTarifasProducto', producto);
      });
    });
  },
  actualizarProductoUnoSecreto({ state, commit, dispatch }, productoObj) {
    //cambia en productosStock solo el valor pasado
    const auxSecreto = JSON.parse(JSON.stringify(state.productosSecreto));

    let fI = auxSecreto.findIndex((p) => p.id == productoObj.id);
    if (fI > -1) {
      auxSecreto[fI] = productoObj;
      commit('SET_PRODUCTOS_SECRETO', auxSecreto);
    }
  },
  actualizarProductoUnoNormal({ state, commit, dispatch }, productoObj) {
    //cambia en productos solo el valor pasado
    const aux = JSON.parse(JSON.stringify(state.productos));
    let fI = aux.findIndex((p) => p.id == productoObj.id);
    if (fI > -1) {
      aux[fI] = productoObj;
      commit('SET_PRODUCTOS', aux);
    }
  },
  cambiarStock(
    { rootState, state, commit, dispatch },
    { producto, cantidadCambiar }
  ) {
    let negocio = rootState.auth.user.negocioTpv;
    let obj = {};
    obj[rootState.auth.user.restauranteTpv] =
      firestore.FieldValue.increment(cantidadCambiar);
    const refStock = db
      .collection('negocios')
      .doc(negocio)
      .collection('productosStock')
      .doc(producto);
    return refStock.set(obj, { merge: true }).then(() => {
      return dispatch('actualizarProductoUno', producto);
    });
  },

  async moverCantidadAlmacen(
    { rootState, commit, dispatch, state, rootGetters },
    payload
  ) {
    const negocio = rootState.auth.user.negocioTpv;
    console.log(payload);

    const cantidadOldOrigen = payload[payload.origen]
      ? parseInt(payload[payload.origen])
      : 0;
    const cantidadOldDestino = payload[payload.destino]
      ? parseInt(payload[payload.destino])
      : 0;
    const cantidad = parseInt(payload.cantidad);
    const cantidadFinalOrigen = cantidadOldOrigen - cantidad;
    const cantidadFinalDestino = cantidadOldDestino + cantidad;

    await Promise.all([
      db
        .collection('negocios')
        .doc(negocio)
        .collection('productosStock')
        .doc(payload.producto)
        .collection('registro')
        .add({
          almacen: payload.origen,
          tipo: 'salida',
          cantidad: cantidad,
          modo: 'mover',
          moment: moment().format('x'), //miliseconds
          modo: 'mover', //manual, pedido, albaran,
          origen: 'tpv', //tpv, admin
          usuario: rootState.usuarios.usuarioActualObj.nombre,
          idasociado: payload.pedido ? payload.pedido : null,
        }),
      db
        .collection('negocios')
        .doc(negocio)
        .collection('productosStock')
        .doc(payload.producto)
        .collection('registro')
        .add({
          almacen: payload.destino,
          tipo: 'entrada',
          cantidad: cantidad,
          modo: 'mover',
          moment: moment().format('x'), //miliseconds
          modo: 'mover', //manual, pedido, albaran,
          origen: 'tpv', //tpv, admin
          usuario: rootState.usuarios.usuarioActualObj.nombre,
          idasociado: payload.pedido ? payload.pedido : null,
        }),
    ]);
    // return;

    let obj = {};
    obj[payload.origen] = cantidadFinalOrigen;
    obj[payload.destino] = cantidadFinalDestino;
    return db
      .collection('negocios')
      .doc(negocio)
      .collection('productosStock')
      .doc(payload.producto)
      .set(obj, { merge: true })
      .then(() => {
        dispatch('actualizarProductoUno', payload.producto);
      });
  },
  async addCantidadAlmacen(
    { rootState, commit, dispatch, state, rootGetters },
    payload
  ) {
    const negocio = rootState.auth.user.negocioTpv;
    console.log(payload);
    const cantidadFinalDestino = parseInt(payload.cantidad);

    await Promise.all([
      db
        .collection('negocios')
        .doc(negocio)
        .collection('productosStock')
        .doc(payload.producto)
        .collection('registro')
        .add({
          almacen: payload.destino,
          tipo: 'entrada',
          cantidad: payload.cantidadAdd,
          moment: moment().format('x'), //miliseconds
          modo: 'manual', //manual, pedido, albaran,
          origen: 'tpv', //tpv, admin
          usuario: rootState.usuarios.usuarioActualObj.nombre,
          idasociado: payload.pedido ? payload.pedido : null,
        }),
    ]);
    let obj = {};
    obj[payload.destino] = cantidadFinalDestino;
    return db
      .collection('negocios')
      .doc(negocio)
      .collection('productosStock')
      .doc(payload.producto)
      .set(obj, { merge: true })
      .then(() => {
        dispatch('actualizarProductoUno', payload.producto);
      });
  },
};
