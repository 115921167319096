export default {
  setPedidos(state, p) {
    state.pedidosArray = p;
  },
  setPedidosListener(state, l) {
    state.pedidosListener = l;
  },
  setPedidosRealizandoTpvListener(state, l) {
    state.pedidosRealizandoTpvListener = l;
  },
  setPedidosRealizandoTpv(state, p) {
    state.pedidosRealizandoTpv = p;
  },
  setPedidosDirectorioMatriz(state, p) {
    state.pedidosDirectorioMatriz = p;
  },
  setPedidosImprimir(state, p) {
    state.pedidosImprimir = p;
  },
  setPedidosImprimirGestionando(state, p) {
    state.pedidosImprimirGestionando = p;
  },
  addPedidoImprimirGestionando(state, p) {
    state.pedidosImprimirGestionando.push(p);
  },
  removePedidoImprimirGestionando(state, p) {
    let fI = state.pedidosImprimirGestionando((i) => i.id == p.id);
    if (fI > -1) state.pedidosImprimirGestionando.splice(fI, 1);
  },
  setPedidoEditando(state, p) {
    state.pedidoEditando = p;
  },
  setSiguienteNumRestDiaListener(state, f) {
    state.siguienteNumRestDiaListener = f;
  },
  setSiguienteNumRestListener(state, f) {
    state.siguienteNumRestListener = f;
  },
  setSiguienteNumRestDia(state, i) {
    state.siguienteNumRestDia = i;
  },
  setSiguienteNumRestDiaModo(state, i) {
    state.siguienteNumRestDiaModo = i;
  },

  setSiguienteNumRest(state, i) {
    state.siguienteNumRest = i;
  },
  setPedidoEditandoFlag(state, p) {
    state.pedidoEditandoFlag = p;
  },
  setPedidoEditandoObj(state, obj) {
    state.pedidoEditandoObj = obj;
  },
  setPedidoEditandoObjValor(state, payload) {
    if (state.pedidoEditandoObj === null) state.pedidoEditandoObj = {};

    state.pedidoEditandoObj[payload.key] = payload.value;
  },
  setCobrarDialog(state, b) {
    state.cobrarDialog = b;
  },
  setCobrarDialogFullscreen(state, b) {
    state.cobrarDialogFullscreen = b;
  },
  setModoPantalla(state, s) {
    state.modoPantalla = s;
  },
  setPedidoDialog(state, b) {
    state.pedidoDialog = b;
  },
  setAccionesDialog(state, b) {
    state.accionesDialog = b;
  },
  setFacturacionDialog(state, b) {
    state.facturacionDialog = b;
  },
  setFacturacionContableFacturar(state, f) {
    state.facturacionContableFacturar = f;
  },
  setCambiandoCliente(state, b) {
    state.cambiandoCliente = b;
  },
  setFacturandoPedido(state, b) {
    state.facturandoPedido = b;
  },
  setMoverPedidoModoDialog(state, b) {
    state.moverPedidoModoDialog = b;
  },
  setDescuentosDialog(state, b) {
    state.descuentosDialog = b;
  },
  setHistorialDialog(state, b) {
    state.historialDialog = b;
  },
  setAbonoDialog(state, b) {
    state.abonoDialog = b;
  },
  setGrabandoPedido(state, b) {
    state.grabandoPedido = b;
  },

  setEditarProductoObj(state, obj) {
    state.editarProductoObj = obj;
  },
  setEditarProductoMenuObj(state, obj) {
    state.editarProductoMenuObj = obj;
  },
  setAbriendoNoGrabar(state, v) {
    state.abriendoNoGrabar = v;
  },

  SET_ORDEN_CAT(state, b) {
    state.ordenCat = b;
  },
  SET_EDITAR_PRODUCTO_MENU_OBJ_CAMBIANDO_OPCIONES(state, obj) {
    state.editarProductoMenuObjCambiandoOpciones = obj;
  },
  SET_PEDIDOS_PENDIENTE_IMPRIMIR_LISTENER(state, l) {
    state.pedidosPendienteImprimirListener = l;
  },
  RESETSET_PEDIDOS_PENDIENTE_IMPRIMIR_LISTENER(state) {
    state.pedidosPendienteImprimirListener = null;
  },
  SET_IMPRIMIR_DESPUES_COBRAR(state, b) {
    state.imprimirDespuesCobrar = b;
  },
  SET_MOSTRAR_TODAS_OPCIONES(state, b) {
    state.mostrarTodasOpciones = b;
  },
  SET_DRAWER_COMANDERO(state, b) {
    state.drawerComandero = b;
  },
  PUSH_PEDIDO_COCINA_TOTAL(state, obj) {
    state.pedidoCocinaTotal.push(obj);
  },
  SET_PEDIDO_COCINA_TOTAL(state, a) {
    state.pedidoCocinaTotal = a;
  },
  RESET_PEDIDO_COCINA_TOTAL(state, obj) {
    state.pedidoCocinaTotal = [];
  },

  //descuentos
  SET_EDITAR_PRODUCTO_DESCUENTO(state, b) {
    state.editarProductoDescuento = b;
  },
  SET_EDITAR_PRODUCTO_DESCUENTO_CANTIDAD(state, s) {
    state.editarProductoDescuentoCantidad = s;
  },

  SET_DEVOLUCION_DIALOG(state, b) {
    state.devolucionDialog = b;
  },
  SET_DEVOLUCION_CONFIRMAR_DIALOG(state, b) {
    state.devolucionConfirmarDialog = b;
  },
  SET_DEVOLUCION_CONFIRMAR_OBJ(state, obj) {
    state.devolucionConfirmarObj = obj;
  },
  SET_BUSCAR_PEDIDO_DIALOG(state, b) {
    state.buscarPedidoDialog = b;
  },
  SET_BUSCAR_PEDIDO_OBJ(state, obj) {
    state.buscarPedidoObj = obj;
  },
};
