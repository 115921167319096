var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function({ hover }){return [_c('v-card',{staticClass:"rounded-lg ficha-generica",class:_vm.shake ? 'constant-tilt-shake' : '',style:(hover
      ? 'cursor: pointer; border-color: #02fafd !important'
      : 'border-color: #56a7a8 !important'),attrs:{"disabled":_vm.disabled,"rounded":!!_vm.esMenu,"elevation":"4","color":_vm.color
      ? _vm.color
      : !!_vm.seleccionado
        ? 'primary'
        : _vm.esMenuSelect
          ? 'orange  lighten-4'
          : _vm.esMenuGrupo
            ? 'blue lighten-5'
            : ''},on:{"click":function($event){return _vm.$emit('accion')}}},[(!!_vm.stock)?_c('v-chip',{staticClass:"custom-badge stock-label elevation-3 purple font-weight-bold white--text",class:_vm.botonGrande ? 'text-h5' : 'caption',attrs:{"color":"purple","x-small":!_vm.botonGrande,"label":""}},[_vm._v(" "+_vm._s(_vm.stock)+" ")]):_vm._e(),(!!_vm.cantidad)?_c('v-chip',{staticClass:"custom-badge producto-badge elevation-3 info font-weight-bold white--text",class:_vm.botonGrande ? 'text-h5' : 'caption',attrs:{"x-small":!_vm.botonGrande}},[_vm._v(" "+_vm._s(_vm.cantidad)+"x ")]):_vm._e(),(!!_vm.cantidadMenu)?_c('v-chip',{staticClass:"custom-badge producto-menu elevation-3 orange lighten-1 font-weight-bold white--text",class:_vm.botonGrande ? 'text-h5' : 'caption',attrs:{"x-small":!_vm.botonGrande}},[_vm._v(" "+_vm._s(_vm.cantidadMenu)+"x ")]):_vm._e(),(!!_vm.precio)?_c('v-chip',{staticClass:"custom-badge producto-price elevation-3 font-weight-bold black--text d-flex justify-center",class:!!_vm.seleccionado ? 'white' : 'primary',attrs:{"label":"","x-small":!_vm.botonGrande}},[_vm._v(" "+_vm._s(_vm.precio)+" ")]):_vm._e(),_c('v-responsive',{staticClass:"align-center",attrs:{"aspect-ratio":1.618}},[_c('v-card-text',{staticClass:"text-uppercase font-weight-black text-center px-1",class:_vm.botonGrande
          ? _vm.$vuetify.breakpoint.mdAndDown
            ? 'text-h6'
            : 'text-h5'
          : _vm.$vuetify.breakpoint.smAndDown || !!_vm.muchasOpciones
            ? 'caption'
            : '',style:(_vm.$vuetify.breakpoint.smAndDown || !!_vm.muchasOpciones
          ? 'line-height:1;'
          : 'line-height:1.2;')},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{directives:[{name:"line-clamp",rawName:"v-line-clamp",value:(_vm.$vuetify.breakpoint.lgAndDown ? 3 : 7),expression:"$vuetify.breakpoint.lgAndDown ? 3 : 7"}],staticStyle:{"word-break":"normal !important","user-select":"none"},style:(_vm.$vuetify.breakpoint.mdAndDown
              ? 'max-height: 48px;'
              : _vm.$vuetify.breakpoint.lgAndDown
                ? 'max-height: 56px;'
                : 'max-height: 120px;')},[_vm._v(" "+_vm._s(_vm.texto)+" ")])],1),(!!_vm.tieneOpciones || !!_vm.esMenu)?_c('div',{staticClass:"opciones-bg"},[(!!_vm.esMenu)?_c('v-icon',{attrs:{"color":"orange lighten-2"}},[_vm._v("mdi-silverware-variant")]):(!!_vm.tieneOpciones)?_c('v-icon',{attrs:{"color":"indigo accent-3"}},[_vm._v("mdi-google-circles")]):_vm._e()],1):_vm._e()],1)],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }