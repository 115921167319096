export const quitarAcentos = (str) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export const idToTexto = (s) => {
  return s && s.length > 0
    ? s.replace(/^./, s[0].toUpperCase()).replace(/_/g, ' ').replace(/-/g, ' ')
    : '';
};

export const lineaQz = (s) => {
  return quitarAcentos(s) + '\x0A';
};
