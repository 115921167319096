import { db, functions } from '@/firebase';
import { parDiasComparar } from '@/utils/dias.utils.js';
import {
  mostrarIdioma,
  redondeo,
  mostrarMoneda,
  generateToken,
} from '@/utils/helper.utils.js';
const { loadImage } = require('canvas');
import { key } from 'firebase-key';

import tickets from '../../../utils/tickets';
import {
  imprimirTicketTipo1,
  imprimirTicketTipo2,
  imprimirTicketTipo2Qz,
  imprimirTicketCocina,
  imprimirTicketCocinaQz,
  imprimirPararCocina,
  imprimirReanudarCocina,
  impresionFacturaSimplificada,
  impresionFacturaSimplificadaQz,
} from '../../../utils/imprimir.utils';
import { restSinNegocio } from '@/utils/restaurantes.utils.js';
import { actualizarCarrito } from '@/utils/pedido.utils.js';
import moment from 'moment';
import firebase from 'firebase';
import { rsvgVersion } from 'canvas';
import state from '../../state';

export default {
  quitarListenerPedidos({ state, commit }) {
    if (!!state.pedidosListener) state.pedidosListener();
    if (!!state.pedidosRealizandoTpvListener)
      state.pedidosRealizandoTpvListener();
    commit('setPedidos', []);
    commit('setPedidosRealizandoTpv', []);
  },
  getPedidosOtroDia({ rootState }, dias) {
    const restSin = restSinNegocio(rootState.restauranteObj);
    const negocio = rootState.restauranteObj.negocio;
    return db
      .collection('pedidos')
      .where('fechaServidor', '>=', dias[0])
      .where('fechaServidor', '<=', dias[1])
      .where('restaurante', '==', restSin)
      .where('negocio', '==', negocio)
      .where('estado', '==', 'tpv-realizando')
      .get()
      .then((r) => {
        return r.docs.map((p) => {
          return {
            id: p.id,
            ...p.data(),
          };
        });
      });
  },
  getPedidos({ state, commit, rootState, dispatch }) {
    //pedidos online
    const negocio = rootState.restauranteObj.negocio;

    // var restBuscar = rootState.restauranteObj.id;
    // if (
    //   rootState.restauranteObj.id.includes(negocio) &&
    //   negocio != 'demo-pruebas'
    // )
    //compatilibidad v2
    var restBuscar = restSinNegocio(rootState.restauranteObj);

    const dias = parDiasComparar();
    if (!!state.pedidosListener) state.pedidosListener();
    var ref = db
      .collection('pedidos')
      .where('fechaServidor', '>=', dias[0])
      .where('fechaServidor', '<=', dias[1])
      .where('restaurante', '==', restBuscar)
      .where('negocio', '==', negocio)
      .where('estado', '==', 'finalizado');

    commit(
      'setPedidosListener',
      ref.onSnapshot((snapDoc) => {
        var res = [];

        snapDoc.forEach((e) => {
          res.push({
            ...e.data(),
            id: e.id,
          });
        });

        var ajustarCierre = Promise.resolve();
        console.log('rootState.zcierre');
        console.log(rootState.zcierre);
        console.log(res);
        if (!!rootState.zcierre) {
          // lee grabamos el zcierre, y el modoId y marcamos la cocina como impresa

          ajustarCierre = Promise.all(
            res
              .filter((p) => !p.zcierre || !p.modoId)
              .map((p) => {
                var modoAjustar = {};
                var siguienteNumPedidoRestModoMostrar = null;
                var siguienteNumPedidoRestModo = null;
                var datosPedidoFinal = {};
                if (!p.modoId) {
                  let f = rootState.datosTpv.modos.find(
                    (m) => m.tipoModo == p.modo
                  );
                  if (f) {
                    modoAjustar.modoId = f.id;
                    modoAjustar.modoCopy = f;
                  }
                  //número modo
                  const siguienteNumPedidoRestModoObj =
                    state.siguienteNumRestDiaModo;
                  const siguienteNumPedidoRestModo =
                    !!siguienteNumPedidoRestModoObj &&
                    !!siguienteNumPedidoRestModoObj[modoAjustar.modoId]
                      ? siguienteNumPedidoRestModoObj[modoAjustar.modoId]
                      : null;
                  dispatch('numPedidoRest', {
                    modo: modoAjustar.modoId,
                  });
                  //buscamos serie para el modo del pedido
                  let fModo = rootState.datosTpv.modos.find(
                    (m) => m.id === modoAjustar.modoId
                  );
                  siguienteNumPedidoRestModoMostrar =
                    !!fModo && !!fModo.numPedidoRestModoSerie
                      ? fModo.numPedidoRestModoSerie +
                        siguienteNumPedidoRestModo.toString().padStart(2, '0')
                      : siguienteNumPedidoRestModo;
                }
                // var impresionCocina = [...p.productos];
                /*p.productos.forEach(p => {
              let fI = impresionCocina.findIndex(impC => impC.id == p.id);
                impresionCocina.push(p)
            })*/

                datosPedidoFinal = {
                  zcierre: rootState.zcierre,
                  xcierre: rootState.xcierre,
                  impresionCocina: [],
                  numPedidoRestDiaModo: siguienteNumPedidoRestModo,
                  numPedidoRestDiaModoMostrar:
                    siguienteNumPedidoRestModoMostrar,
                  ...modoAjustar,
                };
                return db
                  .collection('pedidos')
                  .doc(p.id)
                  .set(datosPedidoFinal, { merge: true })
                  .then(() => {
                    //no hace falta, viene desde servidor marcado
                    // if (rootGetters["impresionLocal"]) {
                    //   dispatch("impresionPedidoCajaLocal", datosPedidoFinal);
                    //   dispatch("impresionPedidoCocinaLocal", datosPedidoFinal);
                    // }
                  });
              })
          );
        }
        ajustarCierre.then(() => {
          if (!rootState.zcierre)
            commit(
              'setPedidos',
              res.filter((p) => !p.zcierre || p.zcierre != rootState.zcierre)
            ); //quitaamos los que vieenen con e lzcierre, veendrán en realizando tpv
        });
        console.log(res);
        return;
      })
    );
  },
  getPedidosRealizandoTpv({ state, rootState, commit, dispatch, rootGetters }) {
    // const restSin = restSinNegocio(rootState.restauranteObj);
    const negocio = rootState.restauranteObj.negocio;
    const dias = parDiasComparar();
    var ref = db.collection('pedidos');
    const restBuscar = restSinNegocio(rootState.restauranteObj);

    if (!!state.pedidosRealizandoTpvListener)
      state.pedidosRealizandoTpvListener();
    if (!!rootState.zcierre) {
      //compatibilidad v2
      console.log(restBuscar);
      ref = ref
        .where('zcierre', '==', rootState.zcierre)
        .where('restaurante', '==', restBuscar)
        .where('negocio', '==', negocio);
      //.where('estado', '==', 'tpv-realizando')
    } else {
      ref = ref
        .where('fechaServidor', '>=', dias[0])
        .where('fechaServidor', '<=', dias[1])
        .where('restaurante', '==', restBuscar)
        .where('negocio', '==', negocio)
        .where('estado', '==', 'tpv-realizando');
    }
    commit(
      'setPedidosRealizandoTpvListener',
      ref.onSnapshot((snapDoc) => {
        var res = [];
        snapDoc.forEach((e) => {
          res.push({
            ...e.data(),
            id: e.id,
          });
        });
        if (!!state.pedidoEditando) {
          //comprobar si se ha modificado el pedido que se está editando
          let f = res.find((p) => p.id == state.pedidoEditando);

          if (
            (f &&
              (!f.ultimoGuardadoCuenta ||
                f.ultimoGuardadoCuenta != rootGetters['cuenta'].id)) ||
            (!!f && !!f.cobrado)
          ) {
            //actualizamos el guardado

            commit('setAbriendoNoGrabar', true);
            commit('setPedidoEditandoObj', f);
          }
        }
        console.log(res);
        dispatch('calcularCierre', null, { root: true });
        commit('setPedidosRealizandoTpv', res);
        return;
      })
    );
    // return ref;
  },

  getPedidosDirectorioMatriz({ commit, rootState, state }) {
    // const restSin = restSinNegocio(rootState.restauranteObj);
    // const negocio = rootState.restauranteObj.negocio;
    const dias = parDiasComparar();
    return (
      db
        .collection('pedidos')
        .where('fechaServidor', '>=', dias[0])
        .where('fechaServidor', '<=', dias[1])
        .where('restauranteDirectorioMatriz', '==', rootState.restauranteObj.id)
        // .where('negocioMatriz', '==', negocio)
        .where('estado', '==', 'finalizado')
        .onSnapshot((snapDoc) => {
          var res = [];
          snapDoc.forEach((e) => {
            res.push({
              ...e.data(),
              id: e.id,
            });
          });
          commit('setPedidosDirectorioMatriz', res);
          return;
        })
    );
  },

  getPedidosPendienteImprimir({
    state,
    rootState,
    commit,
    dispatch,
    getters,
    rootGetters,
  }) {
    const restSin = restSinNegocio(rootState.restauranteObj);
    const negocio = rootState.restauranteObj.negocio;
    const dias = parDiasComparar();
    // if (state.pedidosPendienteImprimirListener)
    //   state.pedidosPendienteImprimirListener();

    commit(
      'SET_PEDIDOS_PENDIENTE_IMPRIMIR_LISTENER',
      db
        .collection('restaurantes')
        .doc(rootState.auth.user.restauranteTpv)
        .collection('impresion')
        .where('estado', '==', 'esperandoImpresion')
        .where('cuentaDestino', '==', rootGetters['cuenta'].id)
        .onSnapshot(
          (snapDoc) => {
            // const snapDoc = snapDocPre.filter(
            //   (p) =>
            //     !p.cuentaDestino || p.cuentaDestino == rootGetters['cuenta'].id
            // );
            // if (snapDoc.size == 0) return;
            var res = [];
            snapDoc[1] = { ...snapDoc[0] };
            snapDoc.forEach((e) => {
              let pedido = {
                ...e.data(),
                id: e.id,
              };

              let fI = state.pedidosImprimirGestionando.findIndex(
                (i) => i.id == pedido.id
              );
              if (fI == -1) {
                commit('addPedidoImprimirGestionando', pedido);

                res.push(pedido);
              }
            });

            return Promise.all(
              res.map(async (imp) => {
                let pedido = 'se encuentra';
                pedido = getters['pedidos'].find((p) => p.id == imp.pedido);

                let esPedido =
                  !imp.tickets.includes('abrirCajon') &&
                  !imp.tickets.includes('zcierre');

                if (esPedido && !pedido) {
                  const sleep = (m) => new Promise((r) => setTimeout(r, m));
                  //es un pedido que no tiene el sistema, esperamos a que lo descargue. Problemas de conexión

                  await sleep(3000);

                  pedido = getters['pedidos'].find((p) => p.id == imp.pedido);
                  if (!pedido) await dispatch('marcarImpresionNulo', imp.id);
                }

                if (pedido) {
                  //buscaamos ssi s eha mandado el id de imipresiíon

                  await db
                    .collection('restaurantes')
                    .doc(rootState.auth.user.restauranteTpv)
                    .collection('impresion')
                    .doc(imp.id)
                    .set({ estado: 'impreso' }, { merge: true });

                  if (imp.tickets.includes('caja')) {
                    return dispatch('impresionPedidoCajaLocal', {
                      pedido: {
                        ...pedido,
                        obligatorio: !!imp.obligatorio,
                        idImpreso: imp.id,
                      },
                      ticket: imp.ticket,
                    }).then(() => {
                      setTimeout(() => {
                        return db.collection('pedidos').doc(pedido.id).set(
                          {
                            impresionCaja: moment().unix(),
                            impresionLocalEstado: 'impreso',
                          },
                          { merge: true }
                        );
                      }, 2000);

                      //return dispatch('marcarImpresionNulo', imp.id)
                    });
                  } else if (imp.tickets.includes('cocina')) {
                    return dispatch('impresionPedidoCocinaLocal', {
                      pedido: {
                        ...pedido,
                        impresionCocina: pedido.impresionCocina
                          ? JSON.parse(JSON.stringify(pedido.impresionCocina))
                          : [],
                        obligatorio: !!imp.obligatorio,
                        idImpreso: imp.id,
                      },
                      filtrarImpresos: imp.filtrarImpresos,
                      imp: imp,
                      ticket: imp.ticket,
                    }).then((r) => {
                      //return dispatch('marcarImpresionNulo', imp.id)
                    });
                  } else if (imp.tickets.includes('factSimplificada')) {
                    return dispatch('impresionFacturaSimplificadaLocal', {
                      pedido: {
                        ...pedido,
                        obligatorio: !!imp.obligatorio,
                        idImpreso: imp.id,
                      },
                      factSimplificada: imp.factSimplificada,
                      ticket: imp.ticket,
                    }).then(() => {
                      // setTimeout(() => {
                      //   return db.collection("pedidos").doc(pedido.id).set(
                      //     {
                      //       impresionCaja: moment().unix(),
                      //       impresionLocalEstado: "impreso",
                      //     },
                      //     { merge: true }
                      //   );
                      // }, 2000);
                      //return dispatch('marcarImpresionNulo', imp.id)
                    });
                  }

                  setTimeout(() => {
                    return;
                  }, 1000);
                } else {
                  if (imp.tickets.includes('zcierre')) {
                    await dispatch('imprimirz', {}, { root: true });
                  } else if (imp.tickets.includes('abrirCajon')) {
                    await dispatch('abrirCajon', {}, { root: true });
                  } else if (imp.tickets.includes('resumenCierre')) {
                    if (rootGetters['impresionLocal']) {
                      await dispatch(
                        'imprimirResumenCierre',
                        { resumen: imp.informeCierres, fechas: imp.fechas },
                        { root: true }
                      );
                    }
                  }
                }
                if (!imp.obligatorio)
                  return dispatch('marcarImpresionNulo', imp.id);
              })
            );
            return;
          }
          // () => {
          //   alert('error');
          //   if (state.pedidosPendienteImprimirListener)
          //     state.pedidosPendienteImprimirListener();
          //   commit('RESET_PEDIDOS_PENDIENTE_IMPRIMIR_LISTENER');
          //   dispatch('getPedidosPendienteImprimir');
          // }
        )
    );
  },

  imprimirPedido({}, pedido) {
    const app = firebase.app();
    const functions = app.functions('europe-west2');
    var imp = functions.httpsCallable('httpPedidosImprimirTpv');
    return imp(pedido);
  },

  async imprimirPedidoCaja({ rootState, dispatch, rootGetters }, pedido) {
    const ticketsImprimir = rootGetters.ticketsCaja.filter(
      (t) =>
        t.cuenta == rootGetters['cuenta'].id ||
        t.cuenta == rootGetters['cuenta'].email
    );
    const ticketsMarcar = rootGetters.ticketsCaja.filter(
      (t) =>
        !(
          t.cuenta == rootGetters['cuenta'].id ||
          t.cuenta == rootGetters['cuenta'].email
        )
    );
    console.log(rootGetters['cuenta']);

    console.log(ticketsImprimir);

    await Promise.all([
      ticketsImprimir.map((ticket) =>
        dispatch('impresionPedidoCajaLocal', { pedido, ticket })
      ),
      ticketsMarcar.map((ticket) =>
        dispatch('marcarImpresionCaja', { pedido, ticket })
      ),
    ]);
    return;

    let impresionLocal = rootGetters['impresionLocal'];
    if (impresionLocal || rootState.configuracionTpv.printnodeExclusivo) {
      return dispatch('impresionPedidoCajaLocal', pedido);
    } else {
      return dispatch('marcarImpresionCaja', pedido);
    }
  },
  async imprimirFacturaSimplificada(
    { rootState, dispatch, rootGetters },
    datos
  ) {
    const ticketsBuscar =
      rootGetters.ticketsFactsimplificada.length > 0
        ? rootGetters.ticketsFactsimplificada
        : rootGetters.ticketsCaja;

    const ticketsImprimir = ticketsBuscar.filter(
      (t) => t.cuenta == rootGetters['cuenta'].id
    );
    const ticketsMarcar = ticketsBuscar.filter(
      (t) => t.cuenta !== rootGetters['cuenta'].id
    );

    await Promise.all([
      ticketsImprimir.map((ticket) =>
        dispatch('impresionFacturaSimplificadaLocal', {
          pedido: datos.pedido,
          factSimplificada: datos.factSimplificada,
          ticket,
        })
      ),
      ticketsMarcar.map((ticket) =>
        dispatch('marcarImpresionFacturaSimplificada', {
          pedido: datos.pedido,
          factSimplificada: datos.factSimplificada,
          ticket,
        })
      ),
    ]);
    return;
    // alert('imprimir fact simp');
    let impresionLocal = rootGetters['impresionLocal'];
    if (impresionLocal || rootState.configuracionTpv.printnodeExclusivo) {
      return dispatch('impresionFacturaSimplificadaLocal', datos);
    } else {
      return dispatch('marcarImpresionFacturaSimplificada', datos);
    }
  },

  async imprimirPedidoCocina(
    { rootState, dispatch, rootGetters, commit },
    { pedido, impresionCocina = [], filtrarImpresos = false }
  ) {
    // if (!datos.filtrarImpresos) datos.filtrarImpresos = false;

    const ticketsImprimir = rootGetters.ticketsCocina.filter(
      (t) => t.cuenta == rootGetters['cuenta'].id
    );
    const ticketsMarcar = rootGetters.ticketsCocina.filter(
      (t) => t.cuenta !== rootGetters['cuenta'].id
    );

    await Promise.all([
      ticketsImprimir.map((ticket) =>
        dispatch('impresionPedidoCocinaLocal', {
          pedido,
          ticket,
          filtrarImpresos,
          impresionCocina,
        })
      ),
      ticketsMarcar.map((ticket) =>
        dispatch('marcarImpresionCocina', { pedido, ticket, filtrarImpresos })
      ),
    ]);
    return;

    let impresionLocal = rootGetters['impresionLocal'];

    if (impresionLocal || rootState.configuracionTpv.printnodeExclusivo) {
      if (rootState.onlineComputadora)
        return dispatch('impresionPedidoCocinaLocal', datos);
      else {
        dispatch('impresionPedidoCocinaLocal', datos);
        return;
      }
    } else {
      return dispatch('marcarImpresionCocina', datos);
    }
  },
  marcarImpresionCaja({ rootState }, { pedido, ticket }) {
    return db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('impresion')
      .add({
        cuentaDestino: ticket.cuenta,
        pedido: pedido.id,
        moment: moment().unix(),
        usuario: rootState.auth.user.uid,
        usuarioTpv: rootState.usuarios.usuarioActual,
        estado: 'esperandoImpresion',
        tickets: ['caja'],
        obligatorio: !!pedido.obligatorio,
        ticket: ticket,
        // ticket: {
        //   id: ticket.id,
        //   offset: ticket.offset,
        //   size:ticket.size
        // },
      });
  },
  marcarImpresionFacturaSimplificada(
    { rootState },
    { pedido, factSimplificada, ticket }
  ) {
    return db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('impresion')
      .add({
        ticket: ticket,
        cuentaDestino: ticket.cuenta,
        pedido: pedido.id,
        factSimplificada: factSimplificada,
        moment: moment().unix(),
        usuario: rootState.auth.user.uid,
        usuarioTpv: rootState.usuarios.usuarioActual,
        estado: 'esperandoImpresion',
        tickets: ['factSimplificada'],
      });
  },
  marcarImpresionCocina({ rootState }, { pedido, filtrarImpresos, ticket }) {
    return db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('impresion')
      .add({
        pedido: pedido.id,
        moment: moment().unix(),
        usuario: rootState.auth.user.uid,
        usuarioTpv: rootState.usuarios.usuarioActual,
        estado: 'esperandoImpresion',
        tickets: ['cocina'],
        obligatorio: !!pedido.obligatorio,
        filtrarImpresos: filtrarImpresos,
        cuentaDestino: ticket.cuenta,
        ticket: ticket,
      });
  },
  marcarImpresionNulo({ commit, rootState }, id) {
    return db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('impresion')
      .doc(id)
      .set(
        {
          estado: 'impreso',
          momentImpreso: moment().unix(),
        },
        { merge: true }
      );
  },
  async impresionPedidoCocinaLocal(
    { rootState, dispatch, commit, state, rootGetters },
    { pedido, filtrarImpresos, impresionCocina, ticket }
  ) {
    if (!impresionCocina) impresionCocina = pedido.impresionCocina;
    if (!impresionCocina) impresionCocina = [];

    let datos = {
      pedido: pedido,
      ticket: ticket,
      filtrarImpresos: filtrarImpresos,
      impresionCocina: impresionCocina,
    };

    const datosPedido = await tickets('cocina', datos);

    // const f = !!t.qzDispositivo ? imprimirTicketCocinaQz : imprimirTicketCocina;

    // datosPedido = f({ ...pedido }, t, filtrarImpresos, impresionCocina);

    // impresionRealizada = impresionRealizada || !!datosPedido;

    if (!!datosPedido) {
      //sólo graba los productos en la primera pasada
      // if (!impresionCalculada) {
      if (!!ticket.qzDispositivo) {
        dispatch(
          'qz/imprimirTicket',
          {
            data: datosPedido.datos,
            impresora: ticket.qzDispositivo,
            copias: !!ticket.copias ? ticket.copias : 1,
          },
          { root: true }
        );
        if (datosPedido && datosPedido.productos) {
          const impresionCocinaAux = JSON.parse(
            JSON.stringify(impresionCocina)
          );
          const prodImpresos = datosPedido.productos.filter((t) => !!t).flat();
          prodImpresos.forEach((p) => {
            // impresionCalculada = true;
            let fI = impresionCocinaAux.findIndex(
              (impC) =>
                impC.id == p.id && impC.menuIdConcreto == p.menuIdConcreto
            );
            if (filtrarImpresos) {
              if (fI > -1) {
                impresionCocinaAux[fI].cantidad += p.cantidad;
              } else {
                impresionCocinaAux.push(p);
              }
            } else {
              impresionCocinaAux.push(p);
            }
          });

          if (prodImpresos.length > 0) {
            return db
              .collection('pedidos')
              .doc(pedido.id)
              .set(
                {
                  impresionCocina: impresionCocinaAux,
                },
                { merge: true }
              )
              .then(() => {
                commit('RESET_PEDIDO_COCINA_TOTAL');
              });
          }
        }
      }
      // }
    }
    return;
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == 'cocina1' || t.tipo == 'cocina';
    });
    const impresionCocinaAux = JSON.parse(JSON.stringify(impresionCocina));
    var impresionRealizada = false;
    var impresionCalculada = false; //flag para calcular sólo una vez la impresión en cocina
    var impresionCocina;
    console.log();
    const impresorasTiene = ticketsImp.filter(
      (t) => t.cuenta == rootGetters.cuenta.id
    );
    const otrasImpresoras = ticketsImp.filter(
      (t) => t.cuenta !== rootGetters.cuenta.id
    );
    return Promise.all(
      impresorasTiene.map(async (t, i) => {
        if (!!t.impresionOtroRestCuenta) {
          //imprime en otro rest
          let datos = {
            pedido: pedido,
            filtrarImpresos: filtrarImpresos,
            cuentaDestino: t.impresionOtroRestCuenta,
          };
          return dispatch('marcarImpresionCocina', datos);
        } else {
          var datosPedido;
          if (t.tipo == 'cocina') {
            let datos = {
              pedido: pedido,
              ticket: t,
              filtrarImpresos: filtrarImpresos,
              impresionCocina: impresionCocina,
            };
            datosPedido = await tickets('cocina', datos);
          } else if (t.tipo == 'cocina1') {
            const f = !!t.qzDispositivo
              ? imprimirTicketCocinaQz
              : imprimirTicketCocina;

            datosPedido = f({ ...pedido }, t, filtrarImpresos, impresionCocina);
          }

          impresionRealizada = impresionRealizada || !!datosPedido;
          if (!!datosPedido) {
            console.log(datosPedido);
            //sólo graba los productos en la primera pasada
            if (!impresionCalculada) {
              if (!!rootState.configuracionTpv && !!t.qzDispositivo) {
                await dispatch(
                  'qz/imprimirTicket',
                  {
                    data: datosPedido.datos,
                    impresora: t.qzDispositivo,
                    copias: !!t.copias ? t.copias : 1,
                  },
                  { root: true }
                );
              } else if (
                !!rootState.configuracionTpv &&
                !!rootState.configuracionTpv.printnodeExclusivo
              ) {
                //utiliza printnode exclusivo, no se usa el servidor de impresión de tpv, se manad a imprimir del tirón

                return dispatch(
                  'impresion/ejecutarImpresionPN',
                  {
                    id: 'cocina_' + i + '_' + moment().unix(),
                    pedidoid: pedido.id,
                    fecha: moment().unix(),
                    nombre: 'COCINA ' + i + ' ' + pedido.id,
                    datos: datosPedido.datos,
                    impresionCocina: impresionCocinaAux,
                    dispositivo: t,
                    obligatorio: pedido.obligatorio,
                    idImpresion: pedido.idImpreso,
                  },
                  { root: true }
                );
              } else {
                commit(
                  'impresion/addTrabajoPorImprimir',
                  {
                    id: 'cocina_' + i + '_' + moment().unix(),
                    pedidoid: pedido.id,
                    fecha: moment().unix(),
                    nombre: 'COCINA ' + i + ' ' + pedido.id,
                    datos: datosPedido.datos,
                    impresionCocina: impresionCocinaAux,
                    dispositivo: t,
                    obligatorio: pedido.obligatorio,
                    idImpresion: pedido.idImpreso,
                  },
                  { root: true }
                );
                return true;
              }
              return datosPedido.productos;
            } //productos que se han imprimido en ticket cocina
            console.log('impresionCocina');
            console.log(impresionCocinaAux);
          }
        }
      }),
      otrasImpresoras.map((t) => {
        console.log('otra t');
        return dispatch('marcarImpresionCocina', {
          pedido: pedido,
          filtrarImpresos: filtrarImpresos,
          impresionCocina: impresionCocina,
          cuentaDestino: t.cuenta,
        });
      })
    ).then((r) => {
      console.log(r);
      const prodImpresos = r.filter((t) => !!t).flat();
      prodImpresos.forEach((p) => {
        // impresionCalculada = true;
        let fI = impresionCocinaAux.findIndex(
          (impC) => impC.id == p.id && impC.menuIdConcreto == p.menuIdConcreto
        );
        if (filtrarImpresos) {
          if (fI > -1) {
            impresionCocinaAux[fI].cantidad += p.cantidad;
          } else {
            console.log('push');
            impresionCocinaAux.push(p);
          }
        } else {
          console.log('push isn filtrar');
          impresionCocinaAux.push(p);
        }
      });
      console.log(prodImpresos);

      if (!!impresionRealizada) {
        console.log('impresionCocinaTotal');
        console.log(impresionCocina);
        console.log(impresionCocinaAux);
        return db
          .collection('pedidos')
          .doc(pedido.id)
          .set(
            {
              impresionCocina: impresionCocinaAux,
            },
            { merge: true }
          )
          .then(() => {
            commit('RESET_PEDIDO_COCINA_TOTAL');
          });
      }
    });
  },
  async impresionFacturaSimplificadaLocal(
    { rootState, dispatch, commit },
    { pedido, factSimplificada, ticket }
  ) {
    var f = rootState.impresion.logosCaja.find((l) => l.ticket == ticket.id);
    let logo = f ? f.datos : false;
    const productosFinales = factSimplificada.productos; //await dispatch('carritoArregladoMenu', factSimplificada);

    let obj = {
      pedido: pedido,
      factSimplificada: factSimplificada,
      logo: logo,
      productosFinales: productosFinales,
      ticket: ticket,
      qrIdPedido: rootState.configuracionTpv.qrIdPedido,
    };
    const datosPedido = await tickets('factsimplificada', obj);

    return dispatch(
      'qz/imprimirTicket',
      {
        data: datosPedido,
        impresora: ticket.qzDispositivo,
        copias: ticket.copias ? ticket.copias : 1,
      },
      { root: true }
    );

    function getBase64Image(img) {
      var canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      var ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      var dataURL = canvas.toDataURL();
      return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
    }
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == 'caja2' || t.tipo == 'factsimplificada';
    });
    if (ticketsImp.length == 0) {
      //buscar uso por defecto en caja
      ticketsImp = rootState.datosTpv.tickets.filter((t) => {
        return t.tipo == 'caja';
      });
    }
    return Promise.all(
      ticketsImp.map(async (t, i) => {
        var f = rootState.impresion.logosCaja.find((l) => l.ticket == t.id);
        let logo = f ? f.datos : false;
        console.log(factSimplificada);
        // var productosFinales = await dispatch(
        //   'carritoArregladoMenu',
        //   factSimplificada
        // );
        const productosFinales = factSimplificada.productos;
        var datosPedido;
        if (t.tipo == 'caja2') {
          //deprecated
          const fTicket = !!t.qzDispositivo
            ? impresionFacturaSimplificadaQz
            : impresionFacturaSimplificada;
          datosPedido = await fTicket(
            pedido,
            factSimplificada,
            logo,
            productosFinales
          );
        } else if (t.tipo == 'factsimplificada' || t.tipo == 'caja') {
          let obj = {
            pedido: pedido,
            factSimplificada: factSimplificada,
            logo: logo,
            productosFinales: productosFinales,
            ticket: t,
          };
          datosPedido = await tickets('factsimplificada', obj);
        }

        if (!!t.impresionOtroRestCuenta) {
          //imprime en otro rest
          let datos = {
            pedido: pedido,
            filtrarImpresos: filtrarImpresos,
            cuentaDestino: t.impresionOtroRestCuenta,
          };
          return dispatch('marcarImpresionCaja', pedido);
        } else if (!!rootState.configuracionTpv && !!t.qzDispositivo) {
          var qrcode = false;
          if (factSimplificada.ficheroFactura) {
            const QRCode = require('qrcode');
            async function getQRCode(content) {
              return new Promise((resolve, reject) => {
                QRCode.toDataURL(content, (err, url) => {
                  if (err) return reject(err);

                  loadImage(url).then(resolve, reject);
                });
              });
            }
            qrcode = getBase64Image(
              await getQRCode(factSimplificada.ficheroFactura)
            );
          }
          dispatch(
            'qz/imprimirTicket',
            {
              data: datosPedido,
              impresora: t.qzDispositivo,
              logo,
              factura: qrcode,
            },
            { root: true }
          );
        } else if (
          !!rootState.configuracionTpv &&
          !!rootState.configuracionTpv.printnodeExclusivo
        ) {
          //utiliza printnode exclusivo, no se usa el servidor de impresión de tpv, se manad a imprimir del tirón

          return dispatch(
            'impresion/ejecutarImpresionPN',
            {
              id: 'facturaSimplificada_' + i + '_' + moment().unix(),
              fecha: moment().unix(),
              pedidoid: pedido.id,
              nombre: 'FACTURA_SIMPLIFICADA ' + i + ' ' + pedido.id,
              datos: datosPedido,
              dispositivo: t,
              obligatorio: false,
              idImpresion: pedido.idImpreso,
            },
            { root: true }
          );
        } else {
          commit(
            'impresion/addTrabajoPorImprimir',
            {
              id: 'caja_' + i + '_' + moment().unix(),
              fecha: moment().unix(),
              pedidoid: pedido.id,
              nombre: 'CAJA ' + i + ' ' + pedido.id,
              datos: datosPedido,
              dispositivo: t,
              obligatorio: pedido.obligatorio,
              idImpresion: pedido.idImpreso,
            },
            { root: true }
          );
          return;
        }
      })
    ).then((r) => {});
  },
  async impresionPedidoCajaLocal(
    { rootState, dispatch, commit, rootGetters },
    { pedido, ticket }
  ) {
    var f = rootState.impresion.logosCaja.find((l) => l.ticket == ticket.id);
    let logo = f ? f.datos : false;
    var productos = await dispatch('carritoArregladoMenu', pedido);

    var datosPedido =
      ticket.tipo == 'caja2'
        ? !!ticket.qzDispositivo
          ? await imprimirTicketTipo2Qz(pedido, logo, productos)
          : await imprimirTicketTipo2(pedido, logo)
        : await tickets('caja', {
            data: pedido,
            logo: logo,
            productosFinales: productos,
            ticket: ticket,
          });

    return dispatch(
      'qz/imprimirTicket',
      {
        data: datosPedido,
        impresora: ticket.qzDispositivo,
        copias: ticket.copias ? ticket.copias : 1,
      },
      { root: true }
    );
    return;
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == 'caja2' || t.tipo == 'caja';
    });

    return Promise.all(
      ticketsImp.map(async (t, i) => {
        var f = rootState.impresion.logosCaja.find((l) => l.ticket == t.id);
        let logo = f ? f.datos : false;
        var productos = await dispatch('carritoArregladoMenu', pedido);

        var datosPedido =
          t.tipo == 'caja2'
            ? !!t.qzDispositivo
              ? await imprimirTicketTipo2Qz(pedido, logo, productos)
              : await imprimirTicketTipo2(pedido, logo)
            : await tickets('caja', {
                data: pedido,
                logo: logo,
                productosFinales: productos,
                ticket: t,
              });
        //comprobamos si imprime esta cuenta en esa impresora
        if (
          !(
            t.cuentas &&
            Array.isArray(t.cuentas) &&
            !t.cuentas.includes(rootGetters['cuenta'].email)
          )
        ) {
          if (!!t.impresionOtroRestCuenta) {
            //imprime en otro rest
            let datos = {
              pedido: pedido,
              filtrarImpresos: filtrarImpresos,
              cuentaDestino: t.impresionOtroRestCuenta,
            };
            return dispatch('marcarImpresionCaja', pedido);
          } else if (!!rootState.configuracionTpv && !!t.qzDispositivo) {
            dispatch(
              'qz/imprimirTicket',
              { data: datosPedido, impresora: t.qzDispositivo },
              { root: true }
            ).then(() => {
              if (pedido.impresionLocalEstado != 'impreso') {
                return db
                  .collection('pedidos')
                  .doc(pedido.id)
                  .set(
                    {
                      historial: firebase.firestore.FieldValue.arrayUnion({
                        fecha: moment().unix(),
                        usuario: rootState.auth.user.uid,
                        mensaje: 'Impresión manual desde TPV ',
                      }),
                      impresionLocalEstado: 'impreso',
                    },
                    { merge: true }
                  );
              }
            });
          } else if (
            !!rootState.configuracionTpv &&
            !!rootState.configuracionTpv.printnodeExclusivo
          ) {
            //utiliza printnode exclusivo, no se usa el servidor de impresión de tpv, se manad a imprimir del tirón
            return dispatch(
              'impresion/ejecutarImpresionPN',
              {
                id: 'caja_' + i + '_' + moment().unix(),
                fecha: moment().unix(),
                pedidoid: pedido.id,
                nombre: 'CAJA ' + i + ' ' + pedido.id,
                datos: datosPedido,
                dispositivo: t,
                obligatorio: pedido.obligatorio,
                idImpresion: pedido.idImpreso,
              },
              { root: true }
            );
          } else {
            commit(
              'impresion/addTrabajoPorImprimir',
              {
                id: 'caja_' + i + '_' + moment().unix(),
                fecha: moment().unix(),
                pedidoid: pedido.id,
                nombre: 'CAJA ' + i + ' ' + pedido.id,
                datos: datosPedido,
                dispositivo: t,
                obligatorio: pedido.obligatorio,
                idImpresion: pedido.idImpreso,
              },
              { root: true }
            );
            return;
          }
        }
      })
    ).then((r) => {});
  },
  imprimirPararEnCocinaLocal({ rootState, commit }, { pedido }) {
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == 'cocina1' || t.tipo == 'cocina';
    });
    var datosParar = imprimirPararCocina(pedido);
    return Promise.all(
      ticketsImp.map((t, i) => {
        commit(
          'impresion/addTrabajoPorImprimir',
          {
            id: 'parar_cocina_' + i + '_' + moment().unix(),
            pedidoid: pedido.id,
            fecha: moment().unix(),
            nombre: 'PARAR COCINA ' + i + ' ' + pedido.id,
            datos: datosParar,
            dispositivo: t,
            idImpresion: pedido.idImpreso,
          },
          { root: true }
        );
        return true;
      })
    );
  },
  imprimirReanudarEnCocinaLocal({ rootState, commit }, { pedido }) {
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == 'cocina1' || t.tipo == 'cocina';
    });
    var datosReanudar = imprimirReanudarCocina(pedido);
    return Promise.all(
      ticketsImp.map((t, i) => {
        commit(
          'impresion/addTrabajoPorImprimir',
          {
            id: 'reanudar_cocina_' + i + '_' + moment().unix(),
            pedidoid: pedido.id,
            fecha: moment().unix(),
            nombre: 'REANUDAR COCINA ' + i + ' ' + pedido.id,
            datos: datosReanudar,
            dispositivo: t,
            idImpresion: pedido.idImpreso,
          },
          { root: true }
        );
        return true;
      })
    );
  },
  marcarPararEnCocinaLocal({ rootState }, { pedido }) {
    return db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('impresion')
      .add({
        pedido: pedido.id,
        moment: moment().unix(),
        usuario: rootState.auth.user.uid,
        usuarioTpv: rootState.usuarios.usuarioActual,
        estado: 'esperandoImpresion',
        tickets: ['pararCocina'],
      });
  },
  marcarReanudarEnCocinaLocal({ rootState }, { pedido }) {
    return db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('impresion')
      .add({
        pedido: pedido.id,
        moment: moment().unix(),
        usuario: rootState.auth.user.uid,
        usuarioTpv: rootState.usuarios.usuarioActual,
        estado: 'esperandoImpresion',
        tickets: ['reanudarCocina'],
      });
  },
  pararEnCocina({ rootState, dispatch, rootGetters }, pedido) {
    let impresionLocal = rootGetters['impresionLocal'];
    var ref;
    if (impresionLocal) {
      ref = dispatch('imprimirPararEnCocinaLocal', { pedido: pedido });
    } else {
      ref = dispatch('marcarPararEnCocinaLocal', pedido);
    }
    return ref.then(() => {
      return db
        .collection('pedidos')
        .doc(pedido.id)
        .set(
          {
            historial: firebase.firestore.FieldValue.arrayUnion({
              fecha: moment().unix(),
              usuario: rootState.auth.user.uid,
              mensaje: 'Parado en cocina desde TPV ',
            }),
            estadoCocina: 'parado',
          },
          { merge: true }
        );
    });
  },
  reanudarEnCocina({ rootState, dispatch, rootGetters }, pedido) {
    let impresionLocal = rootGetters['impresionLocal'];
    var ref;
    if (impresionLocal) {
      ref = dispatch('imprimirReanudarEnCocinaLocal', { pedido: pedido });
    } else {
      ref = dispatch('marcarReanudarEnCocinaLocal', pedido);
    }
    return ref.then(() => {
      return db
        .collection('pedidos')
        .doc(pedido.id)
        .set(
          {
            historial: firebase.firestore.FieldValue.arrayUnion({
              fecha: moment().unix(),
              usuario: rootState.auth.user.uid,
              mensaje: 'Parado en cocina desde TPV ',
            }),
            estadoCocina: 'reanudado',
          },
          { merge: true }
        );
    });
  },

  addProducto(
    { rootState, getters, commit, state, dispatch, rootGetters },
    payload
  ) {
    const producto = payload.producto;
    const opcionesObj = payload.opciones ? payload.opciones : false;
    const carrito = JSON.parse(
      JSON.stringify(state.pedidoEditandoObj.productos)
    );
    let opciones;
    let opcionesNombre = [];
    let opcionesPrecio = [];
    let opcionesTpv = [];
    let opcionesTipo = [];
    let impuestosGlobales = rootState.impuestos;

    if (!!opcionesObj) {
      opciones = JSON.parse(JSON.stringify(opcionesObj.ids)); //para convertir en un array sin observer
      opcionesNombre = JSON.parse(JSON.stringify(opcionesObj.nombres)); //para convertir en un array sin observer
      opcionesPrecio = JSON.parse(JSON.stringify(opcionesObj.precios)); //para convertir en un array sin observer
      opcionesTpv = JSON.parse(JSON.stringify(opcionesObj.nombresTpv)); //para convertir en un array sin observer
    } else {
      opciones = [];
    }
    const idcocina = key();
    let productoAdd = {
      cantidad: payload.cantidad,
      puedeOpciones: !!producto.grupoModificador,
      id: producto.id,
      //impuestoText: mostrarImpuestoTexto(state.activeProduct.impuesto, impuestosGlobales),
      impuesto: producto.impPorcentaje,
      nombre: producto.nombre,
      nombreTpv: producto.nombreTpv ? producto.nombreTpv : producto.nombre,
      opciones: opciones,
      opcionesName: opcionesNombre,
      opcionesTpv: opcionesTpv,
      opcionesPrecio: opcionesPrecio,
      categoria: producto.categoria,
      porPeso: !!producto.porPeso,
      orden: !!producto.orden ? producto.orden : null,
      ordenCat: !!producto.ordenCat ? producto.ordenCat : null,
      precio: parseFloat(producto.precio),
      comentarioCocina: '',
      ...(payload.menuId
        ? {
            menuId: payload.menuId,
            menuIdConcreto: payload.menuIdConcreto,
            menuIdGrupo: payload.menuIdGrupo,
            menuCopy: payload.menuCopy,
          }
        : {}),
    };

    if (rootState.configuracionTpv.cocinaControl)
      productoAdd.cocinaProductos = [idcocina];

    /*********************************** Busca si ya existe ***********************************/
    let coincide = carrito.findIndex((e) => {
      // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();)
      var array2Sorted = opciones.slice().sort();
      return (
        e.id === producto.id &&
        e.menuIdConcreto === productoAdd.menuIdConcreto &&
        e.opciones.length === opciones.length &&
        e.opciones
          .slice()
          .sort()
          .every(function (value, index) {
            return value === array2Sorted[index];
          })
      );
    });
    if (coincide >= 0) {
      carrito[coincide].cantidad += payload.cantidad;
      productoAdd.idproductoconcreto = carrito[coincide].idproductoconcreto;
      if (rootState.configuracionTpv.cocinaControl) {
        carrito[coincide].cocinaProductos.push(idcocina);
      }
    } else {
      productoAdd.idproductoconcreto = key();
      carrito.push(productoAdd);
    }
    let historialAdd = [
      ...state.pedidoEditandoObj.historial,
      {
        fecha: moment().unix(),
        mensaje:
          'Se añade producto: ' +
          payload.cantidad +
          'x ' +
          payload.producto.nombre,
      },
    ];
    commit('setPedidoEditandoObjValor', {
      key: 'historial',
      value: historialAdd,
    });

    commit('setPedidoEditandoObjValor', { key: 'productos', value: carrito });
    commit('setPedidoEditandoObjValor', {
      key: 'cobradoProgramado',
      value: [],
    });

    if (rootState.configuracionTpv.cocinaControl) {
      if (rootGetters['cocinaControl'].includes(productoAdd.categoria)) {
        const cocinaProductos = Array.isArray(
          state.pedidoEditandoObj.cocinaProductos
        )
          ? [...state.pedidoEditandoObj.cocinaProductos]
          : [];
        cocinaProductos.push({
          id: productoAdd.id,
          idproductoconcreto: productoAdd.idproductoconcreto,
          idcocina: idcocina,
          estado: 'cocinaEspera', //cocina-espera, cocina-realizando, cocina-terminado, marchando, entregado
          nota: '',
          moment: moment().unix(),
          historial: [
            {
              mensaje: 'Creado en cocina',
              moment: moment().unix(),
            },
          ],
        });
        commit('setPedidoEditandoObjValor', {
          key: 'cocinaProductos',
          value: cocinaProductos,
        });
        commit('setPedidoEditandoObjValor', {
          key: 'cocinaRevisar',
          value: moment().unix(),
        });
      }
    }
    return dispatch('calcularTotales').then(() =>
      commit('productos/reiniciarProductosPidiendo', false, { root: true })
    );
  },
  addMenu(
    { rootState, getters, commit, state, dispatch, rootGetters },
    payload
  ) {
    const menu = payload.menu;
    const opcionesObj = payload.opciones ? payload.opciones : false;
    const menus = JSON.parse(JSON.stringify(state.pedidoEditandoObj.menus));

    let menuAdd = {
      ...menu,
      impuesto: menu.impPorcentaje,
      precioProductoTotal: menu.precio,
      precioProductoTotalUnitario: menu.precio,
      cantidad: payload.cantidad,
    };

    /*********************************** Busca si ya existe ***********************************/

    menus.push(menuAdd);

    let historialAdd = [
      ...state.pedidoEditandoObj.historial,
      {
        fecha: moment().unix(),
        mensaje: 'Se añade menu: ' + payload.cantidad + 'x ' + menuAdd.nombre,
      },
    ];
    commit('setPedidoEditandoObjValor', {
      key: 'historial',
      value: historialAdd,
    });

    commit('setPedidoEditandoObjValor', { key: 'menus', value: menus });

    if (rootGetters['cuenta'].cocinaControl) {
    }
    return dispatch('calcularTotales').then(() =>
      commit('productos/reiniciarProductosPidiendo', false, { root: true })
    );
    // .finally(() => {
    //   alert('fin accion ' + menuAdd.idconcreto);
    // });
  },
  cambiarCantidadCarritoMenu(
    { commit, state, dispatch, rootGetters },
    payload
  ) {
    const menu = payload.menu;

    const menus = JSON.parse(JSON.stringify(state.pedidoEditandoObj.menus));

    /*********************************** Busca si ya existe ***********************************/
    let coincide = menus.findIndex((e) => {
      // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();)
      return menu.idconcreto == e.idconcreto;
    });

    if (coincide >= 0) {
      menus[coincide].cantidad = payload.cantidad;

      if (!!payload.precioProductoTotalUnitario) {
        //comprobamos si se ha cambiado el precio. Si no se cambia, se comprueba si tiene previo.
        //para que no vaya descontando el descuento en cada cambio;
        const mismoPrecio =
          payload.precioProductoTotalUnitario ==
          menus[coincide].precioProductoTotalUnitario;
        const precioProductoTotalUnitarioComprobado =
          mismoPrecio && menus[coincide].precioProductoTotalUnitarioPrevio
            ? menus[coincide].precioProductoTotalUnitarioPrevio
            : payload.precioProductoTotalUnitario;
        menus[coincide].precioProductoTotalUnitarioPersonalizado =
          precioProductoTotalUnitarioComprobado;
      }

      menus[coincide].precioProductoTotalUnitario =
        payload.cantidad == 0
          ? 0
          : parseFloat(
              payload.precioProductoTotalUnitarioPrevio
                ? payload.precioProductoTotalUnitarioPrevio
                : payload.precioProductoTotalUnitario
            );
    }
    let historialAdd = [
      ...state.pedidoEditandoObj.historial,
      {
        fecha: moment().unix(),
        mensaje:
          'Se cambia menu: ' +
          payload.menu.nombre +
          ' ' +
          payload.cantidad +
          'x ' +
          payload.precioProductoTotalUnitario +
          ' €',
      },
    ];
    commit('setPedidoEditandoObjValor', {
      key: 'historial',
      value: historialAdd,
    });

    commit('setPedidoEditandoObjValor', { key: 'menus', value: menus });

    if (rootGetters['cuenta'].cocinaControl) {
    }
    return dispatch('calcularTotales').then(() =>
      commit('productos/reiniciarProductosPidiendo', false, { root: true })
    );
  },
  cambiarCantidadCarrito(
    { state, commit, dispatch, rootState, rootGetters },
    payload
  ) {
    console.log(payload);

    var carrito = JSON.parse(JSON.stringify(state.pedidoEditandoObj.productos));

    /*********************************** Busca si ya existe ***********************************/
    let coincide = carrito.findIndex((e) => {
      // return e.id === producto.id && (e.opciones).toString() === (opciones).toString();
      var array2Sorted = payload.producto.opciones.slice().sort();
      return (
        e.id === payload.producto.id &&
        e.menuIdConcreto === payload.producto.menuIdConcreto &&
        e.opciones.length === payload.producto.opciones.length &&
        e.opciones
          .slice()
          .sort()
          .every(function (value, index) {
            return value === array2Sorted[index];
          })
      );
    });
    if (coincide > -1) {
      if (payload.cantidad <= 0) {
        //se marca a 0
        // if (payload.producto.eliminadoMotivo)
        carrito[coincide].cantidad = payload.cantidad;
        if (!!payload.precioProductoTotalUnitario)
          carrito[coincide].precioProductoTotalUnitarioPersonalizado =
            payload.precioProductoTotalUnitario;
      } else {
        carrito[coincide].cantidad = payload.cantidad;
        if (!!payload.precioProductoTotalUnitario) {
          //comprobamos si se ha cambiado el precio. Si no se cambia, se comprueba si tiene previo.
          //para que no vaya descontando el descuento en cada cambio;
          const mismoPrecio =
            payload.precioProductoTotalUnitario ==
            carrito[coincide].precioProductoTotalUnitario;
          const precioProductoTotalUnitarioComprobado =
            mismoPrecio && carrito[coincide].precioProductoTotalUnitarioPrevio
              ? carrito[coincide].precioProductoTotalUnitarioPrevio
              : payload.precioProductoTotalUnitario;

          carrito[coincide].precioProductoTotalUnitarioPersonalizado =
            precioProductoTotalUnitarioComprobado;
          carrito[coincide].precioProductoTotalUnitarioPrevio =
            precioProductoTotalUnitarioComprobado;
          carrito[coincide].precioProductoTotalPrevio =
            precioProductoTotalUnitarioComprobado * carrito[coincide].cantidad;
          carrito[coincide].precioProductoTotalUnitario =
            precioProductoTotalUnitarioComprobado * carrito[coincide].cantidad;

          const descuentoObj = payload.descuento;

          if (descuentoObj.descuentoCantidad) {
            carrito[coincide].descuentoCantidad =
              descuentoObj.descuentoCantidad;
            carrito[coincide].descuentoCantidadUnitario =
              descuentoObj.descuentoCantidadUnitario;
            carrito[coincide].descuentoPorcentaje =
              descuentoObj.descuentoPorcentaje;
            carrito[coincide].precioProductoTotalPrevio =
              descuentoObj.precioProductoTotalPrevio;
            carrito[coincide].precioProductoTotalUnitario =
              descuentoObj.precioProductoTotalUnitario;
            carrito[coincide].precioProductoTotalUnitarioPrevio =
              descuentoObj.precioProductoTotalUnitarioPrevio;
            carrito[coincide].descuentoPersonalizado = true;
          }
        }
      }
      if (rootState.configuracionTpv.cocinaControl) {
        const cocinaProductos = Array.isArray(
          state.pedidoEditandoObj.cocinaProductos
        )
          ? [...state.pedidoEditandoObj.cocinaProductos]
          : [];
        const productosEnCocinaNoCancelados = cocinaProductos.filter(
          (p) =>
            p.idproductoconcreto == payload.producto.idproductoconcreto &&
            p.estado !== 'cancelado'
        ).length;
        let diferencia = payload.cantidad - productosEnCocinaNoCancelados;

        if (diferencia > 0) {
          //añadir
          if (
            rootGetters['cocinaControl'].includes(payload.producto.categoria)
          ) {
            const idcocinas = [];

            while (diferencia > 0) {
              const idaux = key();
              idcocinas.push(idaux);
              cocinaProductos.push({
                id: payload.producto.id,
                idproductoconcreto: payload.producto.idproductoconcreto,

                idcocina: idaux,
                estado: 'cocinaEspera', //cocina-espera, cocina-realizando, cocina-terminado, marchando, entregado
                nota: '',
                moment: moment().unix(),
                historial: [
                  {
                    mensaje: 'Creado en cocina',
                    moment: moment().unix(),
                  },
                ],
              });
              diferencia--;
            }
            carrito[coincide].cocinaProductos = [
              ...carrito[coincide].cocinaProductos,
              ...idcocinas,
            ];

            commit('setPedidoEditandoObjValor', {
              key: 'cocinaProductos',
              value: cocinaProductos,
            });
            commit('setPedidoEditandoObjValor', {
              key: 'cocinaRevisar',
              value: moment().unix(),
            });
          }
        } else if (diferencia < 0) {
          diferencia = Math.abs(diferencia);
          //quitar

          while (diferencia > 0) {
            if (
              Array.isArray(payload.producto.cocinaProductos) &&
              payload.producto.cocinaProductos.length > 0
            ) {
              // const idbuscar =
              //   payload.producto.cocinaProductos[
              //     payload.producto.cocinaProductos.length - diferencia
              //   ];
              const cocinaProductos = Array.isArray(
                state.pedidoEditandoObj.cocinaProductos
              )
                ? [...state.pedidoEditandoObj.cocinaProductos]
                : [];
              const fI = cocinaProductos.findLastIndex(
                (p) =>
                  p.idproductoconcreto == payload.producto.idproductoconcreto &&
                  p.estado !== 'cancelado'
              );

              if (fI > -1) {
                cocinaProductos[fI].estado = 'cancelado';
              }
              commit('setPedidoEditandoObjValor', {
                key: 'cocinaProductos',
                value: cocinaProductos,
              });
              commit('setPedidoEditandoObjValor', {
                key: 'cocinaRevisar',
                value: moment().unix(),
              });
            }
            diferencia--;
          }
        }
      }

      carrito[coincide].comentarioCocina = !!payload.comentarioCocina
        ? payload.comentarioCocina
        : '';
      let historialAdd = [
        ...state.pedidoEditandoObj.historial,
        {
          fecha: moment().unix(),
          mensaje:
            'Se cambia cantidad: ' +
            payload.cantidad +
            'x ' +
            payload.producto.nombre,
        },
      ];
      commit('setPedidoEditandoObjValor', {
        key: 'historial',
        value: historialAdd,
      });
      console.log('carrito[coincide]');
      console.log(carrito[coincide]);
      commit('setPedidoEditandoObjValor', { key: 'productos', value: carrito });
      return dispatch('calcularTotales');
    }
  },

  calcularTotalesProductosParametros({}, pedido) {
    let productos = pedido.productos.map((prd) => {
      let precioProducto =
        prd.precio +
        prd.opcionesPrecio.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        );
      let precioProductoTotalUnitario =
        !!prd.precioProductoTotalUnitarioPersonalizado
          ? prd.precioProductoTotalUnitarioPersonalizado
          : precioProducto;
      let precioProductoTotal = precioProductoTotalUnitario * prd.cantidad;

      let baseImponible = redondeo(
        precioProductoTotal / (parseFloat(prd.impuesto) + 1)
      );
      let impuestoTotal =
        precioProductoTotal -
        (baseImponible > 0 ? baseImponible : -baseImponible);

      return {
        ...prd,
        precioProductoTotalUnitario: precioProductoTotalUnitario,
        precioProductoTotal: precioProductoTotal,
        impuestoCantidad: impuestoTotal,
        baseImponible: baseImponible,
      };
    });
    let todoComputar = productos;
    let precioTotal = todoComputar.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.precioProductoTotal,
      0
    );
    let impuestosTotal = todoComputar.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.impuestoCantidad,
      0
    );
    return {
      ...pedido,
      productos: productos,
      precioTotal: precioTotal,
      impuestosTotal: redondeo(impuestosTotal),
    };
  },
  calcularTotales({ state, commit }) {
    commit('setPedidoEditandoObjValor', {
      key: 'productos',
      value: state.pedidoEditandoObj.productos.map((prd) => {
        let precioProducto =
          prd.precio +
          prd.opcionesPrecio.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0
          );
        let precioProductoTotalUnitario =
          !!prd.precioProductoTotalUnitarioPersonalizado
            ? prd.precioProductoTotalUnitarioPersonalizado
            : precioProducto;

        let precioProductoTotal = precioProductoTotalUnitario * prd.cantidad;

        //descuentos
        console.log(prd);
        let objDescuento = {};
        // let objDescuento = {
        //   precioProductoTotalPrevio: prd.precioProductoTotal,
        //   precioProductoTotalUnitarioPrevio: prd.precioProductoTotalUnitario,
        //   precioProductoTotalUnitario: prd.precioProductoTotalUnitario,
        // };
        if (!prd.descuentoPorcentaje) {
          delete prd.descuentoPersonalizado;
          delete prd.descuentoPorcentaje;
          delete prd.descuentoCantidadUnitario;
          delete prd.descuentoCantidad;
        }
        if (prd.descuentoPersonalizado && prd.descuentoPorcentaje) {
          //ya está agregado el descuento
          objDescuento.descuentoPorcentaje = parseFloat(
            prd.descuentoPorcentaje
          );
          objDescuento.descuentoCantidadUnitario = redondeo(
            (precioProductoTotalUnitario * objDescuento.descuentoPorcentaje) /
              100
          );
          objDescuento.descuentoCantidad =
            objDescuento.descuentoCantidadUnitario * prd.cantidad;
          objDescuento.precioProductoTotalUnitarioPrevio =
            precioProductoTotalUnitario;
          objDescuento.precioProductoTotalPrevio = precioProductoTotal;
          objDescuento.precioProductoTotalUnitario =
            precioProductoTotalUnitario -
            objDescuento.descuentoCantidadUnitario;
          objDescuento.precioProductoTotal =
            objDescuento.precioProductoTotalUnitario * prd.cantidad;
        } else if (
          !isNaN(parseInt(state.pedidoEditandoObj.descuentoPorcentaje))
        ) {
          objDescuento.descuentoPorcentaje = parseFloat(
            state.pedidoEditandoObj.descuentoPorcentaje
          );
          objDescuento.descuentoCantidadUnitario = redondeo(
            (precioProductoTotalUnitario * objDescuento.descuentoPorcentaje) /
              100
          );
          objDescuento.descuentoCantidad =
            objDescuento.descuentoCantidadUnitario * prd.cantidad;
          objDescuento.precioProductoTotalUnitarioPrevio =
            precioProductoTotalUnitario;
          objDescuento.precioProductoTotalPrevio = precioProductoTotal;
          objDescuento.precioProductoTotalUnitario =
            precioProductoTotalUnitario -
            objDescuento.descuentoCantidadUnitario;
          objDescuento.precioProductoTotal =
            objDescuento.precioProductoTotalUnitario * prd.cantidad;
        }

        let baseImponible = redondeo(
          precioProductoTotal / (parseFloat(prd.impuesto) + 1)
        );
        let impuestoTotal =
          precioProductoTotal -
          (baseImponible > 0 ? baseImponible : -baseImponible);

        return {
          ...prd,
          precioProductoTotalUnitario: precioProductoTotalUnitario,
          precioProductoTotal: precioProductoTotal,
          impuestoCantidad: impuestoTotal,
          baseImponible: baseImponible,
          ...objDescuento,
        };
      }),
    });
    if (state.pedidoEditandoObj.menus) {
      commit('setPedidoEditandoObjValor', {
        key: 'menus',
        value: state.pedidoEditandoObj.menus.map((prd) => {
          // let precioProducto = (prd.precio + prd.opcionesPrecio.reduce((accumulator, currentValue) =>
          //   accumulator + currentValue, 0));
          let precioProducto = prd.precio;
          let precioProductoTotalUnitario =
            !!prd.precioProductoTotalUnitarioPersonalizado
              ? prd.precioProductoTotalUnitarioPersonalizado
              : prd.precioProductoTotalUnitarioPrevio
              ? prd.precioProductoTotalUnitarioPrevio
              : precioProducto;

          let precioProductoTotal = precioProductoTotalUnitario * prd.cantidad;

          //descuentos
          let objDescuento = {};
          if (prd.descuentoPersonalizado) {
          } else if (
            !isNaN(parseInt(state.pedidoEditandoObj.descuentoPorcentaje))
          ) {
            objDescuento.descuentoPorcentaje = parseFloat(
              state.pedidoEditandoObj.descuentoPorcentaje
            );
            objDescuento.descuentoCantidadUnitario = redondeo(
              (precioProductoTotalUnitario * objDescuento.descuentoPorcentaje) /
                100
            );
            objDescuento.descuentoCantidad =
              objDescuento.descuentoCantidadUnitario * prd.cantidad;
            objDescuento.precioProductoTotalUnitarioPrevio =
              precioProductoTotalUnitario;
            objDescuento.precioProductoTotalPrevio = precioProductoTotal;
            objDescuento.precioProductoTotalUnitario =
              precioProductoTotalUnitario -
              objDescuento.descuentoCantidadUnitario;
            objDescuento.precioProductoTotal =
              objDescuento.precioProductoTotalUnitario * prd.cantidad;
          }

          let baseImponible = redondeo(
            precioProductoTotal / (parseFloat(prd.impPorcentaje) + 1)
          );
          let impuestoTotal =
            precioProductoTotal -
            (baseImponible > 0 ? baseImponible : -baseImponible);
          return {
            ...prd,
            precioProductoTotalUnitario: precioProductoTotalUnitario,
            precioProductoTotal: precioProductoTotal,
            impuestoCantidad: impuestoTotal,
            baseImponible: baseImponible,
            ...objDescuento,
          };
        }),
      });
    }
    let todoComputar = [
      ...state.pedidoEditandoObj.productos.filter((p) => !p.menuId),
      ...(!!state.pedidoEditandoObj.menus ? state.pedidoEditandoObj.menus : []),
    ];

    let precioTotalPrevio = todoComputar.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.precioProductoTotalPrevio,
      0
    );
    let precioTotal = todoComputar.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.precioProductoTotal,
      0
    );

    //hay descuento
    if (state.pedidoEditandoObj.descuentoPorcentaje) {
      commit('setPedidoEditandoObjValor', {
        key: 'descuentoCantidad',
        value: todoComputar.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.descuentoCantidad,
          0
        ),
      });
    }

    commit('setPedidoEditandoObjValor', {
      key: 'precioTotal',
      value: precioTotal,
    });
    commit('setPedidoEditandoObjValor', {
      key: 'precioTotalPrevio',
      value: precioTotalPrevio,
    });

    let impuestosTotal = todoComputar.reduce(
      (accumulator, currentValue) =>
        accumulator + currentValue.impuestoCantidad,
      0
    );

    commit('setPedidoEditandoObjValor', {
      key: 'impuestosTotal',
      value: redondeo(impuestosTotal),
    });
  },
  cambiarPedido({}, pedido) {
    let id = pedido.id;
    let pActualizado = actualizarCarrito(pedido);
    return db
      .collection('pedidos')
      .doc(id)
      .set(
        {
          forma_pago: pedido.forma_pago,
          modo: pActualizado.modo,
          productos: pActualizado.productos,
          precioTotal: pActualizado.precioTotal,
          numeroProductos: pActualizado.numeroProductos,
          cambio: pActualizado.cambio ? pActualizado.cambio : 0,
        },
        { merge: true }
      );
  },
  addNuevoPedido({ state, getters, dispatch, rootState }, pedido) {
    const siguienteNumRest = state.siguienteNumRest;
    const siguienteNumRestDia = state.siguienteNumRestDia;
    const siguienteNumPedidoRestModoObj = state.siguienteNumRestDiaModo;
    const siguienteNumPedidoRestModo =
      !!siguienteNumPedidoRestModoObj &&
      !!siguienteNumPedidoRestModoObj[pedido.modoId]
        ? siguienteNumPedidoRestModoObj[pedido.modoId]
        : null;

    dispatch('numPedidoRest', {
      modo: pedido.modoId,
    });

    //buscamos serie para el modo del pedido
    let f = rootState.datosTpv.modos.find((m) => m.id === pedido.modoId);
    var siguienteNumPedidoRestModoMostrar =
      !!f && !!f.numPedidoRestModoSerie
        ? f.numPedidoRestModoSerie +
          siguienteNumPedidoRestModo.toString().padStart(2, '0')
        : siguienteNumPedidoRestModo;

    return Promise.resolve()
      .then((r) => {
        pedido = {
          ...pedido,
          numPedidoRest: siguienteNumRest,
          numPedidoRestDia: siguienteNumRestDia,
          numPedidoRestDiaModo: siguienteNumPedidoRestModo,
          numPedidoRestDiaModoMostrar: siguienteNumPedidoRestModoMostrar,
          id: key(),
        };

        var pMesa;
        if (pedido.modo == 'mesa' && pedido.mesa) {
          pMesa = dispatch('grabarPedidoMesa', {
            pedido: pedido.id,
            mesa: pedido.mesa,
          });
        } else {
          pMesa = Promise.resolve();
        }

        let pPedido = db
          .collection('pedidos')
          .doc(pedido.id)
          .set(pedido, { merge: true })
          .then(() => pedido);
        if (rootState.onlineComputadora) {
          return Promise.all([pPedido, pMesa]);
        } else {
          return [pedido];
        }
      })
      .catch((e) => console.log(e));
  },
  async crearAbono({ dispatch, state, rootState }, { pedido, desglose }) {
    const productosAbona = desglose.productos.filter((p) => p.cantidad > 0);
    var pedidoAbonar;
    const siguienteNumRest = state.siguienteNumRest;
    const siguienteNumRestDia = state.siguienteNumRestDia;
    const siguienteNumPedidoRestModoObj = state.siguienteNumRestDiaModo;
    const siguienteNumPedidoRestModo =
      !!siguienteNumPedidoRestModoObj &&
      !!siguienteNumPedidoRestModoObj[pedido.modoId]
        ? siguienteNumPedidoRestModoObj[pedido.modoId]
        : null;
    dispatch('numPedidoRest', { modo: pedido.modoId });
    var datosContable = {
      ...desglose,
      productos: productosAbona,
    };
    delete datosContable.programado;
    const factSimplificadaAux = {
      pedido:
        pedido.negocio + '-' + pedido.restaurante + '-tpv-' + siguienteNumRest,
      moment: moment().unix(),
      abonoContableQueAbona: datosContable.codigoFactura,
      abonoPedidoQueAbona: pedido.id,
      serie: 'abono',
      ...datosContable,
    };
    let codigoFactura = await dispatch(
      'contable/crearApunteContable',
      factSimplificadaAux,
      { root: true }
    );
    let f = rootState.datosTpv.modos.find((m) => m.id === pedido.modoId);

    var siguienteNumPedidoRestModoMostrar =
      !!f && !!f.numPedidoRestModoSerie
        ? f.numPedidoRestModoSerie +
          siguienteNumPedidoRestModo.toString().padStart(2, '0')
        : siguienteNumPedidoRestModo;
    pedidoAbonar = {
      ...pedido,
      impuestosTotal: -redondeo(desglose.impuestosTotalesPago),
      cobrado: moment().unix(),
      numPedidoRest: siguienteNumRest,
      numPedidoRestDia: siguienteNumRestDia,
      numPedidoRestDiaModo: siguienteNumPedidoRestModo,
      numPedidoRestDiaModoMostrar: siguienteNumPedidoRestModoMostrar,
      precioTotal: -desglose.pagado,
      zcierre: rootState.zcierre,
      xcierre: rootState.xcierre,
      productos: productosAbona.map((p) => {
        let precioProductoTotal = -(p.precioProductoTotalUnitario * p.cantidad);
        let baseImponible = redondeo(precioProductoTotal / (p.impuesto + 1));
        let impuestoTotal = precioProductoTotal - baseImponible;
        return {
          ...p,
          cantidad: p.cantidad,
          impuesto: p.impuesto,
          impuestoCantidad: -p.impuestoCantidad,
          impuestoTotal: impuestoTotal,
          precioProductoTotal: precioProductoTotal,
          precioProductoTotalUnitario: -p.precioProductoTotalUnitario,
          precioProductoTotalUnitarioPersonalizado:
            p.precioProductoTotalUnitarioPersonalizado
              ? -p.precioProductoTotalUnitarioPersonalizado
              : 0,
          cantidad: p.cantidad,
          opcionesPrecio: p.opcionesPrecio.map((op) => -op),
        };
      }),

      fechaInicio: new Date(),
      fechaServidor: firebase.firestore.Timestamp.now(),
      abonoCreado: moment().unix(),
      abonoPedidoOriginal: pedido.id,
      abonoPedidoOriginalCopy: {
        id: pedido.id,
        numPedidoRestDia: pedido.numPedidoRestDia,
        numPedidoRest: pedido.numPedidoRest,
        numPedidoRestDiaModo: pedido.numPedidoRestDiaModo,
        numPedidoRestDiaModoMostrar: pedido.numPedidoRestDiaModoMostrar,
      },
      abonoPedidoQueAbona: null,
      abonoCodigoFactura: desglose.codigoFactura,
      id:
        pedido.negocio + '-' + pedido.restaurante + '-tpv-' + siguienteNumRest,
      cobradoDesglose: [
        {
          ...desglose,
          pagado: -desglose.pagado,
          // baseImponible: -desglose.baseImponiblePago,
          impuestosTotalesPago: -desglose.impuestosTotalesPago,
          baseImponiblePago: -desglose.baseImponiblePago,
          impuestosDesglosePago: desglose.impuestosDesglosePago.map((i) => {
            return {
              ...i,
              cantidad: -i.cantidad,
            };
          }),
          codigoFactura: codigoFactura,
          esAbono: true,
          productos: desglose.productos.map((p) => {
            return {
              ...p,
              cantidad: p.cantidad,
              impuesto: p.impuesto,
              precioProductoTotalUnitario: -p.precioProductoTotalUnitario,
              precioProductoTotalUnitarioPersonalizado:
                p.precioProductoTotalUnitarioPersonalizado
                  ? -p.precioProductoTotalUnitarioPersonalizado
                  : 0,
              opcionesPrecio: p.opcionesPrecio.map((op) => -op),
            };
          }),
        },
      ],
    };
    delete pedidoAbonar.abonoCreado;
    delete pedidoAbonar.abonoPedidoQueAbona;
    delete pedidoAbonar.abonoPedidoQueAbonaCopy; //si vieene del originiaal see borran esoos datos

    return db
      .collection('pedidos')
      .doc(pedidoAbonar.id)
      .set(pedidoAbonar, { merge: true })
      .then(() => {
        if (state.imprimirDespuesCobrar)
          return dispatch('imprimirFacturaSimplificada', {
            pedido: pedidoAbonar,
            factSimplificada: pedidoAbonar.cobradoDesglose[0],
          });
      })
      .then(() => pedidoAbonar);
  },
  async setPedidoObj({ state, getters, commit }, idpedido) {
    //toma un id de pedido y hace la mutación para el id y el objeto
    commit('setPedidoEditando', idpedido);
    let f = getters.pedidos.find((p) => {
      return p.id == state.pedidoEditando;
    });
    if (!f && idpedido) {
      await db
        .collection('pedidos')
        .doc(idpedido)
        .get()
        .then((r) => {
          if (r.exists) {
            f = {
              ...r.data(),
              id: r.id,
            };
          }
        });
    }
    commit('setPedidoEditandoObj', f ? f : {});
  },
  savePedido({ commit, dispatch, rootGetters, rootState }, pedido) {
    commit('setGrabandoPedido', true);
    commit('setPedidoEditandoFlag', pedido.id);
    //dispatch('calcularCierre', null, { root: true })

    let cuenta = rootGetters['cuenta'];

    if (rootState.onlineComputadora) {
      return Promise.all([
        db
          .collection('pedidos')
          .doc(pedido.id)
          .set({ ...pedido, ultimoGuardadoCuenta: cuenta.id }, { merge: true }),
      ]).finally(() => commit('setGrabandoPedido', false));
    } else {
      db.collection('pedidos')
        .doc(pedido.id)
        .set({ ...pedido, ultimoGuardadoCuenta: cuenta.id }, { merge: true });
      commit('setGrabandoPedido', false);
    }
  },
  getNumPedidoRest({ rootState, commit }) {
    function generarObjModo() {
      var res = {};
      rootState.datosTpv.modos.forEach((m) => {
        res[m.id] = 1;
      });

      return res;
    }
    var data = {};
    let fecha = moment().format('YYYY-MM-DD HH:mm:ss');
    // data.fecha = fecha;
    let fechaS = rootState.zcierre
      ? rootState.zcierre.toString()
      : moment().format('YYYY-MM-DD');
    var restPedidos = db
      .collection('restaurantes')
      .doc(rootState.restauranteObj.id)
      .collection('pedidos');
    var numPedidoRestDia;
    var numPedidoRestDiaModo;
    var numPedidoRest;

    if (state.siguienteNumRestDiaListener) state.siguienteNumRestDiaListener();
    commit(
      'setSiguienteNumRestDiaListener',
      restPedidos.doc(fechaS).onSnapshot((r) => {
        if (r.exists) {
          let datosGeneralDia = r.data();
          numPedidoRestDia = datosGeneralDia.numPedidoRestDia + 1;
          numPedidoRestDiaModo = datosGeneralDia.numPedidoRestDiaModo
            ? datosGeneralDia.numPedidoRestDiaModo
            : generarObjModo();
        } else {
          numPedidoRestDia = 1;
          numPedidoRestDiaModo = generarObjModo();
        }
        commit('setSiguienteNumRestDia', numPedidoRestDia);
        commit('setSiguienteNumRestDiaModo', numPedidoRestDiaModo);
      })
    );
    if (state.siguienteNumRestListener) state.siguienteNumRestListener();
    commit(
      'setSiguienteNumRestListener',
      restPedidos.doc('datosGeneralesTpv').onSnapshot((r) => {
        var numPedidoRest;
        if (r.exists) {
          let datosGeneral = r.data();
          numPedidoRest = datosGeneral.numPedidoRest + 1;
        } else {
          data.numPedidoRest = 1;
        }
        commit('setSiguienteNumRest', numPedidoRest);
      })
    );
  },
  numPedidoRest({ rootState, commit }, { modo }) {
    var data = {};
    let fecha = moment().format('YYYY-MM-DD HH:mm:ss');
    // data.fecha = fecha;
    let fechaS = rootState.zcierre
      ? rootState.zcierre.toString()
      : moment().format('YYYY-MM-DD');
    var restPedidos = db
      .collection('restaurantes')
      .doc(rootState.restauranteObj.id)
      .collection('pedidos');
    var numPedidoRestDia;
    var numPedidoRest;
    var numPedidoRestDiaModo;

    function generarObjModo() {
      var res = {};
      rootState.datosTpv.modos.forEach((m) => {
        res[m.id] = m.id == modo ? 2 : 1;
      });
      return res;
    }

    return Promise.all([
      restPedidos
        .doc(fechaS)
        .get()
        .then((r) => {
          if (r.exists) {
            let datosGeneralDia = r.data();
            numPedidoRestDia = datosGeneralDia.numPedidoRestDia + 1;
            numPedidoRestDiaModo = datosGeneralDia.numPedidoRestDiaModo
              ? datosGeneralDia.numPedidoRestDiaModo
              : generarObjModo();
            if (!!modo && !!numPedidoRestDiaModo[modo]) {
              numPedidoRestDiaModo[modo] = numPedidoRestDiaModo[modo] + 1;
            }
            /*return*/ restPedidos
              .doc(fechaS)
              .set(
                {
                  numPedidoRestDia: numPedidoRestDia,
                  numPedidoRestDiaModo: numPedidoRestDiaModo,
                },
                { merge: true }
              )
              .then(() => {
                data.numPedidoRestDia = numPedidoRestDia;
                return data;
              });
          } else {
            var ref = r.ref;
            var numPedidoRestDiaModo = generarObjModo();
            /*return*/ ref
              .set(
                {
                  numPedidoRestDia: 1,
                  numPedidoRestDiaModo: numPedidoRestDiaModo,
                },
                { merge: true }
              )
              .then(() => {
                data.numPedidoRestDia = 1;
                data.numPedidoRestDiaModo = numPedidoRestDiaModo;
                return data;
              });
          }
        }),
      restPedidos
        .doc('datosGeneralesTpv')
        .get()
        .then((r) => {
          var res;
          var numPedidoRest;
          if (r.exists) {
            let datosGeneral = r.data();
            numPedidoRest = datosGeneral.numPedidoRest + 1;
            res = restPedidos
              .doc('datosGeneralesTpv')
              .set({ numPedidoRest: numPedidoRest }, { merge: true })
              .then(() => {
                data.numPedidoRest = numPedidoRest;
                return data;
              });
            if (rootState.onlineComputadora) {
              return res;
            }
          } else {
            var ref = r.ref;
            res = ref.set({ numPedidoRest: 1 }, { merge: true }).then(() => {
              data.numPedidoRest = 1;
              return data;
            });
            if (rootState.onlineComputadora) {
              return res;
            }
          }
        }),
    ]).then((r) => {
      //commit('setSiguienteNumRestDia', data.numPedidoRestDia)
      //commit('setSiguienteNumRest', data.numPedidoRest)
      return data;
    });
  },
  getPedido({}, id) {
    return db
      .collection('pedidos')
      .doc(id)
      .get()
      .then((r) => {
        if (r.exists) {
          return {
            ...r.data(),
            id: r.id,
          };
        } else {
          return Promise.reject('no encontrado');
        }
      });
  },
  solicitarEliminacion({ rootState }, item) {
    let usuario = rootState.auth.user.email
      ? rootState.auth.user.email
      : rootState.auth.user.uid;
    var res = db
      .collection('pedidos')
      .doc(item.id)
      .set(
        {
          solicitarEliminado: true,
          historial: firebase.firestore.FieldValue.arrayUnion({
            fecha: moment().unix(),
            usuario: usuario,
            mensaje: 'Solicitado eliminar desde TPV ' + usuario,
          }),
        },
        { merge: true }
      );
    if (rootState.onlineComputadora) {
      return res;
    }
  },
  confirmarEliminarTpv({ rootState, dispatch }, item) {
    let usuario = rootState.auth.user.email
      ? rootState.auth.user.email
      : rootState.auth.user.uid;

    if (rootState.onlineComputadora) {
      return db
        .collection('pedidos')
        .doc(item.id)
        .set(
          {
            eliminado: true,
            cobrado: false,
            historial: firebase.firestore.FieldValue.arrayUnion({
              fecha: moment().unix(),
              usuario: usuario,
              mensaje: 'Eliminado desde TPV ' + usuario,
            }),
          },
          { merge: true }
        )
        .then(() => {
          if (item.modo == 'mesa' && !!item.mesa) {
            return dispatch('liberarMesa', item.mesa);
          }
        });
    } else {
      db.collection('pedidos')
        .doc(item.id)
        .set(
          {
            eliminado: true,
            cobrado: false,
            historial: firebase.firestore.FieldValue.arrayUnion({
              fecha: moment().unix(),
              usuario: usuario,
              mensaje: 'Eliminado desde TPV ' + usuario,
            }),
          },
          { merge: true }
        );
      if (item.modo == 'mesa' && !!item.mesa) {
        dispatch('liberarMesa', item.mesa);
      }

      return Promise.resolve();
    }
  },
  liberarMesa({ dispatch, rootState }, mesa) {
    var res = Promise.all([
      // db.collection('pedidos').doc(mesa.pedido).set({
      //   idmesa: null
      // }, { merge: true }),
      dispatch('grabarPedidoMesa', {
        mesa: mesa,
        pedido: null,
      }),
    ]);
    if (rootState.onlineComputadora) {
      return res;
    }
  },
  grabarPedidoMesa({ rootGetters, rootState }, payload) {
    var res = db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('tpv')
      .doc('secreto')
      .collection('mesas')
      .doc(payload.mesa)
      .set({ pedido: payload.pedido }, { merge: true });
    if (rootState.onlineComputadora) {
      return res;
    }
  },
  crearPedidoEnMesa({ commit }, payload) {
    commit('setPedidoEditandoObjValor', {
      key: 'modo',
      value: 'mesa',
    });
    commit('setPedidoEditandoObjValor', {
      key: 'mesa',
      value: payload.mesa.id,
    });
    commit('setPedidoEditandoObjValor', {
      key: 'copyMesa',
      value: payload.mesa,
    });
    commit('setPedidoEditandoObjValor', {
      key: 'comensales',
      value: payload.comensales,
    });
    commit('setModoPantalla', 'pedidoCarrito');
    commit('setPedidoDialog', true);
  },
  generarFactura({ commit, state }, payload) {
    var fact = functions.httpsCallable('httpTpvGenerarFactura');
    return fact(payload).then((r) => {
      let historialAdd = [
        ...state.pedidoEditandoObj.historial,
        {
          fecha: moment().unix(),
          mensaje: 'Factura generada',
        },
      ];
      commit('setPedidoEditandoObjValor', {
        key: 'historial',
        value: historialAdd,
      });
    });
  },
  ultimopedidoMostrarCobrar({ getters }) {
    let p = getters.pedidos
      .filter((p) => !!p.cobrado)
      .sort((a, b) => moment(b.cobrado) - moment(a.cobrado));
    return Array.isArray(p) && p.length > 0 ? p[0].id : null;
  },

  //dato un pedido devuelve un carrito construido con menús y productos en condiciones
  carritoArregladoMenu({ rootGetters }, pedidoRecorrer) {
    if (Array.isArray(pedidoRecorrer.productos)) {
      var productosFinales = pedidoRecorrer.productos
        .filter((p) => p.cantidad > 0 || !!pedidoRecorrer.abonoPedidoOriginal) // o tiene más de un producto o es un abono
        .map((p) => {
          let opcionesFinal = false;
          if (Array.isArray(p.opcionesName)) {
            let res = [];
            let arrayRecorrer = Array.isArray(p.opcionesNombreEs)
              ? p.opcionesNombreEs
              : p.opcionesName;
            arrayRecorrer.forEach((op) => {
              var index = res.findIndex((o) => o.key == op);
              if (index < 0) {
                res.push({
                  key: op,
                  cantidad: 1,
                });
              } else {
                res[index].cantidad++;
              }
            });
            opcionesFinal = res;
          }
          var coincide = false;

          //agrupamos productos menú

          return {
            ...p,
            precioTotalMostrar: p.precioProductoTotal
              ? mostrarMoneda(p.precioProductoTotal)
              : 0,
            precioProductoTotalUnitarioMostrar: p.precioProductoTotalUnitario
              ? mostrarMoneda(p.precioProductoTotalUnitario)
              : 0,
            cantidad: redondeo(p.cantidad),
            opcionesMostrar: opcionesFinal ? opcionesFinal : [],
            editando: !!coincide,
          };
        })
        .reverse();

      //agrupamos menu
      var productosMenu = [];
      var productosMenuTipo = {
        menuId: '',
        menuIdConcreto: '',
        nombre: '',
        precioProductoTotal: 0,
        precioProductoTotalUnitario: 0,
        productos: [],
      };

      productosFinales
        .filter((p) => !!p.menuId)
        .forEach((menu) => {
          let fI = productosMenu.findIndex(
            (m) => m.menuIdConcreto === menu.menuIdConcreto
          );
          if (fI > -1) {
            productosMenu[fI].productos.push(menu);
          } else {
            productosMenu.push({
              menuId: menu.menuId,
              menuIdConcreto: menu.menuIdConcreto,
              menuIdGrupo: menu.menuIdGrupo ? menu.menuIdGrupo : null,
              nombre: menu.menuCopy.nombre,
              precioProductoTotal: menu.menuCopy.precio,
              precioProductoTotalUnitario: menu.menuCopy.precio,
              precioTotalMostrar: mostrarMoneda(menu.menuCopy.precio),
              precioProductoTotalUnitarioMostrar: mostrarMoneda(
                menu.menuCopy.precio
              ),
              productos: [menu],
              menuCopy: menu.menuCopy,
              opciones: [],
            });
          }
        });
      return [
        ...productosMenu.map((menu) => {
          // console.log(this.pedidoEditandoObj.menus[0].idconcreto);

          let f = pedidoRecorrer.menus
            ? pedidoRecorrer.menus.find(
                (m) => m.idconcreto == menu.menuIdConcreto
              )
            : false;

          let fMenuMontado = rootGetters['productos/MENUS_MONTADOS'].find(
            (m) => m.id == menu.menuId
          );

          let gruposMontados = fMenuMontado.grupos.map((g) => {
            let fGrupo = menu.productos.find((p) => p.menuIdGrupo == g.id);
            return {
              ...g,
              eleccion: fGrupo
                ? { id: fGrupo.id, nombre: fGrupo.nombre }
                : null,
            };
          });
          let productos = menu.productos
            .map((p) => {
              return {
                ...p,
                orden: gruposMontados.findIndex((g) => g.id == p.menuIdGrupo),
              };
            })
            .sort((a, b) => a.orden - b.orden);
          return {
            ...menu,
            ...f,
            productosMostrar: productos.map(
              (p) => p.cantidad + 'x ' + p.nombreTpv
            ),
            precioTotalMostrar:
              f && f.precioProductoTotal
                ? mostrarMoneda(f.precioProductoTotal)
                : 0,
            precioProductoTotalUnitarioMostrar:
              f && f.precioProductoTotalUnitario
                ? mostrarMoneda(f.precioProductoTotalUnitario)
                : 0,
            cantidad: 1,
            esMenu: true,
            grupos: gruposMontados,
            menuCompleto: gruposMontados.filter((e) => !e.eleccion).length == 0,
          };
        }),
        ...productosFinales.filter((p) => !p.menuId),
      ];
    }
  },
  cobrarPedidoSinProductos({ state, commit }) {
    commit('setCobrarDialog', true);
    commit('setCobrarSinProductos', true);
    const siguienteNumRest = state.siguienteNumRest;
    const siguienteNumRestDia = state.siguienteNumRestDia;
    const siguienteNumPedidoRestModoObj = state.siguienteNumRestDiaModo;
    const siguienteNumPedidoRestModo =
      !!siguienteNumPedidoRestModoObj &&
      !!siguienteNumPedidoRestModoObj[pedido.modoId]
        ? siguienteNumPedidoRestModoObj[pedido.modoId]
        : null;
    commit('setCobrarSinProductosObj', {});
  },

  generarQrFactura({ rootState }, { pedido, objCobrado }) {
    const token = generateToken(14);
    const general = !rootState.configuracionTpv.generarFacturaQrClienteDominio;
    const enlace =
      'https://' +
      (!general
        ? rootState.configuracionTpv.generarFacturaQrClienteDominio
        : 'factura.funeats.app') +
      '/consigue-tu-factura?pedido=' +
      pedido +
      '&token=' +
      token +
      '&codigoFactura=' +
      objCobrado.codigoFactura +
      (general ? '&general=1' : '');
    return db
      .collection('pedidosQrFacturaToken')
      .add({
        pedido: pedido,
        restaurante: rootState.restauranteObj.id,
        token: token,
        estado: 'pendiente',
        moment: moment().unix(),
        momentFin: moment().add(1, 'month').unix(),
        objCobrado: objCobrado,
        enlace: enlace,
      })
      .then(() => enlace);
  },

  buscarPedidoPorId({ commit }, pedido) {
    return db
      .collection('pedidos')
      .doc(pedido)
      .get()
      .then((r) => {
        if (r.exists) {
          commit('SET_BUSCAR_PEDIDO_OBJ', {
            ...r.data(),
            id: r.id,
          });
        } else {
          commit('SET_BUSCAR_PEDIDO_OBJ', false);
        }
      })
      .catch((e) => {
        commit('SET_BUSCAR_PEDIDO_OBJ', false);
      });
  },
};
