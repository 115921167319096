<template>
  <v-hover v-slot:default="{ hover }">
    <v-card
      :disabled="disabled"
      @click="$emit('accion')"
      :rounded="!!esMenu"
      elevation="4"
      class="rounded-lg ficha-generica"
      :class="shake ? 'constant-tilt-shake' : ''"
      :style="hover
        ? 'cursor: pointer; border-color: #02fafd !important'
        : 'border-color: #56a7a8 !important'
        "
      :color="color
        ? color
        : !!seleccionado
          ? 'primary'
          : esMenuSelect
            ? 'orange  lighten-4'
            : esMenuGrupo
              ? 'blue lighten-5'
              : ''
        "
    >
      <v-chip
        color="purple"
        :x-small="!botonGrande"
        class="custom-badge stock-label elevation-3 purple font-weight-bold white--text"
        v-if="!!stock"
        label
        :class="botonGrande ? 'text-h5' : 'caption'"
      >
        {{ stock }}
      </v-chip>
      <v-chip
        :x-small="!botonGrande"
        class="custom-badge producto-badge elevation-3 info font-weight-bold white--text"
        v-if="!!cantidad"
        :class="botonGrande ? 'text-h5' : 'caption'"
      >
        {{ cantidad }}x
      </v-chip>
      <v-chip
        :x-small="!botonGrande"
        class="custom-badge producto-menu elevation-3 orange lighten-1 font-weight-bold white--text"
        :class="botonGrande ? 'text-h5' : 'caption'"
        v-if="!!cantidadMenu"
      >
        {{ cantidadMenu }}x
      </v-chip>

      <v-chip
        label
        :x-small="!botonGrande"
        class="custom-badge producto-price elevation-3 font-weight-bold black--text d-flex justify-center"
        v-if="!!precio"
        :class="!!seleccionado ? 'white' : 'primary'"
      >
        {{ precio }}
      </v-chip>

      <v-responsive
        :aspect-ratio="1.618"
        class="align-center"
      >
        <v-card-text
          class="text-uppercase font-weight-black text-center px-1"
          :class="botonGrande
            ? $vuetify.breakpoint.mdAndDown
              ? 'text-h6'
              : 'text-h5'
            : $vuetify.breakpoint.smAndDown || !!muchasOpciones
              ? 'caption'
              : ''
            "
          :style="$vuetify.breakpoint.smAndDown || !!muchasOpciones
            ? 'line-height:1;'
            : 'line-height:1.2;'
            "
        >
          <v-row
            class=""
            no-gutters
          >
            <v-col
              :style="$vuetify.breakpoint.mdAndDown
                ? 'max-height: 48px;'
                : $vuetify.breakpoint.lgAndDown
                  ? 'max-height: 56px;'
                  : 'max-height: 120px;'
                "
              v-line-clamp="$vuetify.breakpoint.lgAndDown ? 3 : 7"
              style="word-break: normal !important;user-select: none"
            >
              {{ texto }}
            </v-col>
          </v-row>
          <div
            class="opciones-bg"
            v-if="!!tieneOpciones || !!esMenu"
          >
            <v-icon
              color="orange lighten-2"
              v-if="!!esMenu"
            >mdi-silverware-variant</v-icon>
            <v-icon
              color="indigo accent-3"
              v-else-if="!!tieneOpciones"
            >mdi-google-circles</v-icon>
          </div>
        </v-card-text>
      </v-responsive>
    </v-card>
  </v-hover>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    shake: {
      required: false,
      default: false,
      type: Boolean,
    },
    color: {
      required: false,
      type: String,
    },
    precio: {
      required: false,
    },
    cantidad: {
      required: false,
    },
    cantidadMenu: {
      required: false,
    },
    stock: {
      required: false,
    },
    texto: {
      type: String,
      required: true,
    },
    esMenu: {
      required: false,
    },
    esMenuGrupo: {
      required: false,
    },
    esMenuSelect: {
      required: false,
    },
    tieneOpciones: {
      required: false,
    },
    muchasOpciones: {
      required: false,
      default: false,
    },
    seleccionado: {
      required: false,
    },
    disabled: {
      require: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters(["cuenta", "botonGrande"]),
  },
};
</script>

<style lang="scss" scoped>
.opciones-bg {
  position: absolute;
  top: 0;
  right: 0;
}

.constant-tilt-shake {
  animation: tilt-shaking .3s infinite;
}

@keyframes tilt-shaking {
  0% {
    transform: rotate(0deg);
  }

  25% {
    transform: rotate(3deg);
  }

  50% {
    transform: rotate(0eg);
  }

  75% {
    transform: rotate(-3deg);
  }

  100% {
    transform: rotate(0deg);
  }
}
</style>
