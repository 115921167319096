<template>
  <div
    :class="keyboardClass"
    ref="keyboardFooter"
  ></div>
</template>

<script>
import Keyboard from 'simple-keyboard';
import 'simple-keyboard/build/css/index.css';
import { mapState } from 'vuex';

export default {
  name: 'keyboardNumerico',
  props: {
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    keyboardClass: {
      default: 'keyboard-numerico',
      type: String,
    },
    input: {
      type: String,
    },
    inputName: {
      type: String,
    },
    noDividir: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    keyboard: null,
  }),
  watch: {
    input(input) {
      this.keyboard.setInput(input);
    },
    desactivarDividir(v) {

      if (v) {
        this.keyboard.addButtonTheme(' /2 /3 / ', 'disabled');
      } else {
        this.keyboard.removeButtonTheme(' /2 /3 /', 'd-none');
      }
    },
    limpiarInput(v) {
      if (v) {
        this.reset();
      } else {
        this.$store.commit('teclado/setLimpiarInput', false);
      }
    },
    vaDividir(v) {
      if (v) {
        this.keyboard.addButtonTheme(' /2 /3 / C 0', 'd-none');
      } else {
        this.keyboard.removeButtonTheme(' /2 /3 / C 0', 'd-none');
      }
    },

    variasFormasPago(v) {
      if (v) {
        this.keyboard.addButtonTheme(' /2 /3 / ', 'd-none');
      } else {
        this.keyboard.removeButtonTheme(' /2 /3 /', 'd-none');
      }
    },
    descuentoGestionando(v) {
      if (v) {
        this.keyboard.addButtonTheme(' /2 /3 / ', 'd-none');
      } else {
        this.keyboard.removeButtonTheme(' /2 /3 /', 'd-none');
      }
    },
    'pedidoEditandoObj.cobrado': (v) => { },
  },
  computed: {
    limpiarInput() {
      return this.$store.state.teclado.limpiarInput;
    },
    ...mapState({
      pedidoEditandoObj: (state) => state.pedidos.pedidoEditandoObj,

      vaDividir: (state) => state.teclado.vaDividir,
      desactivarDividir: (state) => state.teclado.desactivarDividir,
      variasFormasPago: (state) => state.teclado.variasFormasPago,
      descuentoGestionando: (state) => state.teclado.descuentoGestionando,
      cobroPorProductos: (state) => state.teclado.cobroPorProductos,
    }),
  },

  methods: {
    reset() {
      if (this.keyboard) this.keyboard.setInput('');
    },
    onChange(input) {
      if (this.disabled) return;

      this.$emit('onChange', input);
    },
    onKeyPress(button) {
      if (this.disabled) return;
      if (
        this.vaDividir &&
        (button == 'C' || button == '/2' || button == '/3' || button == '/')
      ) {
      } else {
        this.$emit('onKeyPress', button);
      }

      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === '{shift}' || button === '{lock}') this.handleShift();
    },
    handleShift() {
      let currentLayout = this.keyboard.options.layoutName;
      let shiftToggle = currentLayout === 'default' ? 'shift' : 'default';

      this.keyboard.setOptions({
        layoutName: shiftToggle,
      });
    },
  },
  mounted() {
    let layout = this.noDividir
      ? ['1 2 3', '4 5 6', '7 8 9', 'C 0 {bksp}']
      : ['/2 /3 /', '1 2 3', '4 5 6', '7 8 9', 'C 0 {bksp}'];
    this.keyboard = null;
    setTimeout(() => {
      this.keyboard = new Keyboard(this.keyboardClass, {
        onChange: this.onChange,
        onKeyPress: this.onKeyPress,
        physicalKeyboardHighlightBgColor: '#9ab4d0',
        physicalKeyboardHighlightPress: true,
        layout: {
          default: layout,
          //shift: ["! / #", "$ % ^", "& * (", "{shift} ) +", "{bksp}"]
        },
        display: {
          '{bksp}': '⬅︎',
        },
        theme: 'hg-theme-default hg-layout-numeric numeric-theme',
        buttonTheme: [
          {
            class: 'rounded-lg font-weight-bold elevation-1 text-h5',
            buttons: ' C /2 /3 / 1 2 3 * 4 5 6 - 7 8 9 + , 0 = ⬅︎ {bksp}',
          },
          {
            class: 'warning white--text',
            buttons: 'C ⬅︎ {bksp}',
          },
          {
            class: 'blue-grey lighten-4',
            buttons: ' /2 /3 / * - + , =',
          },
        ],
      });
    }, 10);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.keyboard-numerico {
  overflow: visible;
  padding: 0;
}

.nuevo-producto-dialogo {
  .keyboard-numerico {
    &.hg-theme-default.hg-layout-numeric {
      .hg-button {
        height: 42px;
      }
    }
  }
}

.hg-theme-default {
  padding: 2px !important;
  background-color: #ececec00;
}
</style>
