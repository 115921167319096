export default {
  SET_PRODUCTOS(state, a) {
    state.productos = a;
  },
  SET_MENUS(state, a) {
    state.menus = a;
  },
  SET_IMPUESTOS(state, i) {
    state.impuestos = i;
  },
  SET_PRODUCTOS_DIALOG(state, b) {
    state.productosDialog = b;
  },
  SET_MODIFICADORES(state, a) {
    state.modificadores = a;
  },
  SET_GRUPOS_MODIFICADORES(state, a) {
    state.gruposModificadores = a;
  },
  SET_MODIFICADORES_OPCIONES(state, a) {
    state.modificadoresOpciones = a;
  },
  SET_TARIFAS(state, a) {
    state.tarifas = a;
  },

  setProductosArray(state, p) {
    state.productosArray = p;
  },
  setCategorias(state, c) {
    state.categorias = c;
  },
  setCategoriaPidiendo(state, c) {
    state.categoriaPidiendo = c;
  },
  setProductoEditandoCarrito(state, c) {
    state.productoEditandoCarrito = c;
  },
  setPidiendoDialog(state, b) {
    state.pidiendoDialog = b;
  },
  setProductosPidiendo(state, p) {
    //array
    state.productosPidiendo = p;
  },
  setProductoPidiendo(state, p) {
    if (!state.productoPidiendo || state.productoPidiendo.id !== p.id) {
      state.productoPidiendoOpciones = {
        ids: [],
        nombres: [],
        nombresTpv: [],
        precios: [],
        incompatibilidades: [],
      };
    }
    state.productoPidiendo = p;
  },
  setProductoPidiendoMenu(state, p) {
    state.productoPidiendoMenu = p;
  },
  setProductoPidiendoMenuDialog(state, p) {
    state.productoPidiendoMenuDialog = p;
  },
  setProductoPidiendoOpciones(state, ops) {
    state.productoPidiendoOpciones = ops;
  },
  setPasoPidiendoProducto(state, i) {
    state.pasoPidiendoProducto = i;
  },
  setPasoPidiendoProductoOpciones(state, i) {
    state.pasoPidiendoProductoOpciones = i;
  },
  setOpcionesProductoDialogo(state, i) {
    state.opcionesProductoDialogo = i;
  },
  reiniciarProductosPidiendo(state) {
    state.productosPidiendo = null;
    state.productoPidiendoOpciones = {
      ids: [],
      nombres: [],
      nombresTpv: [],
      precios: [],
      incompatibilidades: [],
    };
  },
  addRemoveProductoPidiendoOpcionUltra(state, opAdd) {
    let fI = state.productoPidiendoOpciones.ids.findIndex(
      (op) => op == opAdd.id
    );
    if (fI > -1 && opAdd.cantidad == -1) {
      state.productoPidiendoOpciones.ids.splice(fI, 1);
      state.productoPidiendoOpciones.nombres.splice(fI, 1);
      state.productoPidiendoOpciones.nombresTpv.splice(fI, 1);
      state.productoPidiendoOpciones.precios.splice(fI, 1);
      state.productoPidiendoOpciones.incompatibilidades.splice(fI, 1);
    } else {
      state.productoPidiendoOpciones.ids.push(opAdd.id);
      state.productoPidiendoOpciones.incompatibilidades.push(
        !!opAdd.incompatibilidades ? opAdd.incompatibilidades : []
      );
      state.productoPidiendoOpciones.nombres.push(opAdd.nombreMostrar);
      state.productoPidiendoOpciones.nombresTpv.push(opAdd.nombreTpv);
      state.productoPidiendoOpciones.precios.push(
        parseFloat(opAdd.precioCobrar)
      );
    }
  },
  addRemoveProductoPidiendoOpcion(state, opAdd) {
    let fI = state.productoPidiendoOpciones.ids.findIndex(
      (op) => op == opAdd.id
    );
    if (fI > -1) {
      state.productoPidiendoOpciones.ids.splice(fI, 1);
      state.productoPidiendoOpciones.nombres.splice(fI, 1);
      state.productoPidiendoOpciones.nombresTpv.splice(fI, 1);
      state.productoPidiendoOpciones.precios.splice(fI, 1);
      state.productoPidiendoOpciones.incompatibilidades.splice(fI, 1);
    } else {
      state.productoPidiendoOpciones.ids.push(opAdd.id);
      state.productoPidiendoOpciones.incompatibilidades.push(
        !!opAdd.incompatibilidades ? opAdd.incompatibilidades : []
      );
      state.productoPidiendoOpciones.nombres.push(opAdd.nombreMostrar);
      state.productoPidiendoOpciones.nombresTpv.push(opAdd.nombreTpv);
      state.productoPidiendoOpciones.precios.push(
        parseFloat(opAdd.precioCobrar)
      );
    }
  },
  removeProductoPidiendoOpcion(state, opRemove) {
    //elimina la opción si existe
    let fI = state.productoPidiendoOpciones.ids.findIndex(
      (op) => op == opRemove
    );
    if (fI > -1) {
      state.productoPidiendoOpciones.ids.splice(fI, 1);
      state.productoPidiendoOpciones.nombres.splice(fI, 1);
      state.productoPidiendoOpciones.nombresTpv.splice(fI, 1);
      state.productoPidiendoOpciones.precios.splice(fI, 1);
      state.productoPidiendoOpciones.incompatibilidades.splice(fI, 1);
    }
  },
  setProductosCatalogo(state, payload) {
    let findIndex = state.productosCatalogos.findIndex(
      (pc) => pc.catalogo == payload.catalogo
    );
    if (findIndex > -1) {
      state.productosCatalogos[findIndex].productos = payload.productos;
      state.productosCatalogos[findIndex].tarifa = payload.tarifa;
    } else {
      state.productosCatalogos.push({
        catalogo: payload.catalogo,
        productos: payload.productos,
        tarifa: payload.tarifa,
      });
    }
  },

  setProductosTarifaTodos(state, tarifas) {
    state.productosTarifa = tarifas;
  },
  setGenerarCatalogoSnackbar(state, b) {
    state.generarCatalogoSnackbar = b;
  },
  setGenerarCatalogoSegundos(state, i) {
    if (i >= 0) state.generarCatalogoSegundos = i;
  },
  setGenerarCatalogoTimeout(state, f) {
    state.generarCatalogoTimeout = f;
  },
  setGenerarCatalogoTimeoutContador(state, f) {
    state.generarCatalogoTimeoutContador = f;
  },
  setResultadosBusqueda(state, a) {
    state.resultadosBusqueda = a;
  },

  SET_EDITAR_PRODUCTO_SISTEMA_EDITANDO(state, b) {
    state.editarProductoSistemaEditando = b;
  },

  SET_PRODUCTOS_CACHEADO_TARIFA(state, payload) {
    let fI = state.productosCatalogoCacheado.findIndex(
      (t) => t.tarifa == payload.tarifa
    );
    if (fI > -1) {
      state.productosCatalogoCacheado[fI].productos = payload.productos;
    } else {
      state.productosCatalogoCacheado.push({
        tarifa: payload.tarifa,
        productos: payload.productos,
      });
    }
  },
  SET_PRODUCTOS_CACHEADO_TARIFA_TODO(state, a) {
    state.productosCatalogoCacheado = a;
  },
  SET_PRODUCTOS_SECRETO(state, a) {
    state.productosSecreto = a;
  },
};
