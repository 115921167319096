// https://vuex.vuejs.org/en/actions.html
import firebase from 'firebase';
import { db, storage } from '@/firebase';
import moment from 'moment';
import { key, resetLastTimestamp } from 'firebase-key';
import axios from 'axios';

import {
  zcierre,
  zcierreQz,
  abrirCaja,
  abrirCajaQz,
} from '../utils/imprimir.utils';
import { parDiasComparar } from '@/utils/dias.utils.js';
import tickets from '../utils/tickets';

export default {
  inicioTpvAlternativo({ commit, rootState, state, dispatch, rootGetters }) {
    var p1 = db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .onSnapshot((r) => {
        if (r.exists) {
          var rest = r.data();
          rest.id = r.id;
          const domicilio = rest.tiempoDomicilio ? rest.tiempoDomicilio : 0;
          const recogida = rest.tiempoRecogida ? rest.tiempoRecogida : 0;
          const modoHorarioRecoger = rest.modoHorarioRecoger
            ? rest.modoHorarioRecoger == 'auto'
            : false;
          const modoHorarioDomicilio = rest.modoHorarioDomicilio
            ? rest.modoHorarioDomicilio == 'auto'
            : false;
          const flag = state.restauranteObj == null; //para llamar a toda la info
          commit('setTiempoDomicilio', domicilio);
          commit('setTiempoRecogida', recogida);
          commit('setRecogidaActivada', modoHorarioRecoger);
          commit('setDomicilioActivado', modoHorarioDomicilio);
          commit('setRestauranteObj', rest);
          if (flag) {
            dispatch('getConfiguracionTpv');
            dispatch('getAccionesTpv');
            if (rootState.auth.user.role == 'tpv')
              dispatch(
                'calendario/getReservasSinConfirmar',
                {},
                { root: true }
              );
            setTimeout(() => {
              dispatch('pedidos/getPedidos', {}, { root: true });
              dispatch('pedidos/getPedidosRealizandoTpv', {}, { root: true });
              dispatch('pedidos/getNumPedidoRest');
            }, 2000);
          }
        } else {
          commit('setRestauranteObj', false); //no existe restaurante o no tiene
        }
      });

    var p2 = db
      .collection('negocios')
      .doc(rootState.auth.user.negocioTpv)
      .onSnapshot((n) => {
        if (n.exists) {
          var neg = n.data();
          neg.id = n.id;
          commit('setNegocioObj', neg);
        }
      });
    var mesasTpv = db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('tpv')
      .doc('secreto')
      .collection('mesas')
      .onSnapshot((r) => {
        // r.docs.map(d => {
        commit('setDatosTpv', {
          key: 'mesas',
          value:
            r.size > 0
              ? r.docs
                  .map((m) => {
                    return {
                      ...m.data(),
                      id: m.id,
                    };
                  })
                  .sort((a, b) =>
                    a.orden > b.orden ? 1 : b.orden > a.orden ? -1 : 0
                  )
              : [],
        });
        // })
      });
    var modosTpv = db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('tpv')
      .doc('secreto')
      .collection('modos')
      .get()
      .then((r) => {
        // r.docs.map(d => {
        commit('setDatosTpv', {
          key: 'modos',
          value:
            r.size > 0
              ? r.docs
                  .map((m) => {
                    return {
                      ...m.data(),
                      id: m.id,
                    };
                  })
                  .sort((a, b) =>
                    a.orden > b.orden ? 1 : b.orden > a.orden ? -1 : 0
                  )
              : [],
        });
        // })
      });
    var cuentasTpv = db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('tpv')
      .doc('secreto')
      .collection('cuentas')
      .get()
      .then((r) => {
        // r.docs.map(d => {
        commit('setDatosTpv', {
          key: 'cuentas',
          value:
            r.size > 0
              ? r.docs
                  .map((m) => {
                    return {
                      ...m.data(),
                      id: m.id,
                    };
                  })
                  .sort((a, b) =>
                    a.orden > b.orden ? 1 : b.orden > a.orden ? -1 : 0
                  )
              : [],
        });

        if (rootGetters['impresionLocal']) {
          setTimeout(() => {
            dispatch('pedidos/getPedidosPendienteImprimir', {}, { root: true });
          }, 4000);
        }
        db.collection('restaurantes')
          .doc(rootState.auth.user.restauranteTpv)
          .collection('tpv')
          .doc('secreto')
          .collection('cuentas')
          .doc(rootGetters['cuenta'].id)
          .set(
            {
              version: state.version,
            },
            { merge: true }
          );
        commit('setModoPaginacion', rootGetters['cuenta'].paginacion);
        commit('setNecesarioTeclado', rootGetters['cuenta'].tecladoAsistido);
        commit('setBajaCalidad', rootGetters['cuenta'].bajaCalidad);
        commit('setSizeBoton', rootGetters['cuenta'].sizeBoton);

        // })
      })
      .catch((e) => console.log(e));
    var dispositivosTpv = dispatch('dispositivosTpv');
    var datosTpv = db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('tpv')
      .get()
      .then((r) => {
        r.docs.map((d) => {
          commit('setDatosTpv', {
            key: d.id,
            value: d.data(),
          });
        });
        // commit('setDatosTpv', r.docs.map(d => {
        //   return {
        //     ...d.data(),
        //     id: d.id
        //   }
        // }))
      });
    var datosTpvUsuarios = db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('tpv')
      .doc('secreto')
      .collection('usuarios')
      .where('activo', '==', true)
      .get()
      .then((r) => {
        commit(
          'usuarios/setUsuarios',
          r.docs.map((d) => {
            return {
              ...d.data(),
              id: d.id,
            };
          }),
          { root: true }
        );

        //para recuperar el usuario. Ahora mismo desactivado
        let user = localStorage.getItem('usuarioTpv');
        if (!!user && !rootGetters['auth/isComandero']) {
          dispatch('usuarios/setUsuarioActual', user, { root: true });
        }
      });

    //obtenemos versión servidor
    db.collection('configuracion')
      .doc('tpv')
      .onSnapshot((r) => {
        if (r.exists) {
          commit('setConfiguracionTpvGeneral', r.data());
        }
      });
    var not = Promise.resolve();
    var almacenes = Promise.resolve();
    var almacenes = Promise.resolve();
    var imp = dispatch('getImpuestos');

    if (rootState.auth.user.role == 'tpv') {
      var not = dispatch('notificaciones/getNotificaciones');
      almacenes = db
        .collection('negocios')
        .doc(rootState.auth.user.negocioTpv)
        .collection('almacenes')
        .get()
        .then((r) => {
          commit(
            'SET_ALMACENES',
            r.docs.map((d) => {
              return {
                ...d.data(),
                id: d.id,
              };
            }),
            { root: true }
          );
        });
    }
    // var prod = dispatch('productos/getTodosCatalogos')
    // var cat = dispatch('productos/getCategorias')

    const todosRest = dispatch(
      'restaurantes/getRestaurantes',
      {},
      { root: true }
    );
    console.log(not);
    return Promise.all([
      p1,
      p2,
      not,
      imp,
      datosTpv,
      mesasTpv,
      modosTpv,
      datosTpvUsuarios,
      dispositivosTpv,
      cuentasTpv,
      // almacenes,
      todosRest,
    ])
      .then(() => {
        return;
      })
      .catch((e) => {
        console.log(e);
        commit('setRestauranteObj', false);
      });
  },
  getConfiguracionTpv({ rootState, commit, dispatch, rootGetters }) {
    db.collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('tpv')
      .doc('configuracion')
      .onSnapshot((n) => {
        if (n.exists) {
          var conf = n.data();

          commit('setConfiguracionTpv', conf);

          commit('setCashguardPosible', !!conf.cashguard);

          if (!!conf.cierreEnCurso && conf.zcierreObligatorio) {
            commit('setZCierre', conf.cierreEnCurso.toString());
            dispatch('getZCierre', conf.cierreEnCurso).then((r) => {
              if (!!r) {
                commit('setZCierreDatos', r);
                commit(
                  'setXCierre',
                  Array.isArray(r.cierresX) ? r.cierresX.length : 1
                );
                commit(
                  'setXCierreInicio',
                  r.cierreXEnCurso && r.cierreXEnCurso.inicio
                    ? r.cierreXEnCurso.inicio
                    : conf.cierreEnCurso.toString()
                );
              }
            });
          } else if (conf.cierreEnCurso === null) {
            commit('setZCierre', null);
          }

          if (conf.qz && rootGetters['auth/isTpv']) {
            dispatch('qz/conectar');
          }
        }
      });
  },
  getAccionesTpv({ rootState, rootGetters, state, commit, dispatch }) {
    db.collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('tpv')
      .doc('acciones')
      .onSnapshot(async (r) => {
        if (r.exists) {
          var acciones = r.data();

          if (acciones.reiniciar) {
            db.collection('restaurantes')
              .doc(rootState.auth.user.restauranteTpv)
              .collection('tpv')
              .doc('acciones')
              .set(
                {
                  reiniciar: false,
                },
                { merge: true }
              )
              .then(() => location.reload());
          } else if (acciones.obtenerImpresorasQz) {
            db.collection('restaurantes')
              .doc(rootState.auth.user.restauranteTpv)
              .collection('tpv')
              .doc('acciones')
              .set(
                {
                  obtenerImpresorasQz: false,
                },
                { merge: true }
              )
              .then(() => {
                return dispatch('qz/getPrinters');
              })
              .then((r) => {});
          } else if (acciones.obtenerProductos) {
            db.collection('restaurantes')
              .doc(rootState.auth.user.restauranteTpv)
              .collection('tpv')
              .doc('acciones')
              .set(
                {
                  obtenerProductos: false,
                },
                { merge: true }
              )
              .then(() => {
                return dispatch('productos/generarProductosCatalogos');
              })
              .then((r) => {});
          } else if (acciones.obtenerTickets) {
            db.collection('restaurantes')
              .doc(rootState.auth.user.restauranteTpv)
              .collection('tpv')
              .doc('acciones')
              .set(
                {
                  obtenerTickets: false,
                },
                { merge: true }
              )
              .then(() => {
                return dispatch('montarTickets');
              })
              .then((r) => {});
          }

          if (!!acciones.comprobarConexion) {
            if (acciones.comprobarConexion == rootState.auth.user.uid) {
              var userStatusDatabaseRef = firebase
                .database()
                .ref('/status/' + rootState.auth.user.uid);

              var isOnlineForDatabase = {
                uid: rootState.auth.user.uid,
                state: 'online',
                rest: rootGetters.cuenta.marcaConectado
                  ? state.restauranteObj.id
                  : null,
                last_changed: firebase.database.ServerValue.TIMESTAMP,
              };
              userStatusDatabaseRef.set(isOnlineForDatabase).then(() => {
                db.collection('restaurantes')
                  .doc(rootState.auth.user.restauranteTpv)
                  .collection('tpv')
                  .doc('acciones')
                  .set(
                    {
                      comprobarConexion: null,
                    },
                    { merge: true }
                  );
              });
            }
          }
        }
      });
  },
  getUniqueId({ commit }) {
    let id = localStorage.getItem('uniqueId');
    let nuevo = false;
    if (!id) {
      id = key();
      nuevo = true;
    }
    commit('setUniqueId', !!id ? id : null);
    return nuevo;
  },
  dispositivosTpv({ rootState, commit, getters, state, dispatch }) {
    db.collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('tpv')
      .doc('secreto')
      .collection('dispositivos')
      .get()
      .then((r) => {
        // r.docs.map(d => {
        var dispositivos =
          r.size > 0
            ? r.docs.map((m) => {
                return {
                  ...m.data(),
                  id: m.id,
                };
              })
            : [];
        commit('setDatosTpv', {
          key: 'dispositivosTpv',
          value: dispositivos,
        });
        dispatch('montarTickets');
      });
  },
  montarTickets({ getters, rootState, commit }) {
    var tickets = getters['tickets'];
    return db
      .collection('negocios')
      .doc(rootState.auth.user.negocioTpv)
      .collection('tickets')
      .get()
      .then((r) => {
        const a = r.docs.map((doc) => {
          var aux = doc.data();
          aux.id = doc.id;
          return aux;
        });

        commit('setDatosTpv', {
          key: 'tickets',
          value: a
            .filter((t) => {
              return tickets.map((t) => t.ticket).includes(t.id);
            })
            .map((t) => {
              let fTicket = tickets.filter((tAux) => tAux.ticket == t.id);

              return fTicket.map((tk) => {
                return {
                  ...tk,
                  ...t,
                };
              });
            })
            .flat(),
        });
        //descargamoos los logos caja si hay
        function getBase64Image(img) {
          var canvas = document.createElement('canvas');
          img.width = 480;
          img.height = 240;
          canvas.width = img.width;
          canvas.height = img.height;
          var ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, img.width, img.height);
          var dataURL = canvas.toDataURL();
          return dataURL.replace(/^data:image\/(png|jpg);base64,/, '');
        }

        var negocio = rootState.auth.user.negocioTpv;

        let logosDescargar = a
          .filter(
            (t) =>
              (!!t.logo && !Array.isArray(t.logo)) ||
              (Array.isArray(t.logo) && t.logo.length > 0)
          )
          .map((t) => {
            let img = new Image();
            img.crossOrigin =
              'anonymous'; /* THIS WILL MAKE THE IMAGE CROSS-ORIGIN */

            img.addEventListener('load', () => {
              commit('impresion/pushLogosCaja', {
                ticket: t.id,
                datos: getBase64Image(img),
              });
            });
            let logoGuardar = t.logo;
            if (Array.isArray(t.logo) && t.logo.length > 0) {
              logoGuardar = t.logo[0].url;
            }
            img.src = logoGuardar;
          });
      });
  },
  cargaDespuesInicio({ dispatch, rootState }) {
    var p = [];
    if (rootState.auth.user.role == 'tpv') {
      p.push([
        dispatch('internos/getCategorias', {}, { root: true }),
        dispatch('internos/getProductos', {}, { root: true }),
        dispatch('directorio/getDirectorio', {}, { root: true }),
      ]);
    }
    return Promise.all(p)
      .then(() => {
        dispatch('cargaLazy').catch((e) => console.log(e));
        if (
          rootState.directorio.directorioMatriz &&
          rootState.auth.user.role == 'tpv'
        )
          dispatch('pedidos/getPedidosDirectorioMatriz', {}, { root: true });
      })
      .catch((e) => {
        console.log(e);
      });
  },
  cargaLazy({ commit, dispatch, state }) {
    //carga datos lazy por que son datos pesados, muestra en la tabla loading
    commit('setCargandoLazySnack', true);
    // dispatch('clientes/getClientes').then((r) => console.log(r)).catch(e => console.log(e))
    return Promise.all(
      state.cargaLazy.map((c) => {
        c.loading = true;
        return dispatch(c.accion)
          .then(() => (c.loading = false))
          .catch((e) => console.log(e));
      })
    ).finally(() => commit('setCargandoLazySnack', false));
  },
  inicioTpv({ commit }) {
    var f = firebase.functions().httpsCallable('httpTpvCargaInicial');
    return f().then((r) => {
      const rest = r.data[0];
      const domicilio = rest.tiempoDomicilio ? rest.tiempoDomicilio : 0;
      const recogida = rest.tiempoRecogida ? rest.tiempoRecogida : 0;
      const modoHorarioRecoger = rest.modoHorarioRecoger
        ? rest.modoHorarioRecoger == 'auto'
        : false;
      const modoHorarioDomicilio = rest.modoHorarioDomicilio
        ? rest.modoHorarioDomicilio == 'auto'
        : false;
      commit('setRestauranteObj', r.data[0]);
      commit('setNegocioObj', r.data[1]);
      commit('setTiempoDomicilio', domicilio);
      commit('setTiempoRecogida', recogida);
      commit('setRecogidaActivada', modoHorarioRecoger);
      commit('setDomicilioActivado', modoHorarioDomicilio);
    });
  },
  setTiempoDomicilio({ rootState, state, commit }, t) {
    if (rootState.directorio.directorioMatriz) {
      return Promise.all(
        rootState.directorio.directorioMatrizObj.restaurantes.map((r) => {
          return db
            .collection('restaurantes')
            .doc(r)
            .set(
              {
                tiempoDomicilio: t,
              },
              { merge: true }
            )
            .catch((e) => console.log(e));
        })
      ).then(() => {
        commit('setTiempoDomicilio', t);
      });
    } else {
      return db
        .collection('restaurantes')
        .doc(state.restauranteObj.id)
        .set(
          {
            tiempoDomicilio: t,
          },
          { merge: true }
        )
        .then(() => {
          commit('setTiempoDomicilio', t);
        });
    }
  },
  setTiempoRecogida({ state, commit, rootState }, t) {
    if (rootState.directorio.directorioMatriz) {
      return Promise.all(
        rootState.directorio.directorioMatrizObj.restaurantes.map((r) => {
          return db
            .collection('restaurantes')
            .doc(r)
            .set(
              {
                tiempoRecogida: t,
              },
              { merge: true }
            )
            .catch((e) => console.log(e));
        })
      ).then(() => {
        commit('setTiempoRecogida', t);
      });
    } else {
      return db
        .collection('restaurantes')
        .doc(state.restauranteObj.id)
        .set(
          {
            tiempoRecogida: t,
          },
          { merge: true }
        )
        .then(() => {
          commit('setTiempoRecogida', t);
        });
    }
  },
  setRecogidaActivada({ rootState, state, commit }, { v, t }) {
    let tiempo = t ? moment().unix() + t * 60 : null;
    const modoHorarioRecoger = v ? 'auto' : 'forzado_cierre';

    if (rootState.directorio.directorioMatriz) {
      return Promise.all(
        rootState.directorio.directorioMatrizObj.restaurantes.map((r) => {
          return db
            .collection('restaurantes')
            .doc(r)
            .set(
              {
                modoHorarioRecoger: modoHorarioRecoger,
                modoHorarioRecogerTiempo: tiempo,
              },
              { merge: true }
            )
            .catch((e) => console.log(e));
        })
      ).then(() => {
        commit('setRecogidaActivada', t);
      });
    } else {
      return db
        .collection('restaurantes')
        .doc(state.restauranteObj.id)
        .set(
          {
            modoHorarioRecoger: modoHorarioRecoger,
            modoHorarioRecogerTiempo: tiempo,
          },
          { merge: true }
        )
        .then(() => {
          commit('setRecogidaActivada', t);
        });
    }
  },
  setDomicilioActivado({ rootState, state, commit }, { v, t }) {
    let tiempo = t ? moment().unix() + t * 60 : null;
    const modoHorarioDomicilio = v ? 'auto' : 'forzado_cierre';
    if (rootState.directorio.directorioMatriz) {
      return Promise.all(
        rootState.directorio.directorioMatrizObj.restaurantes.map((r) => {
          return db
            .collection('restaurantes')
            .doc(r)
            .set(
              {
                modoHorarioDomicilio: modoHorarioDomicilio,
                modoHorarioDomicilioTiempo: tiempo,
              },
              { merge: true }
            )
            .catch((e) => console.log(e));
        })
      ).then(() => {
        commit('setDomicilioActivado', t);
      });
    } else {
      return db
        .collection('restaurantes')
        .doc(state.restauranteObj.id)
        .set(
          {
            modoHorarioDomicilio: modoHorarioDomicilio,
            modoHorarioDomicilioTiempo: tiempo,
          },
          { merge: true }
        )
        .then(() => {
          commit('setDomicilioActivado', t);
        });
    }
  },
  async imprimirResumenCierre(
    { rootState, state, rootGetters, dispatch },
    datos
  ) {
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == 'resumencierre';
    });
    if (ticketsImp.length == 0) {
      //buscar uso por defecto en caja
      ticketsImp = rootState.datosTpv.tickets.filter((t) => {
        return t.tipo == 'caja';
      });
    }

    if (ticketsImp.length > 0) {
      var datosZFichero;

      await Promise.all(
        ticketsImp.map(async (ticket, i) => {
          // let ticket = ticketsImp[0];
          const fT = rootState.impresion.logosCaja.find(
            (l) => l.ticket === ticket.id
          );
          let obj = {
            ...datos,
            ticket: ticket,
            logo: fT ? fT.datos : false,
            restaurante: {
              ...state.restauranteObj,
              nombreNegocio: rootState.negocioObj.nombre,
              cuenta: rootGetters['cuenta'],
            },
          };
          let datosResumen = await tickets('resumencierre', obj);
          let impresionLocal = rootGetters['impresionLocal'];
          if (impresionLocal) {
            // return dispatch("impresionZLocal", pedido);
            return dispatch(
              'qz/imprimirTicket',
              { data: datosResumen, impresora: ticket.qzDispositivo },
              { root: true }
            );
          }
        })
      );
    }
  },
  async imprimirz({ dispatch, state, rootGetters, rootState, commit }) {
    // console.log(rootGetters['pedidos/pedidos']);

    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == 'caja2' || t.tipo == 'cierre';
    });
    if (ticketsImp.length == 0) {
      //buscar uso por defecto en caja
      ticketsImp = rootState.datosTpv.tickets.filter((t) => {
        return t.tipo == 'caja';
      });
    }

    if (ticketsImp.length > 0) {
      var datosZFichero;

      await Promise.all(
        ticketsImp.map(async (t, i) => {
          const fT = rootState.impresion.logosCaja.find(
            (l) => l.ticket === t.id
          );
          if (t.tipo == 'caja2') {
            const f = !!ticketsImp[0].qzDispositivo ? zcierreQz : zcierre;
            datosZFichero = await f(
              rootGetters['pedidos/pedidos'],
              state.zcierreResumen,
              {
                ...state.restauranteObj,
                logo: fT ? fT.datos : false,
                nombreNegocio: rootState.negocioObj.nombre,
                cuenta: rootGetters['cuenta'],
              }
            );
          } else if (t.tipo == 'cierre' || t.tipo == 'caja') {
            let obj = {
              pedidos: rootGetters['pedidos/pedidos'],
              resumen: state.zcierreResumen,
              ticket: t,
              logo: fT ? fT.datos : false,
              restaurante: {
                ...state.restauranteObj,
                nombreNegocio: rootState.negocioObj.nombre,
                cuenta: rootGetters['cuenta'],
              },
            };

            datosZFichero = await tickets('cierre', obj);
            if (ticketsImp.length > 0) {
              let impresionLocal = rootGetters['impresionLocal'];
              if (
                impresionLocal ||
                rootState.configuracionTpv.printnodeExclusivo
              ) {
                // return dispatch("impresionZLocal", pedido);
                return dispatch(
                  'qz/imprimirTicket',
                  {
                    data: datosZFichero,
                    impresora: t.qzDispositivo,
                  },
                  { root: true }
                );
              } else {
                return db
                  .collection('restaurantes')
                  .doc(rootState.auth.user.restauranteTpv)
                  .collection('impresion')
                  .add({
                    // pedido: pedido.id,
                    moment: moment().unix(),
                    usuario: rootState.auth.user.uid,
                    usuarioTpv: rootState.usuarios.usuarioActual,
                    estado: 'esperandoImpresion',
                    tickets: ['zcierre'],
                  });
              }
            }
          }
        })
      );
    }
  },
  imprimirzAyer(
    { state, rootGetters, rootState, commit, dispatch },
    { pedidos, fecha }
  ) {
    // console.log(rootGetters['pedidos/pedidos']);

    const datosZFichero = zcierre(
      pedidos.filter((p) => !!p.cobrado && !p.eliminado),
      state.zcierreResumen,
      state.restauranteObj,
      fecha
    );
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == 'caja2' || t.tipo == 'cierre';
    });
    if (ticketsImp.length > 0) {
      return db
        .collection('restaurantes')
        .doc(rootState.auth.user.restauranteTpv)
        .collection('impresion')
        .add({
          // pedido: pedido.id,
          moment: moment().unix(),
          usuario: rootState.auth.user.uid,
          usuarioTpv: rootState.usuarios.usuarioActual,
          estado: 'esperandoImpresion',
          tickets: ['zcierre'],
          obligatorio: !!pedido.obligatorio,
        });
    }
  },
  abrirCajon({ state, rootGetters, rootState, dispatch, commit }) {
    var ticketsImp = rootState.datosTpv.tickets.filter((t) => {
      return t.tipo == 'caja2' || t.tipo == 'abrircajon';
    });
    if (ticketsImp.length == 0) {
      //buscar uso por defecto en caja
      ticketsImp = rootState.datosTpv.tickets.filter((t) => {
        return t.tipo == 'caja';
      });
    }

    return Promise.all(
      ticketsImp.map((t, i) => {
        var abrirCajaDatos;
        if (t.tipo == 'caja2') {
          const f = !!t.qzDispositivo ? abrirCajaQz : abrirCaja;
          abrirCajaDatos = f();
        } else if (t.tipo == 'abrircajon' || t.tipo == 'caja') {
          abrirCajaDatos = tickets('abrircajon', t);
        }
        if (!!rootState.configuracionTpv && !!t.qzDispositivo) {
          dispatch(
            'qz/imprimirTicket',
            { data: abrirCajaDatos, impresora: t.qzDispositivo },
            { root: true }
          );
        } else if (
          !!rootState.configuracionTpv &&
          !!rootState.configuracionTpv.printnodeExclusivo
        ) {
          //utiliza printnode exclusivo, no se usa el servidor de impresión de tpv, se manad a imprimir del tirón

          return dispatch(
            'impresion/ejecutarImpresionPN',
            {
              id: 'abrirCajón' + i + '_' + moment().unix(),

              fecha: moment().unix(),
              nombre: 'COCINA ' + i + ' ' + pedido.id,
              datos: datosPedido.datos,
              impresionCocina: impresionCocina,
              dispositivo: t,
              obligatorio: pedido.obligatorio,
              idImpresion: pedido.idImpreso,
            },
            { root: true }
          );
        } else {
          return db
            .collection('restaurantes')
            .doc(rootState.auth.user.restauranteTpv)
            .collection('impresion')
            .add({
              moment: moment().unix(),
              usuario: rootState.auth.user.uid,
              usuarioTpv: rootState.usuarios.usuarioActual,
              estado: 'esperandoImpresion',
              tickets: ['abrirCajon'],
            });
          return true;
        }
      })
    );
  },
  getImpuestos({ commit }) {
    let devolver = [];
    return db
      .collection('impuestos')
      .get()
      .then((res) => {
        res.forEach((doc) => {
          let aux = doc.data();
          aux.id = doc.id;
          devolver.push(aux);
        });

        commit('setImpuestos', devolver);
      });
  },
  firestoreConexion({}, disabled = true) {
    if (disabled) {
      return firebase
        .firestore()
        .disableNetwork()
        .then(() => {
          // Do offline actions
          // ...
        });
    } else {
      return firebase
        .firestore()
        .enableNetwork()
        .then(() => {
          // Do offline actions
          // ...
        });
    }
  },
  reiniciar({}) {
    window.location.reload();
  },
  //PAGINACIÓN
  cambiarPagina({ state, commit }, i) {
    switch (state.paginacionManejando) {
      case 'pedidos':
        commit('setPaginaPaginacionPedidos', state.paginaPaginacionPedidos + i);
        break;
      case 'categorias':
        commit(
          'setPaginaPaginacionCategorias',
          state.paginaPaginacionCategorias + i
        );
        break;
      case 'productos':
        commit(
          'setPaginaPaginacionProductos',
          state.paginaPaginacionProductos + i
        );
        break;
      case 'productosListado':
        commit(
          'setPaginaPaginacionProductosListado',
          state.paginaPaginacionProductosListado + i
        );
        break;
    }
  },
  //AUDIO
  reproducirSonido() {
    var audio = new Audio('audio_file.mp3');
    audio.play();
  },
  //ZCIERRE
  inicioZCierre({ rootState, commit, dispatch }, datos) {
    var cierre = db
      .collection('negocios')
      .doc(rootState.auth.user.negocioTpv)
      .collection('cierrestpv');
    return cierre
      .doc(datos.inicio.toString())
      .set(datos, { merge: true })
      .then(() => {
        return db
          .collection('restaurantes')
          .doc(rootState.auth.user.restauranteTpv)
          .collection('tpv')
          .doc('configuracion')
          .set(
            {
              cierreEnCurso: datos.inicio.toString(),
            },
            { merge: true }
          );
      })
      .then(() => {
        commit('setZCierre', datos.inicio.toString());

        commit('setZCierreDatos', datos);

        //dispatch('pedidos/getPedidos', {}, { root: true })
        //dispatch('pedidos/getPedidosRealizandoTpv', {}, { root: true })
        //dispatch('pedidos/getNumPedidoRest', {}, { root: true })
      });
  },
  getZCierre({ rootState }, id) {
    var cierre = db
      .collection('negocios')
      .doc(rootState.auth.user.negocioTpv)
      .collection('cierrestpv');
    return cierre
      .doc(id.toString())
      .get()
      .then((r) => {
        return r.exists ? r.data() : null;
      });
  },
  finZCierre({ rootState, commit, state, dispatch }, datos) {
    const cierreId = state.zcierre;
    const grabar = JSON.parse(JSON.stringify(datos));

    grabar.cierresX = [];
    var cierre = db
      .collection('negocios')
      .doc(rootState.auth.user.negocioTpv)
      .collection('cierrestpv');
    if (grabar.resumen && grabar.resumen.resumen) delete grabar.resumen.resumen;
    console.log(grabar);

    return cierre
      .doc(state.zcierre.toString())
      .set(
        {
          fin: moment().tz('Europe/Madrid').unix(),
          ...grabar,
          estado: 'cerrado',
        },
        { merge: true }
      )
      .then(() => {
        // return dispatch('enviarCierre', state.zcierre)//probar
        //return dispatch('enviarCierre', state.zcierre)

        return db
          .collection('restaurantes')
          .doc(rootState.auth.user.restauranteTpv)
          .collection('tpv')
          .doc('configuracion')
          .set({ cierreEnCurso: null }, { merge: true });
      })
      .then(() => {
        // return dispatch('enviarCierre', cierreId);
      })
      .then(() => {
        commit('setZCierre', null);
        commit('setZCierreDatos', {});
        dispatch('pedidos/quitarListenerPedidos', {}, { root: true });
      })
      .catch((e) => console.log(e));
  },
  guardarSalidaDinero({ rootState, commit, state, dispatch }, datos) {
    var cierre = db
      .collection('negocios')
      .doc(rootState.auth.user.negocioTpv)
      .collection('cierrestpv');
    return cierre
      .doc(state.zcierre.toString())
      .set(
        { salidaDinero: firebase.firestore.FieldValue.arrayUnion(datos) },
        { merge: true }
      );
  },
  async guardarCierreX({ rootState, commit, state, dispatch }, cierreX) {
    var cierre = db
      .collection('negocios')
      .doc(rootState.auth.user.negocioTpv)
      .collection('cierrestpv');
    cierreX.fin = moment().unix();
    //guardamos nuevo inicio
    await dispatch('guardarCierreXEnCurso', {
      inicio: moment().unix(),
    });
    return cierre
      .doc(state.zcierre.toString())
      .set(
        { cierresX: firebase.firestore.FieldValue.arrayUnion(cierreX) },
        { merge: true }
      );
  },
  guardarCierreXEnCurso({ rootState, commit, state, dispatch }, cierreX) {
    var cierre = db
      .collection('negocios')
      .doc(rootState.auth.user.negocioTpv)
      .collection('cierrestpv');
    return cierre
      .doc(state.zcierre.toString())
      .set({ cierreXEnCurso: cierreX }, { merge: true });
  },
  subirFichero({ rootState }, { archivo }) {
    //generico
    let destino =
      'usuariosTpv/' +
      rootState.restauranteObj.id +
      '/' +
      rootState.auth.user.uid +
      '/salidasDinero/' +
      archivo.name;
    var storageRef = storage.ref(destino);

    return storageRef
      .put(archivo)
      .then((r) => {
        return storageRef.getDownloadURL();
      })
      .then((url) => {
        return url;
      })
      .catch((e) => {
        console.log(e);
      });
  },
  enviarCierre({}, id) {
    // var doc = new jsPDF();
    // doc.setFontSize(20);
    // doc.text("CIERRE " + datosNegocio.nombre.toUpperCase() + ' ' + datosRest.nombre.toUpperCase(), 35, 25);
    // doc.addImage("img/logoFunEatsNegro.png", "PNG", 15, 40, 180, 180);
    // return doc.save(datosCierre.id + '.pdf');
    var f = firebase.functions().httpsCallable('httpTpvEnviarCierre');
    return f({ idCierre: id });
  },
  loginComandero({ rootState }, data) {
    const app = firebase.app();

    const functions = app.functions('europe-west1');
    var f = functions.httpsCallable('httpTpvComanderoLogin');

    return f(data).then((r) => {
      return r.data;
    });
  },
  permitirLoginComandero({ rootState }, id) {
    return db
      .collection('restaurantes')
      .doc(rootState.auth.user.restauranteTpv)
      .collection('tpv')
      .doc('secreto')
      .collection('cuentas')
      .doc(id)
      .collection('loginComandero')
      .add({
        permitirHasta: moment().unix() + 60 * 5, // 5 miniutos
      })
      .then((r) => r.id)
      .catch((e) => console.log(e));
  },
  async calcularCierre(
    { rootGetters, rootState, commit, dispatch, state },
    pedidos = null
  ) {
    var tramosHorarios = [];
    var promesa = !!state.zcierre
      ? dispatch('getZCierre', state.zcierre.toString())
      : Promise.resolve(false);
    var start = new Date().getTime();

    return promesa.then((r) => {
      var salidaDinero = !!r && !!r.salidaDinero ? r.salidaDinero : [];
      return new Promise(async (resolve) => {
        let objBase = {
          nombre: '',
          // tipo: 'formaPago',
          total: 0,
          numPedidos: 0,
          //formas pago
          efectivo: 0,
          tarjeta_domicilio: 0,
          tarjeta_online: 0,
          tarjeta_local: 0,
          local: 0,
          otro: 0,
          varios: 0,
        };
        var res = {}; //cobrados
        var resPendientes = {}; //peendientes

        res.totales = {
          ...objBase,
          nombre: 'TOTALES',
        };
        resPendientes.totales = {
          ...objBase,
          nombre: 'TOTALES',
        };
        var productos = {
          productos: [],
          categorias: [],
        };

        let salidasDinero = {
          ...objBase,
          total: salidaDinero.reduce((a, b) => a + b.cantidad, 0),
          efectivo: salidaDinero
            .filter((s) => s.metodo == 'efectivo')
            .reduce((a, b) => a + b.cantidad, 0),
          numPedidos: salidaDinero.length,
          nombre: 'SALIDAS DINERO',
        };
        let auxModos = rootState.datosTpv.modos.map((m) => {
          return {
            ...objBase,
            nombre: m.nombre.toUpperCase(),
            id: m.id,
          };
        });
        res.modos = auxModos.map((m) => {
          return { ...m };
        });
        resPendientes.modos = auxModos.map((m) => {
          return { ...m };
        });
        let auxCuentas = rootState.datosTpv.cuentas.map((m) => {
          return {
            ...objBase,
            nombre: 'CUENTA ' + m.nombre.toUpperCase(),
            id: m.email ? m.email : m.id,
          };
        });
        res.cuentas = auxCuentas.map((c) => {
          return { ...c };
        });
        resPendientes.cuentas = auxCuentas.map((c) => {
          return { ...c };
        });

        let auxTramos = tramosHorarios.map((t) => {
          return {
            ...objBase,
            nombre: 'HORA ' + t,
            id: 'hora_' + t,
          };
        });
        res.tramosHorarios = [...auxTramos];
        resPendientes.tramosHorarios = [...auxTramos];

        var devolver = {
          salidasDinero: salidasDinero,
          productos: productos,
          cobrados: { ...res },
          pendientes: { ...resPendientes },
          empleados: [],
          cuentas: [],
          moment: state.zcierre,
        };

        //fin calculo pagos pedidos

        const pedidosDefinitivos = !!pedidos
          ? pedidos
          : rootGetters['pedidos/pedidos'];

        //calcular pagos pedidos
        var calculoPagosPedidos = [];

        pedidosDefinitivos
          .filter((p) => !p.eliminado)
          .forEach((p, i) => {
            if (
              !!p.modoId ||
              (p.estado === 'finalizado' && (!state.zcierre || !!p.zcierre))
            ) {
              //tiene modoId o es desde la web
              //productos
              let n =
                p.copyUsuarioTpv && p.copyUsuarioTpv.nombre
                  ? p.copyUsuarioTpv.nombre
                  : false;
              if (n) {
                let f = devolver.empleados.find((e) => e == n);
                if (!f) devolver.empleados.push(n);
              }

              n =
                p.copyCuentaTpv && p.copyCuentaTpv.nombre
                  ? p.copyCuentaTpv.nombre
                  : false;
              if (n) {
                let f = devolver.cuentas.find((e) => e == n);
                if (!f) devolver.cuentas.push(n);
              }
              p.productos.forEach((p) => {
                let fIP = devolver.productos.productos.findIndex(
                  (pA) => pA.id === p.id
                );
                if (fIP > -1) {
                  devolver.productos.productos[fIP].cantidad += p.cantidad;
                  devolver.productos.productos[fIP].precioTotal +=
                    p.precioProductoTotal;
                } else {
                  devolver.productos.productos.push({
                    id: p.id,
                    cantidad: p.cantidad,
                    precioTotal: p.precioProductoTotal,
                    nombre: p.nombre,
                  });
                }
                let fIC = devolver.productos.categorias.findIndex(
                  (pC) => pC.id === p.categoria
                );
                if (fIC > -1) {
                  devolver.productos.categorias[fIC].cantidad += p.cantidad;
                  devolver.productos.categorias[fIC].precioTotal +=
                    p.precioProductoTotal;
                } else if (!!p.categoria) {
                  devolver.productos.categorias.push({
                    id: p.categoria,
                    cantidad: p.cantidad,
                    precioTotal: p.precioProductoTotal,
                  });
                }
              });

              //pedido
              var aux = !!p.cobrado ? 'cobrados' : 'pendientes';
              devolver[aux].totales.total += parseFloat(p.precioTotal);
              devolver[aux].totales.numPedidos++;

              if (Array.isArray(p.cobradoDesglose)) {
                p.cobradoDesglose.forEach((c) => {
                  if (Array.isArray(c.formaPago)) {
                    c.variasFormasPago.forEach((vF) => {
                      devolver[aux].totales[vF.formaPago] += parseFloat(
                        vF.pagar
                      );
                    });
                  } else {
                    devolver[aux].totales[c.formaPago] += parseFloat(c.pagado);
                  }
                });

                let fIndex = devolver[aux].modos.findIndex(
                  (r) => p.modoId && r.id == p.modoId
                );
                if (fIndex > -1) {
                  devolver[aux].modos[fIndex].total += parseFloat(
                    p.precioTotal
                  );
                  devolver[aux].modos[fIndex].numPedidos++;

                  p.cobradoDesglose.forEach((c) => {
                    if (Array.isArray(c.formaPago)) {
                      c.variasFormasPago.forEach((vF) => {
                        devolver[aux].modos[fIndex][vF.formaPago] += parseFloat(
                          vF.pagar
                        );
                      });
                    } else {
                      devolver[aux].modos[fIndex][c.formaPago] += parseFloat(
                        c.pagado
                      );
                    }
                  });
                }
                if (!!p.cuentaTpv) {
                  let fIndexCuenta = devolver[aux].cuentas.findIndex(
                    (r) => p.cuentaTpv && r.id == p.cuentaTpv
                  );
                  if (fIndexCuenta > -1) {
                    devolver[aux].cuentas[fIndexCuenta].total += parseFloat(
                      p.precioTotal
                    );
                    devolver[aux].cuentas[fIndexCuenta].numPedidos++;
                    p.cobradoDesglose.forEach((c) => {
                      if (Array.isArray(c.formaPago)) {
                        c.variasFormasPago.forEach((vF) => {
                          devolver[aux].modos[fIndex][vF.formaPago] +=
                            parseFloat(vF.pagar);
                        });
                      } else {
                        devolver[aux].modos[fIndex][c.formaPago] += parseFloat(
                          c.pagado
                        );
                      }
                    });
                  }
                }
                if (!!p.fechaServidor) {
                  let hora = moment(p.fechaServidor.toDate()).format('HH:00');

                  let fI = devolver[aux].tramosHorarios.findIndex(
                    (h) => hora == h.id
                  );
                  if (fI > -1) {
                    devolver[aux].tramosHorarios[fI].total += parseFloat(
                      p.precioTotal
                    );
                    devolver[aux].tramosHorarios[fI].numPedidos++;
                    p.cobradoDesglose.forEach((c) => {
                      devolver[aux].tramosHorarios[fI][c.formaPago] +=
                        parseFloat(c.pagado);
                    });
                  } else {
                    devolver[aux].tramosHorarios.push({
                      ...objBase,
                      nombre: hora + 'h',
                      /* nombre: 'HORA ' + hora, */
                      id: hora,
                    });
                    let fI2 = devolver[aux].tramosHorarios.findIndex(
                      (h) => hora == h.id
                    );
                    if (fI2 > -1) {
                      devolver[aux].tramosHorarios[fI2].total += parseFloat(
                        p.precioTotal
                      );
                      devolver[aux].tramosHorarios[fI2].numPedidos++;
                      p.cobradoDesglose.forEach((c) => {
                        devolver[aux].tramosHorarios[fI2][c.formaPago] +=
                          parseFloat(c.pagado);
                      });
                    }
                  }
                }

                var multiplePago = p.cobradoDesglose.length > 1;
                var numeroCalc =
                  p.copyMesa && p.modoCopy && p.modo === 'mesa'
                    ? p.numPedidoRestDiaModoMostrar
                    : p.modoCopy
                    ? p.numPedidoGofo
                      ? 'G' + p.numPedidoGofo
                      : p.numPedidoRestDiaModoMostrar
                      ? p.numPedidoRestDiaModoMostrar
                      : p.numPedidoRestDia
                      ? p.numPedidoRestDia
                      : ''
                    : p.modoCopy.nombre + s;
                p.cobradoDesglose.forEach((pDes) => {
                  if (Array.isArray(pDes.variasFormasPago)) {
                    //MISMA FACTURA SIMPLIFICADA CON VARIOS PAGOS
                    pDes.variasFormasPago.forEach((forma) => {
                      calculoPagosPedidos.push({
                        pedido: p.id,
                        numero: numeroCalc,
                        modo: p.modoCopy ? p.modoCopy.nombre : p.modo,
                        formaPago: forma.formaPago,
                        cantidad: forma.pagar,
                      });
                      // devolverQz.push(lineaQz(s));
                    });
                  } else {
                    calculoPagosPedidos.push({
                      pedido: p.id,

                      numero: numeroCalc,
                      modo: p.modoCopy ? p.modoCopy.nombre : p.modo,
                      formaPago: pDes.formaPago,
                      cantidad: pDes.total,
                    });
                  }
                });
              }
            }
          });
        devolver.cobrados.tramosHorarios =
          devolver.cobrados.tramosHorarios.sort((a, b) => {
            return moment(a.id, 'HH:mm').unix() - moment(b.id, 'HH:mm').unix();
          });

        var serieFacturacionTodas = pedidosDefinitivos
          .map((p) =>
            Array.isArray(p.cobradoDesglose)
              ? p.cobradoDesglose.map((c) => c.codigoFactura)
              : ''
          )
          .flat();

        let serieFacturacion = serieFacturacionTodas
          .filter((codigoFactura) => codigoFactura.includes('FT'))
          .sort((a, b) => {
            return parseInt(a.substr(a.length - 5)) <
              parseInt(b.substr(b.length - 5))
              ? 1
              : -1;
          });
        let serieFacturacionAbono = serieFacturacionTodas
          .filter((codigoFactura) => codigoFactura.includes('FA'))
          .sort((a, b) => {
            return parseInt(a.substr(a.length - 5)) <
              parseInt(b.substr(b.length - 5))
              ? 1
              : -1;
          });
        let serieFacturacionOnline = serieFacturacionTodas
          .filter((codigoFactura) => codigoFactura.includes('FN'))
          .sort((a, b) => {
            return parseInt(a.substr(a.length - 5)) <
              parseInt(b.substr(b.length - 5))
              ? 1
              : -1;
          });

        devolver.serieFacturacion = [false, false];
        devolver.serieFacturacionAbono = [false, false];
        devolver.serieFacturacionOnline = [false, false];
        if (serieFacturacion.length > 0)
          devolver.serieFacturacion = [
            serieFacturacion[serieFacturacion.length - 1],
            serieFacturacion[0],
          ];
        if (serieFacturacionAbono.length > 0)
          devolver.serieFacturacionAbono = [
            serieFacturacionAbono[serieFacturacionAbono.length - 1],
            serieFacturacionAbono[0],
          ];
        if (serieFacturacionOnline.length > 0)
          devolver.serieFacturacionOnline = [
            serieFacturacionOnline[serieFacturacionOnline.length - 1],
            serieFacturacionOnline[0],
          ];

        const datosCalculadosDevolver = { ...state.zcierreDatos, ...devolver };
        if (!!pedidos) {
          resolve(datosCalculadosDevolver);
        } else {
          const cierreActual = await dispatch(
            'calcularCierre',
            rootGetters['pedidos/pedidos'].filter(
              (p) => p.xcierre == state.xcierre
            )
          );

          delete cierreActual.cierresX;
          cierreActual.inicio = state.xcierreInicio;
          cierreActual.fin = null;

          commit('setZCierreResumen', {
            ...datosCalculadosDevolver,
            cierresX: [
              ...(Array.isArray(datosCalculadosDevolver.cierresX)
                ? JSON.parse(JSON.stringify(datosCalculadosDevolver.cierresX))
                : []),
              cierreActual,
            ],
          });
        }
        var end = new Date().getTime();
        var time = end - start;

        resolve();
      });
    });
  },
  getIp() {
    return axios
      .get('https://api.ipify.org?format=json')
      .then((r) => {
        if (r && r.data && r.data.ip) return r.data.ip;
        return Promise.reject();
      })
      .catch((e) => {
        return 'sin-datos';
      });
  },
  getSiguienteNumeroZCierre({ rootState }) {},
};
